import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { rootPaths } from "./root/rootPaths";
import { useAppSelector } from "../hooks/redux";

const CheckAuth: React.FC = () => {
  const { isAuth } = useAppSelector((state) => state.userReducer);
  const location = useLocation();

  return !isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={rootPaths.HOME + location.search} />
  );
};

export default CheckAuth;
