import React from 'react'

const PickerArrowLeft: React.FC = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1875 13H6.8125"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 7.9375L6.8125 13L11.875 18.0625"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="25" height="25" rx="5.5" stroke="#BDBDBD" />
    </svg>
  )
}

export default PickerArrowLeft
