import React from 'react'

const PaginationPrevIcon: React.FC = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.0791 1.63174L4.38737 3.60542L6.0791 5.5791"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.03394 1.63174L1.34221 3.60542L3.03394 5.5791"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PaginationPrevIcon
