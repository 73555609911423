import React, {useState} from "react";
import dayjs, {Dayjs} from "dayjs";
import {SpaceModel} from "../../utils/models/spaceModel";
import styles from "../../index.module.scss";
import {useViewFolders} from "../../services/queries";
import Folder from "../folder/Folder";
import SpaceIcon from "../../assets/icons/SpaceIcon";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {gantListStorageActions} from "../../../../store/reducers/gantListSlice";
import {dayjsUnits} from "../../../../utils/constants/dayjsUnits";
import MTitle from "../../../../components/common/MTitle/MTitle";
import {useSearchParams} from "react-router-dom";

type Props = {
    ganttStart: Dayjs;
    isLoading?: boolean;
};

const Space: React.FC<Props & SpaceModel> = ({
                                                 color,
                                                 done_tasks_count,
                                                 due_date,
                                                 id,
                                                 name,
                                                 start_date,
                                                 tasks_count,
                                                 ganttStart,
                                             }) => {
    const gantListStorage = useAppSelector((store) => store.gantListSlice.ids);
    const [openSpace, setOpenSpace] = useState(gantListStorage?.includes(id));
    const {data} = useViewFolders(openSpace ? id : "");
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const handleCollapse = () => {
        if (openSpace && gantListStorage.includes(id)) {
            dispatch(
                gantListStorageActions.setGantListAddStorage([
                    ...gantListStorage.filter((item) => item !== id),
                ])
            );
        } else {
            dispatch(
                gantListStorageActions.setGantListAddStorage([...gantListStorage, id])
            );
        }
        setOpenSpace(!openSpace);
    };

    const leftLength = dayjs.unix(start_date).diff(ganttStart, dayjsUnits.DAY);
    const rightLength = dayjs.unix(due_date).diff(ganttStart, dayjsUnits.DAY);
    const spaceWidth = rightLength - leftLength;

    const comleteTask = (done_tasks_count / (tasks_count / 100)).toFixed(1);

    const spaceIdFromParam = searchParams.getAll("spaceId");
    if (spaceIdFromParam.length > 0 && !spaceIdFromParam.includes(id.toString())) {
        return null;
    }

    return (
        <div className={`${styles.space} ${styles.gantt_row}`}>
            <div className={styles.top}>
                <div className={styles.left} onClick={handleCollapse}>
          <span
              className={
                  openSpace ? styles.left_open_icon : styles.left_close_icon
              }
          ></span>
                    <SpaceIcon/>
                    <p>{name}</p>
                </div>
                <div className={styles.right}>
                    <MTitle content={name + " - " + comleteTask + "%"}>
                        <div
                            className={styles.right_line}
                            style={{left: leftLength * 25, width: spaceWidth * 25}}
                        >
                            <span style={{width: comleteTask + "%"}}></span>
                        </div>
                    </MTitle>
                </div>
            </div>
            <div className={styles.bottom}>
                {openSpace &&
                    data?.map((item) => (
                        <Folder {...item} key={item.id} ganttStart={ganttStart}/>
                    ))}
            </div>
        </div>
    );
};

export default Space;
