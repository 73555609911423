import React, { useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { FolderModel } from "../../utils/models/folderModel";
import styles from "../../index.module.scss";
import { useViewLists } from "../../services/queries";
import List from "../list/List";
import FolderIcon from "../../assets/icons/FolderIcon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { gantListStorageActions } from "../../../../store/reducers/gantListSlice";
import { dayjsUnits } from "../../../../utils/constants/dayjsUnits";
import MTitle from "../../../../components/common/MTitle/MTitle";
import { useSearchParams } from "react-router-dom";

type Props = {
  ganttStart: Dayjs;
};

const Folder: React.FC<Props & FolderModel> = ({
                                                 name,
                                                 done_tasks_count,
                                                 due_date,
                                                 id,
                                                 start_date,
                                                 tasks_count,
                                                 ganttStart,
                                               }) => {
  const gantListStorage = useAppSelector((store) => store.gantListSlice.ids);
  const dispatch = useAppDispatch();
  const [openFolder, setOpenFolder] = useState(gantListStorage?.includes(id));
  const { data: folderData } = useViewLists(openFolder ? id : "");
  const [searchParams] = useSearchParams();

  const handleCollapse = () => {
    if (openFolder && gantListStorage.includes(id)) {
      dispatch(
          gantListStorageActions.setGantListAddStorage([
            ...gantListStorage.filter((item) => item !== id),
          ])
      );
    } else {
      dispatch(
          gantListStorageActions.setGantListAddStorage([...gantListStorage, id])
      );
    }
    setOpenFolder(!openFolder);
  };

  const leftLength = dayjs.unix(start_date).diff(ganttStart, dayjsUnits.DAY);
  const rightLength = dayjs.unix(due_date).diff(ganttStart, dayjsUnits.DAY);
  const folderWidth = rightLength - leftLength;
  const comleteTask = (done_tasks_count / (tasks_count / 100)).toFixed(1);

  const folderIdFromParam = searchParams.getAll("folderId");
  if (folderIdFromParam.length > 0 && !folderIdFromParam.includes(id.toString())) {
    return null;
  }

  return (
      <div className={`${styles.folder} ${styles.gantt_row}`}>
        <div className={styles.top} onClick={handleCollapse}>
          <div className={styles.left}>
          <span
              className={
                openFolder ? styles.left_open_icon : styles.left_close_icon
              }
          ></span>
            <FolderIcon />
            <p>{name}</p>
          </div>
          <div className={styles.right}>
            <MTitle content={name + " - " + comleteTask + "%"}>
              <div
                  className={styles.right_line}
                  style={{ left: leftLength * 25, width: folderWidth * 25 }}
              >
                <span style={{ width: comleteTask + "%" }}></span>
              </div>
            </MTitle>
          </div>
        </div>
        <div className={styles.bottom}>
          {openFolder &&
              folderData?.map((item) => (
                  <List {...item} key={item.id} ganttStart={ganttStart} />
              ))}
        </div>
      </div>
  );
};

export default Folder;
