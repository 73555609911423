import React from 'react'

const HomeIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.24 2.0127H5.34C3.15 2.0127 2 3.1627 2 5.3427V7.2427C2 9.4227 3.15 10.5727 5.33 10.5727H7.23C9.41 10.5727 10.56 9.4227 10.56 7.2427V5.3427C10.57 3.1627 9.42 2.0127 7.24 2.0127Z"
                fill="#8A92A6"
            />
            <path
                opacity="0.4"
                d="M18.6699 2.0127H16.7699C14.5899 2.0127 13.4399 3.1627 13.4399 5.3427V7.2427C13.4399 9.4227 14.5899 10.5727 16.7699 10.5727H18.6699C20.8499 10.5727 21.9999 9.4227 21.9999 7.2427V5.3427C21.9999 3.1627 20.8499 2.0127 18.6699 2.0127Z"
                fill="#8A92A6"
            />
            <path
                d="M18.6699 13.4424H16.7699C14.5899 13.4424 13.4399 14.5924 13.4399 16.7724V18.6724C13.4399 20.8524 14.5899 22.0024 16.7699 22.0024H18.6699C20.8499 22.0024 21.9999 20.8524 21.9999 18.6724V16.7724C21.9999 14.5924 20.8499 13.4424 18.6699 13.4424Z"
                fill="#8A92A6"
            />
            <path
                opacity="0.4"
                d="M7.24 13.4424H5.34C3.15 13.4424 2 14.5924 2 16.7724V18.6724C2 20.8624 3.15 22.0124 5.33 22.0124H7.23C9.41 22.0124 10.56 20.8624 10.56 18.6824V16.7824C10.57 14.5924 9.42 13.4424 7.24 13.4424Z"
                fill="#8A92A6"
            />
        </svg>

    )
}

export default HomeIcon