import React from 'react'

interface IProps {
  fill?: string
}

const FlagIcon: React.FC<IProps> = ({ fill = 'transparent' }) => {
  return (
    <svg
      width="32"
      height="25"
      viewBox="0 0 32 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 20.5V4.5H12V6.5H22L18.5 10L22 13.5H12V20.5H10Z"
        fill={fill}
        stroke="#cccccc"
      />
    </svg>
  )
}

export default FlagIcon
