import React from 'react'

const EmployeeIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.949 14.5532C8.49903 14.5532 5.58807 15.117 5.58807 17.2927C5.58807 19.4694 8.51783 20.0133 11.949 20.0133C15.399 20.0133 18.31 19.4495 18.31 17.2738C18.31 15.0972 15.3802 14.5532 11.949 14.5532Z"
        fill="#8A92A6"
      />
      <path
        opacity="0.4"
        d="M11.949 12.4802C14.2851 12.4802 16.1583 10.5962 16.1583 8.2467C16.1583 5.89625 14.2851 4.01318 11.949 4.01318C9.61293 4.01318 7.73975 5.89625 7.73975 8.2467C7.73975 10.5962 9.61293 12.4802 11.949 12.4802Z"
        fill="#8A92A6"
      />
      <path
        opacity="0.4"
        d="M21.0879 9.23241C21.6923 6.85495 19.9203 4.71973 17.6639 4.71973C17.4186 4.71973 17.184 4.74674 16.9548 4.79267C16.9243 4.79988 16.8903 4.81519 16.8724 4.8422C16.8518 4.87642 16.867 4.92235 16.8894 4.95207C17.5672 5.90846 17.9567 7.07288 17.9567 8.32285C17.9567 9.52059 17.5995 10.6373 16.9727 11.564C16.9082 11.6594 16.9655 11.7882 17.0792 11.808C17.2368 11.8359 17.398 11.8503 17.5627 11.8548C19.2058 11.8981 20.6805 10.8345 21.0879 9.23241Z"
        fill="#8A92A6"
      />
      <path
        d="M22.8093 14.8301C22.5084 14.1853 21.7823 13.7432 20.6782 13.5261C20.1571 13.3983 18.7468 13.2181 17.4351 13.2425C17.4154 13.2452 17.4046 13.2587 17.4028 13.2677C17.4002 13.2803 17.4055 13.3019 17.4315 13.3154C18.0377 13.6171 20.381 14.9292 20.0864 17.6966C20.0738 17.8164 20.1696 17.9199 20.2887 17.9019C20.8654 17.8191 22.349 17.4985 22.8093 16.4998C23.0636 15.972 23.0636 15.3588 22.8093 14.8301Z"
        fill="#8A92A6"
      />
      <path
        opacity="0.4"
        d="M7.04483 4.79292C6.8165 4.74609 6.58101 4.71997 6.33567 4.71997C4.07926 4.71997 2.30726 6.85519 2.91255 9.23266C3.31906 10.8347 4.79379 11.8983 6.43685 11.8551C6.60161 11.8506 6.76368 11.8353 6.92037 11.8082C7.03409 11.7884 7.09139 11.6597 7.02692 11.5642C6.40014 10.6366 6.04288 9.52083 6.04288 8.3231C6.04288 7.07222 6.43327 5.9078 7.11109 4.95231C7.13258 4.9226 7.1487 4.87667 7.12721 4.84245C7.1093 4.81453 7.07617 4.80012 7.04483 4.79292Z"
        fill="#8A92A6"
      />
      <path
        d="M3.32156 13.5259C2.21752 13.7429 1.49225 14.1851 1.19139 14.8299C0.936203 15.3585 0.936203 15.9718 1.19139 16.5004C1.65163 17.4982 3.13531 17.8197 3.71195 17.9017C3.83104 17.9197 3.92595 17.817 3.91342 17.6964C3.61883 14.9298 5.9621 13.6177 6.56918 13.3161C6.59425 13.3016 6.59962 13.2809 6.59694 13.2674C6.59515 13.2584 6.5853 13.2449 6.5656 13.2431C5.25294 13.2179 3.84358 13.398 3.32156 13.5259Z"
        fill="#8A92A6"
      />
    </svg>
  )
}

export default EmployeeIcon
