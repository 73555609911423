import {useSearchParams} from "react-router-dom";
import {VIEW_TYPE} from "../../../utils/constants/queryParamsKeys";
import {strikesViewTypes} from "../utils/enums/strikesViewTypes";
import {dayjsUnits} from "../../../utils/constants/dayjsUnits";
import {dayjsParse} from "../../../utils/helpers/dayjsFormatter";
import {
    DATE_FORMAT,
    DEFAULT_DATE_FORMAT,
} from "../../../utils/constants/dayjsFormats";
import {useQuery} from "@tanstack/react-query";
import {StrikeType} from "../utils/models/strikesModel";
import {ErrorRes} from "../../../utils/model/responseType";
import {queryKeys} from "../utils/constant/queryKeys";
import $api, {$strikeapi} from "../../../services/RequestService";
import {endpoints} from "../utils/constant/endpoints";
import {stringify} from "query-string";
import dayjs from "dayjs";
import {LoginReq, LoginRes} from "../../../utils/model/loginModel";
import {authEndpoints} from "../../../utils/constants/apis";

export function useGetAllTasks(selectedTime: string) {
    const [searchParams] = useSearchParams();
    const viewType = searchParams.get(VIEW_TYPE) ?? strikesViewTypes.WEEKLY;
    const units = {
        [strikesViewTypes.WEEKLY]: dayjsUnits.WEEK,
        [strikesViewTypes.MONTHLY]: dayjsUnits.MONTH,
    };
    const unit = units[viewType as keyof typeof units];
    const date = dayjsParse(selectedTime, DATE_FORMAT)
        .startOf(unit)
        .format(DEFAULT_DATE_FORMAT);

    return useQuery<StrikeType[], ErrorRes>(
        [queryKeys.EVENT_GET, date],
        async () => {
            const res = await $strikeapi.get(
                `${endpoints.EVENT_GET}?${stringify({date})}`
            );
            return res.data?.map((item: any) => ({
                ...item,
                date: dayjs().startOf("week").format("YYYY-MM-DD"),
            }));
        }
    );
}

export function useGetPermission() {
    return useQuery<LoginRes, LoginReq>(
        [queryKeys.EVENT_DELETE],
        async () => {
            const res = await $api.post(`${authEndpoints.INDEX}`)
            return res.data
        }
    )
}
