export enum permissions {
  LOG_OUT = 'LOG_OUT',
  USER_VIEW = 'USER_VIEW',
  USER_CREATE = 'USER_CREATE',
  USER_UPDATE = 'USER_UPDATE',
  REASON_VIEW = 'REASON_VIEW',
  REASON_CREATE = 'REASON_CREATE',
  REASON_UPDATE = 'REASON_UPDATE',
  CALENDAR_VIEW = 'CALENDAR_VIEW',
  CALENDAR_CREATE = 'CALENDAR_CREATE',
  CALENDAR_UPDATE = 'CALENDAR_UPDATE',
  USER_VIEW_PAGINATION = 'USER_VIEW_PAGINATION',
  REASON_STATUS_CHANGE = 'REASON_STATUS_CHANGE',
  CALENDAR_STATUS_CHANGE = 'CALENDAR_STATUS_CHANGE',
  WORKLOAD_VIEW = 'WORKLOAD_VIEW',
  WORKLOAD_UPDATE = 'WORKLOAD_UPDATE',
  USER_POSITION = 'USER_POSITION',
  WEBSITE_VIEW = "WEBSITE_VIEW",
  WEBSITE_UPDATE = "WEBSITE_UPDATE",
  WEBSITE_CREATE = "WEBSITE_CREATE",
  WEBSITE_DELETE = "WEBSITE_DELETE",
  WEBSITE_WEB_LIST = "WEBSITE_WEB_LIST",
  DASHBOARD_VIEW = "DASHBOARD_VIEW",
  DASHBOARD_CREATE = "DASHBOARD_CREATE",
  CHART_VIEW = "CHART_VIEW",
  CHART_CREATE = "CHART_CREATE",
  STRIKES = 'STRIKES'
}
