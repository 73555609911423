import React from 'react'

const DotIcon = (props: any) => {
    return (
        <svg
            {...props}
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12.0005" r="5" fill="white" />
        </svg>

    )
}

export default DotIcon
