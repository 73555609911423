import React, {useState} from 'react';
import {Button, Form, Input, Modal, Select, Table} from 'antd';
import TrashIcon from '../../assets/TrashIcon';
import EditIcon from '../../assets/EditIcon';
import {useWebsiteView} from '../../services/queries';
import dayjs from 'dayjs';
import styles from './list.module.scss'
import DotIcon from '../../../../assets/icons/sidebar/DotIcon';
import itemRender from '../../utils/helpers/itemReminder';
import {getColumnSearchProps, getHelpIcon} from '../../utils/helpers/getIcons';
import {useWebsiteDelete, useWebsiteUpdate} from '../../services/mutation';
import TextArea from 'antd/lib/input/TextArea';
import modal from '../website-modal/website.module.scss'
import {useViewEmployeesSelect} from '../../../../queries/queries';
import {useQueryParams} from '../../../../hooks/useQueryParams';
import {PAGE} from '../../../../utils/constants/queryParamsKeys';

const {Option} = Select


const rowSelection = {
  onChange: (selectedRowKeys: React.Key[], selectedRows: any) => {
    
  },
};


const List: React.FC = () => {
  const [form] = Form.useForm()
  const [openIsModal, setOpenIsModal] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [siteUrl, setSiteUrl] = useState('')
  const [itemId, setItemId] = useState(0)
  const {append, getParams} = useQueryParams()
  const page = getParams()?.page || String(1)
  const {data} = useWebsiteView(page)
  const {data: employee} = useViewEmployeesSelect()
  const websiteDelete = useWebsiteDelete()
  const updateWebsite = useWebsiteUpdate();

  const handleCancelModal = () => {
    setOpenIsModal(false)
  }

  const handleCancel = () => {
    setOpenModal(false);
  };

  function deleteItem(e: any, id: number) {
    e.stopPropagation()
    websiteDelete.mutate(id);
    handleCancelModal()
  }

  const handlePagination = (page: number) => {
    append(PAGE, String(page))
  }


  const onFinish = (values: any) => {
    updateWebsite.mutateAsync({
      url: values.site_url,
      responsible_id: Number(values.users),
      website_id: itemId,
      status: values.status,
      comment: values.comment
    }).then(() => {
      setOpenModal(false)
    })
  }
  const columns: any = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 240,
      render: (_: string, prev: any) => {
        return (
          <a href={prev.url} target='_blank' rel="noreferrer">{prev.url}</a>
        )
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_: any, prev: any) => {
        return (
          <div className={prev.status === 'active' ? styles.active : styles.disactive}>
            <DotIcon className={styles.dot}/>
            <p>{prev.status}</p>
          </div>
        )
      },
      ...getColumnSearchProps('status')
    },
    {
      title: 'Responsible employee',
      dataIndex: 'Responsible employee',
      render: (id: number, prev: any) => {
        return (
          <p>{prev.user ? prev.user.first_name : ''}</p>
        )
      },
      ...getHelpIcon('Responsible employee')
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
    },
    {
      title: 'Statuses',
      dataIndex: 'nimadir',
      render: (_: any, prev: any) => {
        const expired_date = dayjs(prev?.SSL_cert_expired_date);
        const domain_expired_date = dayjs(prev?.domain_expired_date);
        const now_date = dayjs()
        const ssl_date = expired_date.diff(now_date, "days").toString();
        const domain_date = domain_expired_date.diff(now_date, "days").toString();

        return (
          <div className={styles.statues}>
            {
              prev?.SSL_cert_expired_date !== 'ssl kodi ishlamdi' ?
                <p className={styles.statues_ssldate}>SSL: {ssl_date} days</p> : ''
            }

            {
              prev.domain_expired_date !== '' ? <p className={styles.statues_domain}>Domain: {domain_date} days</p> : ''
            }
            {
              prev.server_name ? <p className={styles.statues_server}>{prev.server_name}</p> : ''
            }

          </div>
        )
      }
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_: any, prev: any) => {
        return (
          <>
            <div className={styles.actions}>
              <TrashIcon className={styles.actions_icon} onClick={() => {
                setOpenIsModal(true)
                setSiteUrl(prev.url)
                setItemId(prev.id)
              }}/>
              <EditIcon className={styles.actions_icon} onClick={() => {
                setOpenModal(true)
                setItemId(prev.id)
                form.setFieldsValue({
                  "site_url": prev?.url,
                  "users": prev?.user.first_name,
                  "status": prev?.status,
                  "comment": prev?.comment,
                });
              }}/>
            </div>
          </>
        )
      }
    }
  ];


  return (
    <div>

      <Table
        rowSelection={{
          ...rowSelection,
        }}
        rowKey={(prev) => prev.id}
        columns={columns}
        dataSource={data?.data}
        pagination={{
          onChange: handlePagination,
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          itemRender: itemRender,
          current: data?.current_page,
          total: data?.total,
          className: styles.website_pagination
        }}
        className={styles.table}
      />

      <Modal
        title="Delete"
        open={openIsModal}
        onCancel={handleCancelModal}
        className={styles.modal}
        footer={false}
      >
        <h2>{siteUrl} o'chirilsinmi?</h2>

        <div className={styles.modal_btns}>
          <Button onClick={handleCancelModal}>Bekor qilish</Button>
          <Button loading={websiteDelete.isLoading} onClick={(e) => deleteItem(e, itemId)}>O'chirish</Button>
        </div>
      </Modal>

      <Modal
        open={openModal}
        onCancel={handleCancel}
        footer={false}
        className={modal.modal}
      >
        <div className={modal.modal_title}>
          <h4>Site checker</h4>
          <p>Update your site details.</p>
        </div>
        <Form name='site_modal' onFinish={onFinish} form={form} layout='vertical'>
          <Form.Item label='Site url' name={'site_url'}>
            <Input placeholder='Site url'/>
          </Form.Item>
          <Form.Item label='Responsible employee' name={'users'}>
            <Select placeholder='Responsible employee'>
              {
                employee?.map((item) => (
                  <Option key={item.user_id}>{item.first_name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item label='Comment' name={'comment'} help>
            <TextArea placeholder='comment'></TextArea>
          </Form.Item>
          <Form.Item label='Status' name={'status'}>
            <Select placeholder='Status'>
              <Option key={'active'}>active</Option>
              <Option key={'deleted'}>deleted</Option>
            </Select>
          </Form.Item>
          <div className={modal.modal_btns}>
            <Button onClick={handleCancel}>Cencel</Button>
            <Button htmlType='submit' loading={updateWebsite.isLoading}>Update</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default List;