import React from 'react';
import {Button} from "antd";

import {useGetCharts} from "../../services/queries";
import {useQueryParams} from "../../../../hooks/useQueryParams";
import BurndownChart from "./burndown-chart/BurndownChart";
import ChartModal from "./chart-modal/ChartModal";
import {useAppDispatch} from "../../../../hooks/redux";
import {dashboardReducerActions} from "../../../../store/reducers/dashboardReducer";
import VelocityChart from "./velocity-chart/VelocityChart";
import PieChart from "./pie-chart/PieChart";
import BurnupChart from "./burnup-chart/BurnupChart";

import styles from './body.module.scss'

const Body: React.FC = () => {
  const dispatch = useAppDispatch()
  const {setChartModalData: setModalData} = dashboardReducerActions
  const {getParams} = useQueryParams()
  const {data} = useGetCharts(getParams()?.id)

  // open modal
  const handleOpenModal = () => {
    dispatch(setModalData({visible: true}))
  }

  return (
    <>
      <div className={styles.body}>
        <div className={styles.body_top}>
          <div></div>
          <Button type='primary' onClick={handleOpenModal}>+ Add chart</Button>
        </div>
        <div className={styles.body_bottom}>
          {data?.burndown?.map(item => (
            <BurndownChart key={item.id} {...item} />
          ))}
          {data?.velocity?.map(item => (
            <VelocityChart key={item.id} {...item} />
          ))}
          {data?.pie?.map(item => (
            <PieChart key={item.id} {...item} />
          ))}
          {data?.burnup?.map(item => (
            <BurnupChart key={item.id} {...item} />
          ))}
        </div>
      </div>
      <ChartModal/>
    </>
  );
};

export default Body