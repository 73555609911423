import { Link, useLocation } from 'react-router-dom'
import { homePaths } from '../../../../routing/home/homePaths'
import { rootPaths } from '../../../../routing/root/rootPaths'
import styles from './navbar.module.scss'

const Navbar = () => {

    const location = useLocation()

    const links = [
        {
            path: '',
            label: "Tasks",
            disabled: false
        },
        {
            path: homePaths.COMMENTS,
            label: "Comments",
            disabled: true
        },
        {
            path: homePaths.DONE,
            label: "Done",
            disabled: true
        },
    ]

    const pathLink = (path: string) => {
        return rootPaths.HOME + path
    }

    const linkActiveClass = (path: string) => {
        if (location.pathname === rootPaths.HOME + path) {
            return styles.active
        }
    }

    return (
        <>
            <div className={styles.header}>
                {
                    links.map((link) => (
                        <Link
                            className={linkActiveClass(link.path)}
                            to={pathLink(link.path)}
                            key={link.label}
                        >
                            {link.label}
                        </Link>
                    ))
                }
            </div>
        </>
    )
}

export default Navbar