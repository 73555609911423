import { Button, Modal } from 'antd'
import React, { useState } from 'react'

import { useViewReasons } from '../../../queries/queries'
import TimeOffReason from '../timeOffReason/TimeOffReason'
import ModalCloseIcon from '../../../assets/icons/ModalCloseIcon'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { timeOffReducerActions } from '../../../store/reducers/timeOffReducer'

import styles from './TimeOffSettingsModal.module.scss'
import { hasPermission } from '../../../utils/helpers/hasPermission'
import { permissions } from '../../../utils/constants/permissions'

const TimeOffSettingsModal: React.FC = () => {
  const visible = useAppSelector(
    (state) => state.timeOffReducer.visibleTimeOffSettingsModal
  )
  const { setVisibleTimeOffSettingsModal: setVisible } = timeOffReducerActions
  const [isCreating, setIsCreating] = useState(false)
  const dispatch = useAppDispatch()
  const { data } = useViewReasons()

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisible(false))
  }

  // handle after close
  const handleAfterClose = () => {}

  // handle create
  const handleCreate = () => {
    setIsCreating(true)
  }

  return (
    <Modal
      centered
      title={null}
      footer={null}
      closable={false}
      visible={visible}
      onCancel={handleCancel}
      afterClose={handleAfterClose}
      className={`${styles.settings_modal} common_modal`}
    >
      <div className="common_modal_header">
        <p>Types of vacations</p>
        <ModalCloseIcon onClickFunc={handleCancel} />
      </div>
      <div className={`${styles.body} common_modal_body`}>
        {data?.map((reason) => (
          <TimeOffReason
            key={reason.reason_id}
            data={{
              color: reason.color,
              name: reason.name,
              status: reason.status,
              day: reason.day,
              reason_id: reason.reason_id,
            }}
          />
        ))}
        {isCreating && <TimeOffReason data={null} setIsCreating={setIsCreating} />}
      </div>
      <div className="common_modal_footer">
        {hasPermission(permissions.REASON_CREATE) && (
          <Button type="primary" onClick={handleCreate} className="submit_btn">
            Add
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default TimeOffSettingsModal
