import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { sliceNames } from '../../utils/constants/sliceNames'
import { EmployeeRes } from '../../utils/model/employeesModel'

const initialState: {
  selectedEmployee: EmployeeRes | null
  visibleEmployeesModal: boolean
  isUpdatingPassword: boolean
} = {
  selectedEmployee: null,
  visibleEmployeesModal: false,
  isUpdatingPassword: false,
}

const employeesReducer = createSlice({
  name: sliceNames.EMPLOYEES,
  initialState,
  reducers: {
    setVisibleEmployeesModal: (state, action: PayloadAction<boolean>) => {
      state.visibleEmployeesModal = action.payload
    },
    setSelectedEmployee: (state, action: PayloadAction<EmployeeRes | null>) => {
      state.selectedEmployee = action.payload
    },
    setIsUpdatingPassword: (state, action: PayloadAction<boolean>) => {
      state.isUpdatingPassword = action.payload
    },
  },
})

export default employeesReducer.reducer
export const employeesReducerActions = employeesReducer.actions
