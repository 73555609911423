import React from 'react'

const WorkloadIcon: React.FC = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M22 7.82318V8.51318H9V2.01318H16.19C19.83 2.01318 22 4.18318 22 7.82318Z" fill="#8A92A6"/>
<path opacity="0.4" d="M22 15.5132V16.2032C22 19.8432 19.83 22.0132 16.19 22.0132H9V15.5132H22Z" fill="#8A92A6"/>
<path d="M9 2.01318V22.0132H7.81C4.17 22.0132 2 19.8432 2 16.2032V7.82318C2 4.18318 4.17 2.01318 7.81 2.01318H9Z" fill="#8A92A6"/>
<path opacity="0.6" d="M22 8.51318H9V15.5132H22V8.51318Z" fill="#8A92A6"/>
</svg>

  )
}

export default WorkloadIcon
