import ArrowDown from "../../assets/ArrowDown";
import HelpIcon from "../../assets/HelpIcon";

export const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown:() => <></>,
    filterIcon: () => (
      <ArrowDown />
    ),
  });

export const getHelpIcon = (dataIndex: any) => ({
    filterDropdown:() => <></>,
    filterIcon: () => (
      <HelpIcon />
    ),
  });