import {useMutation, useQueryClient} from "@tanstack/react-query";
import {ErrorRes, SuccessRes} from "../../../utils/model/responseType";
import {DashboardRequestModel} from "../utils/models/dashboardRequestModel";
import $api from "../../../services/RequestService";
import {showMessage} from "../../../utils/helpers/showMessage";
import {messageTypes} from "../../../utils/constants/messageTypes";
import {errorHandler} from "../../../utils/helpers/errorHandler";
import {queryKeys} from "../utils/constants/queryKeys";
import {endpoints} from "../utils/constants/endpoints";
import {ChartRequestModel} from "../utils/models/chartRequestModel";

// create dashboard
export const useCreateDashboard = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, DashboardRequestModel>(
    async (req) => {
      const res = await $api.post(endpoints.DASHBOARD_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.DASHBOARD_VIEW])
      },
      onError: errorHandler
    }
  )
}

// update dashboard
export const useUpdateDashboard = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, DashboardRequestModel & { id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.DASHBOARD_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.DASHBOARD_VIEW])
      },
      onError: errorHandler
    }
  )
}

// delete dashboard
export const useDeleteDashboard = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async (req) => {
      const res = await $api.delete(`${endpoints.DASHBOARD_DELETE}?id=${req.id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.DASHBOARD_VIEW])
      },
      onError: errorHandler
    }
  )
}

// create chart
export const useCreateChart = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, ChartRequestModel>(
    async (req) => {
      const res = await $api.post(endpoints.CHART_CREATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.CHART_VIEW])
      },
      onError: errorHandler
    }
  )
}

// update chart
export const useUpdateChart = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, ChartRequestModel & { id: number }>(
    async (req) => {
      const res = await $api.put(endpoints.CHART_UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.CHART_VIEW])
      },
      onError: errorHandler
    }
  )
}

// delete chart
export const useDeleteChart = () => {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async (req) => {
      const res = await $api.delete(`${endpoints.CHART_DELETE}?id=${req.id}`)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.CHART_VIEW])
      },
      onError: errorHandler
    }
  )
}