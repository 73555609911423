import React from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from "./components/sidebar/Sidebar";
import Body from "./components/body/Body";

import styles from './index.module.scss';

function Index() {
    const location = useLocation();
    const isEmbedPath = location.pathname.includes('/embed');

    return (
        <div className={styles.dashboard}>
            {!isEmbedPath && <Sidebar />}
            <Body />
        </div>
    );
}

export default Index;