import Index from '../../features/gantt/Index'
import { RouteType } from '../../utils/model/routeModel'
import { ganttPaths } from './ganttPaths'

export const ganttRoutes: RouteType[] = [
  {
    path: ganttPaths.INDEX,
    element: Index,
    isExact: true,
  },
]
