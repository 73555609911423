export const DATE_FORMAT = 'DD.MM.YYYY'
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const YEAR_WEEK_FORMAT = 'YYYY wo'
export const MONTH_FORMAT = 'MMM'
export const YEAR_MONTH_FORMAT = 'YYYY MMM'
export const D_M_H_M = 'DD.MM HH:mm'
export const DAY_OF_WEEK = 'ddd'
export const DAY_OF_WEEK_FULL = 'dddd'
export const DAY = 'DD'
