import React, { useState, useRef, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import styles from './calendar.module.scss'

import events from './events'
import { useHomeView } from '../../services/queries'
import dayjs from 'dayjs'
import { useTaskUpdate } from '../../services/mutation'
import { toHourSeconds } from '../../utils/helper/toHoursAndMinutes'

export default function Calendar() {
  const taskUpdate = useTaskUpdate()
  const { data } = useHomeView()

  const [weekendsVisible] = useState(true)
  const [, setCurrentEvents] = useState([])
  const calendarRef = useRef<any>(null)

  const [, setTitle] = useState('')
  const [, setStart] = useState(new Date())
  const [, setEnd] = useState(new Date())

  function handleDateClick() {}

  function handleDateSelect(selectInfo: any) {
    if (
      selectInfo.view.type === 'timeGridWeek' ||
      selectInfo.view.type === 'timeGridDay'
    ) {
      selectInfo.view.calendar.unselect()
      setState({ selectInfo, state: 'create' })
      setStart(selectInfo.start)
      setEnd(selectInfo.end)
    }
  }
  function renderEventContent(eventInfo: any) {
    return (
      <div className={styles.container_header}>
        <span className={styles.container_header_title}>
          {eventInfo.event.title}
        </span>
        <div
          className="dots"
          style={{ display: 'flex', alignItems: 'center', gap: '3px' }}
        >
          <div className={styles.container_header_dot}></div>
          <span>
            {eventInfo.event?.start?.getHours().toString().padStart(2, '0') +
              ':' +
              eventInfo.event?.start.getMinutes().toString().padStart(2, '0') +
              '-' +
              (eventInfo.event?.end?.getHours().toString().padStart(2, '0') +
                ':' +
                eventInfo.event?.end?.getMinutes().toString().padStart(2, '0'))}
          </span>
        </div>
      </div>
    )
  }
  function handleEventClick(clickInfo: any) {
    setState({ clickInfo, state: 'update' })
    setTitle(clickInfo.event.title)
    setStart(clickInfo.event.start)
    setEnd(clickInfo.event.end)
  }
  function handleEvents(events: any) {
    setCurrentEvents(events)
  }
  function handleEventDrop(checkInfo: any) {
    setState({ checkInfo, state: 'drop' })
    taskUpdate.mutate({
      task_id: checkInfo?.event?.id,
      start_date: String(dayjs(checkInfo.event.start).valueOf()),
      due_date: String(dayjs(checkInfo.event.end).valueOf()),
    })
  }
  function handleEventResize(checkInfo: any) {
    setState({ checkInfo, state: 'resize' })
    taskUpdate.mutate({
      task_id: checkInfo?.event?.id,
      start_date: String(dayjs(checkInfo.event.start).valueOf()),
      due_date: String(dayjs(checkInfo.event.end).valueOf()),
    })
  }

  const [state, setState] = useState({})

  const [totalSeconds, setTotalSeconds] = useState({
    estimateSeconds: 0,
  })

  const [totalTrack, setTotalTrack] = useState({
    trackSeconds: 0,
  })

  useEffect(() => {
    if (calendarRef?.current) {
      let calendarApi = calendarRef?.current?.getApi()
      if (data) {
        let estimateSeconds = 0
        let trackSeconds = 0

        let second = data?.time_estimate
        estimateSeconds += second || 0

        let trackSecond = data?.time_tracking
        trackSeconds += trackSecond || 0

        setTotalSeconds({
          estimateSeconds,
        })

        setTotalTrack({
          trackSeconds,
        })

        const dataArr = data?.today?.map((item) => ({
          id: item?.id,
          title: item?.name,
          start: dayjs.unix(item?.start_date ?? item?.due_date).toISOString(),
          end: dayjs.unix(item?.due_date)?.toISOString(),
          allDay:
            dayjs.unix(item?.start_date).hour() === 4 ||
            (dayjs.unix(item?.start_date).hour() === 4 &&
              dayjs.unix(item?.due_date).hour() === 4) ||
            !item?.due_date ||
            !item?.start_date,
        }))

        calendarApi?.removeAllEventSources()
        calendarApi?.addEventSource([...dataArr])
      }
    }
  }, [calendarRef, data])

  // const max_time_estimate = Number(data?.max_time_estimate);
  // const calcWidth = max_time_estimate*3600
  // const totalProsent = (Number(data?.time_estimate) * 100) / calcWidth;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_nav}>
          <div className={styles.container_nav_parent}>
            <p>Time estimate</p>
            <div
              className={styles.container_nav_parent_child}
              style={{ background: 'rgba(120, 108, 230, 0.2)' }}
            >
              <span className={styles.container_nav_parent_child_text}></span>
              <span
                className={styles.container_nav_parent_child_protsent}
                style={{ backgroundColor: '#786CE6', width: '60%' }}
              >
                {totalSeconds.estimateSeconds === 0
                  ? '00h 00m'
                  : toHourSeconds(totalSeconds.estimateSeconds)
                      .totalHours.toString()
                      .padStart(2, '0') +
                    'h ' +
                    toHourSeconds(totalSeconds.estimateSeconds)
                      .totalMinutes.toString()
                      .padStart(2, '0') +
                    'm '}
              </span>
            </div>
          </div>
          <div className={styles.container_nav_parent}>
            <p>Time tracked</p>
            <div
              className={styles.container_nav_parent_child}
              style={{ background: 'rgba(1, 169, 77, 0.2)' }}
            >
              <span className={styles.container_nav_parent_child_text}></span>
              <span
                className={styles.container_nav_parent_child_protsent}
                style={{ backgroundColor: '#01A94D', width: '60%' }}
              >
                {toHourSeconds(totalTrack.trackSeconds)
                  .totalHours.toString()
                  .padStart(2, '0') +
                  ':' +
                  toHourSeconds(totalTrack.trackSeconds)
                    .totalMinutes.toString()
                    .padStart(2, '0') +
                  ':' +
                  toHourSeconds(totalTrack.trackSeconds)
                    .totalSeconds.toString()
                    .padStart(2, '0')}
              </span>
            </div>
          </div>
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridDay"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={weekendsVisible}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          slotEventOverlap={false}
          eventsSet={() => handleEvents(events)}
          eventDrop={handleEventDrop}
          eventResize={handleEventResize}
          dateClick={handleDateClick}
          // allda
          titleFormat={{
            month: 'short',
            day: '2-digit',
            weekday: 'short',
          }}
          slotLabelFormat={[
            {
              hour: '2-digit',
              minute: '2-digit',
            },
          ]}
          locale="ru-Re"
        />
      </div>
    </>
  )
}
