import React from 'react'

type Props = {
  onClickFunc?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
}

const EditIcon: React.FC<Props> = ({ onClickFunc }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      onClick={(e) => onClickFunc && onClickFunc(e)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75002 13.7709H5.25002C2.08252 13.7709 0.729187 12.4176 0.729187 9.25008V5.75008C0.729187 2.58258 2.08252 1.22925 5.25002 1.22925H6.41669C6.65585 1.22925 6.85419 1.42758 6.85419 1.66675C6.85419 1.90591 6.65585 2.10425 6.41669 2.10425H5.25002C2.56085 2.10425 1.60419 3.06091 1.60419 5.75008V9.25008C1.60419 11.9392 2.56085 12.8959 5.25002 12.8959H8.75002C11.4392 12.8959 12.3959 11.9392 12.3959 9.25008V8.08341C12.3959 7.84425 12.5942 7.64591 12.8334 7.64591C13.0725 7.64591 13.2709 7.84425 13.2709 8.08341V9.25008C13.2709 12.4176 11.9175 13.7709 8.75002 13.7709Z"
        fill="white"
      />
      <path
        d="M4.95834 10.8191C4.60251 10.8191 4.27584 10.6907 4.03667 10.4574C3.75084 10.1716 3.62834 9.75741 3.69251 9.31991L3.94334 7.56408C3.99001 7.22574 4.21167 6.78824 4.45084 6.54908L9.04751 1.95241C10.2083 0.791579 11.3867 0.791579 12.5475 1.95241C13.1833 2.58825 13.4692 3.23575 13.4108 3.88325C13.3583 4.40825 13.0783 4.92158 12.5475 5.44658L7.95084 10.0432C7.71167 10.2824 7.27417 10.5041 6.93584 10.5507L5.18001 10.8016C5.10417 10.8191 5.02834 10.8191 4.95834 10.8191ZM9.66584 2.57075L5.06917 7.16741C4.95834 7.27824 4.83001 7.53491 4.80667 7.68658L4.55584 9.44241C4.53251 9.61158 4.56751 9.75158 4.65501 9.83908C4.74251 9.92658 4.88251 9.96158 5.05167 9.93824L6.80751 9.68741C6.95917 9.66408 7.22167 9.53574 7.32667 9.42491L11.9233 4.82825C12.3025 4.44908 12.5008 4.11075 12.53 3.79575C12.565 3.41658 12.3667 3.01408 11.9233 2.56491C10.99 1.63158 10.3483 1.89408 9.66584 2.57075Z"
        fill="white"
      />
      <path
        d="M11.5792 6.23416C11.5383 6.23416 11.4975 6.22833 11.4625 6.21666C9.92833 5.785 8.70916 4.56583 8.27749 3.03166C8.21333 2.79833 8.34749 2.55916 8.58083 2.48916C8.81416 2.425 9.05333 2.55916 9.11749 2.7925C9.46749 4.035 10.4533 5.02083 11.6958 5.37083C11.9292 5.435 12.0633 5.68 11.9992 5.91333C11.9467 6.11166 11.7717 6.23416 11.5792 6.23416Z"
        fill="white"
      />
    </svg>
  )
}

export default EditIcon
