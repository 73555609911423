import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OPEN_GANT_IDS } from "../../utils/constants/localStorageKeys";
import { sliceNames } from "../../utils/constants/sliceNames";

interface initialStateTypes {
  ids: any[];
}

const initialState: initialStateTypes = {
  ids: JSON.parse(localStorage.getItem(OPEN_GANT_IDS)!) || [],
};

const gantListStorage = createSlice({
  name: sliceNames.USER,
  initialState,
  reducers: {
    setGantListAddStorage: (state, action: PayloadAction<string[]>) => {
      state.ids = action.payload;
      localStorage.setItem(OPEN_GANT_IDS, JSON.stringify(action.payload));
    },
  },
});

export default gantListStorage.reducer;
export const gantListStorageActions = gantListStorage.actions;
