import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { exactRouteFixer } from '../../utils/helpers/exactRouteFixer'
import { workloadRoutes } from './workloadRoutes'

const WorkLoadRouter: React.FC = () => {
  return (
    <Routes>
      {workloadRoutes.map((item) => (
        <Route
          key={item.path}
          path={exactRouteFixer(item.path, item.isExact)}
          element={<item.element />}
        />
      ))}
    </Routes>
  )
}

export default WorkLoadRouter
