import React from "react";
import {Route, Routes} from "react-router-dom";
import {strikesRoutes} from "./StrikesRoutes";
import {exactRouteFixer} from "../../utils/helpers/exactRouteFixer";

const StrikesRouter: React.FC = () => {
    return (
        <Routes>
            {
                strikesRoutes.map((item) => (
                    <Route
                        key={item.path}
                        path={exactRouteFixer(item.path, item.isExact)}
                        element={<item.element/>}
                    />
                ))
            }
        </Routes>
    )
}

export default StrikesRouter