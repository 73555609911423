import React, {useState} from 'react'
import List from './components/list/List'
import Navbar from './components/navbar/Navbar'
import WebsiteModal from './components/website-modal/WebsiteModal'
import styles from './index.module.scss'

const Website = () => {
  const [openModal, setOpenModal] = useState(false)
  
  return (
    <div className={styles.container}>
        <Navbar setOpenModal={setOpenModal} />
        <List />
        <WebsiteModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  )
}

export default Website
