import { Button, Input, Switch } from 'antd'
import React, { memo, useEffect, useState } from 'react'

import CancelIcon from '../../../assets/icons/CancelIcon'
import CheckIcon from '../../../assets/icons/CheckIcon'
import {
  useChangeStatusTimeOffReason,
  useCreateTimeOffReason,
  useUpdateTimeOffReason,
} from '../../../queries/mutation'
import { colors } from '../../../utils/constants/colors'
import { permissions } from '../../../utils/constants/permissions'
import { timeOffReasonStatuses } from '../../../utils/constants/timeOffReasonStatuses'
import { hasPermission } from '../../../utils/helpers/hasPermission'

import styles from './TimeOffReason.module.scss'

type props = {
  data: {
    color: string
    name: string
    day: number
    status: string
    reason_id: number
  } | null
  setIsCreating?: (creating: boolean) => void
}

const TimeOffReason: React.FC<props> = ({ data, setIsCreating }) => {
  const [color, setColor] = useState<string>(data?.color ?? colors.PRIMARY_COLOR)
  const [status, setStatus] = useState<string>(data?.status ?? '')
  const [name, setName] = useState<string>(data?.name ?? '')
  const [day, setDay] = useState<number>(data?.day ?? 0)
  const [editable, setEditable] = useState(false)
  const createReason = useCreateTimeOffReason(afterSuccess)
  const updateReason = useUpdateTimeOffReason(afterSuccess)
  const statusChangeReason = useChangeStatusTimeOffReason(afterSuccessStatusChange)

  // is editable
  useEffect(() => {
    if (color !== data?.color || name !== data?.name || day !== data?.day) {
      setEditable(true)
    }
  }, [color, name, day, data?.color, data?.name, data?.day])

  // after succes
  function afterSuccess() {
    setEditable(false)
  }

  // after succes status change
  function afterSuccessStatusChange() {
    let newStatus

    if (status === timeOffReasonStatuses.PASSIVE) {
      newStatus = timeOffReasonStatuses.ACTIVE
    } else newStatus = timeOffReasonStatuses.PASSIVE

    setStatus(newStatus)
  }

  // on finish
  const onFinish = () => {
    const req = { color, name, day }

    if (data) {
      if (hasPermission(permissions.REASON_UPDATE)) {
        updateReason.mutate({ ...req, reason_id: data.reason_id })
      }
    } else {
      if (hasPermission(permissions.REASON_UPDATE)) {
        createReason.mutate(req)
      }
    }
  }

  // handle color
  const handleColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor(e?.target.value)
  }

  // handle name
  const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e?.target.value)
  }

  // handle day
  const handleDay = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDay(+e?.target.value)
  }

  // handle status
  const handleStatus = (checked: boolean) => {
    if (data) {
      statusChangeReason.mutate(data.reason_id)
    }
  }

  // status value
  const statusValue = () => {
    if (status === timeOffReasonStatuses.ACTIVE) return true
    return false
  }

  // handle cancel
  const handleCancel = () => {
    if (data) {
      setColor(data?.color ?? colors.PRIMARY_COLOR)
      setName(data?.name ?? '')
      setDay(data?.day ?? 0)
      setEditable(false)
    } else {
      if (setIsCreating) setIsCreating(false)
    }
  }

  return (
    <div className={styles.reason}>
      <div className={styles.color}>
        <label
          style={{
            backgroundColor: color,
          }}
        >
          <Input type="color" value={color} onChange={handleColor} />
        </label>
      </div>
      <div className={styles.name}>
        <Input value={name} onChange={handleName} />
      </div>
      <div className={styles.day}>
        <Input type="number" value={day} onChange={handleDay} />
        <span onClick={() => setEditable(true)}>day</span>
      </div>
      <Switch
        className={styles.status}
        checked={statusValue()}
        onChange={handleStatus}
        loading={statusChangeReason.isLoading}
      />
      <div className={`${styles.actions} ${editable ? styles.active : ''}`}>
        <Button
          type="primary"
          className={styles.save}
          onClick={onFinish}
          loading={createReason.isLoading || updateReason.isLoading}
        >
          <CheckIcon />
        </Button>
        <Button type="primary" className={styles.cancel} onClick={handleCancel}>
          <CancelIcon />
        </Button>
      </div>
    </div>
  )
}

export default memo(TimeOffReason)
