import { useMutation, useQueryClient } from "@tanstack/react-query";
import { $strikeapi } from "../../../services/RequestService";
import { endpoints } from "../utils/constant/endpoints";
import { queryKeys } from "../utils/constant/queryKeys";

export function useDeleteStrike() {
    const qc = useQueryClient()

    return useMutation(
        async (id: number | undefined) => {
            const res = await $strikeapi.delete(`${endpoints.DELETE}?id=${id}`)
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.EVENT_GET]) // uchib ketgandan keyin birdaniga o'zgarishi uchun
            }
        }
    )
}
