import React from 'react'

type Props = {
  color: string
}

const PriorityIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0134 8.72016L11.2 7.90683C11.0067 7.74016 10.8934 7.4935 10.8867 7.22016C10.8734 6.92016 10.9934 6.62016 11.2134 6.40016L12.0134 5.60016C12.7067 4.90683 12.9667 4.24016 12.7467 3.7135C12.5334 3.1935 11.8734 2.90683 10.9 2.90683H3.93335V2.3335C3.93335 2.06016 3.70668 1.8335 3.43335 1.8335C3.16002 1.8335 2.93335 2.06016 2.93335 2.3335V14.6668C2.93335 14.9402 3.16002 15.1668 3.43335 15.1668C3.70668 15.1668 3.93335 14.9402 3.93335 14.6668V11.4135H10.9C11.86 11.4135 12.5067 11.1202 12.7267 10.5935C12.9467 10.0668 12.6934 9.40683 12.0134 8.72016Z"
        fill={color}
      />
    </svg>
  )
}

export default PriorityIcon
