import React from 'react'

const FolderIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.75 2c0-.9665.7835-1.75 1.75-1.75h3.0858a1.75 1.75 0 011.2374.5126l1.4142 1.4142a.25.25 0 00.1768.0732H11.5c.9665 0 1.75.7835 1.75 1.75v5c0 .9665-.7835 1.75-1.75 1.75h-9C1.5335 10.75.75 9.9665.75 9V2zm1.75-.25a.25.25 0 00-.25.25v7c0 .138.112.25.25.25h9a.25.25 0 00.25-.25V4a.25.25 0 00-.25-.25H8.4142a1.75 1.75 0 01-1.2374-.5126L5.7626 1.8232a.25.25 0 00-.1768-.0732H2.5z"
      ></path>
    </svg>
  )
}

export default FolderIcon
