import { rootPaths } from '../../routing/root/rootPaths'

export const urlLastSegmentHandler = (url: string, baseUrl: string) => {
  if (url === baseUrl) return rootPaths.INDEX

  const last = url.split(rootPaths.INDEX).pop()
  return rootPaths.INDEX + last
}

export const urlFirstSegmentHandler = (url: string) => {
  const last = url.split(rootPaths.INDEX).at(1)
  return rootPaths.INDEX + last
}
