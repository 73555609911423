import { Button } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'

import {
  DATE_FORMAT,
  YEAR_MONTH_FORMAT,
  YEAR_WEEK_FORMAT,
} from '../../utils/constants/dayjsFormats'
import weekday from 'dayjs/plugin/weekOfYear'
import DatePicker from '../../services/DatePicker'
import { rootPaths } from '../../routing/root/rootPaths'
import { dayjsUnits } from '../../utils/constants/dayjsUnits'
import { permissions } from '../../utils/constants/permissions'
import PickerArrowLeft from '../../assets/icons/PickerArrowLeft'
import { hasPermission } from '../../utils/helpers/hasPermission'
import { timeOffPaths } from '../../routing/timeOff/timeOffPaths'
import PickerArrowRight from '../../assets/icons/PickerArrowRight'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { timeOffViewTypes } from '../../utils/constants/timeOffViewTypes'
import { datePickerPickers } from '../../utils/constants/datePickerPickers'
import { timeOffReducerActions } from '../../store/reducers/timeOffReducer'
import { urlLastSegmentHandler } from '../../utils/helpers/urlSegmentHandler'
import TimeOffModal from '../../components/timeOff/timeOffModal/TimeOffModal'
import { dayjsParse, dayjsStringify } from '../../utils/helpers/dayjsFormatter'
import TimeOffSettingsModal from '../../components/timeOff/timeOffSettingsModal/TimeOffSettingsModal'

import styles from './TimeOffLayout.module.scss'

dayjs.extend(weekday)

type props = {
  children: ReactNode
}

const TimeOffLayout: React.FC<props> = ({ children }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { setVisibleTimeOffModal, setVisibleTimeOffSettingsModal, setSelectedTime } =
    timeOffReducerActions
  const { selectedTime } = useAppSelector((state) => state.timeOffReducer)

  // open time off modal
  const handleOpenTimeOffModal = () => {
    dispatch(setVisibleTimeOffModal(true))
  }

  // open time off settings modal
  const handleOpenTimeOffSettingsModal = () => {
    dispatch(setVisibleTimeOffSettingsModal(true))
  }

  // links
  const links = [
    {
      path: '',
      label: 'List',
    },
    {
      path: timeOffPaths.DAILY,
      label: 'Day',
    },
    {
      path: timeOffPaths.WEEKLY,
      label: 'Weeks',
    },
    {
      path: timeOffPaths.MOTHLY,
      label: 'Months',
    },
  ]

  // link paths
  const linkPath = (path: string) => {
    return rootPaths.TIME_OFF + path
  }

  // link active class
  const linkActiveClass = (path: string) => {
    if (location.pathname === rootPaths.TIME_OFF + path) {
      return styles.active
    }
  }

  // time-off view type
  const timeOffViewType = () => {
    switch (urlLastSegmentHandler(location.pathname, rootPaths.TIME_OFF)) {
      case timeOffPaths.INDEX:
        return timeOffViewTypes.LIST
      case timeOffPaths.DAILY:
        return timeOffViewTypes.DAILY
      case timeOffPaths.WEEKLY:
        return timeOffViewTypes.WEEKLY
      case timeOffPaths.MOTHLY:
        return timeOffViewTypes.MONTHLY
    }
  }

  // visible date picker
  const isVisibleDatePicker = () => {
    if (timeOffViewType() === timeOffViewTypes.LIST) return false
    return true
  }

  // date picker format
  const datePickerFormat = () => {
    switch (timeOffViewType()) {
      case timeOffViewTypes.DAILY:
        return DATE_FORMAT
      case timeOffViewTypes.WEEKLY:
        return YEAR_WEEK_FORMAT
      case timeOffViewTypes.MONTHLY:
        return YEAR_MONTH_FORMAT
      default:
        return ''
    }
  }

  // handle time
  const handleTime = (value: Dayjs | null) => {
    const newValue = dayjsStringify(value ?? dayjs(), DATE_FORMAT)

    dispatch(setSelectedTime(newValue))
  }

  // date picker value
  const datePickerValue = () => {
    return dayjsParse(selectedTime, DATE_FORMAT)
  }

  // unit handler
  const unitHandler = () => {
    switch (timeOffViewType()) {
      case timeOffViewTypes.DAILY:
        return dayjsUnits.DAY
      case timeOffViewTypes.WEEKLY:
        return dayjsUnits.WEEK
      case timeOffViewTypes.MONTHLY:
        return dayjsUnits.MONTH
    }
  }

  // handle prev time
  const handlePrevTime = () => {
    const parsedTime = dayjsParse(selectedTime, DATE_FORMAT)
    const newTime = dayjs(parsedTime).add(-1, unitHandler())

    dispatch(setSelectedTime(dayjsStringify(newTime, DATE_FORMAT)))
  }

  // handle next time
  const handleNextTime = () => {
    const parsedTime = dayjsParse(selectedTime, DATE_FORMAT)
    const newTime = dayjs(parsedTime).add(1, unitHandler())

    dispatch(setSelectedTime(dayjsStringify(newTime, DATE_FORMAT)))
  }

  // picker type
  const pickerType = () => {
    switch (timeOffViewType()) {
      case timeOffViewTypes.DAILY:
        return datePickerPickers.DATE
      case timeOffViewTypes.WEEKLY:
        return datePickerPickers.WEEK
      case timeOffViewTypes.MONTHLY:
        return datePickerPickers.MONTH
    }
  }

  return (
    <div className={styles.time_off_layout}>
      <div className={styles.body}>
        <div className={styles.body_topbar}>
          <div className={styles.left}>
            <div className={styles.tabs}>
              {links.map((link) => (
                <Link
                  className={linkActiveClass(link.path)}
                  to={linkPath(link.path)}
                  key={link.path}
                >
                  {link.label}
                </Link>
              ))}
            </div>
          </div>
          {isVisibleDatePicker() && (
            <div className={styles.center}>
              <span className={styles.arrow} onClick={handlePrevTime}>
                <PickerArrowLeft />
              </span>
              <DatePicker
                placement="bottomRight"
                format={datePickerFormat()}
                bordered={false}
                picker={pickerType()}
                onChange={handleTime}
                value={datePickerValue()}
              />
              <span className={styles.arrow} onClick={handleNextTime}>
                <PickerArrowRight />
              </span>
            </div>
          )}
          <div className={styles.right}>
            {hasPermission(permissions.REASON_VIEW) && (
              <Button type="primary" onClick={handleOpenTimeOffSettingsModal}>
                Settings
              </Button>
            )}
            <Button type="primary" onClick={handleOpenTimeOffModal}>
              Add
            </Button>
          </div>
        </div>
        <div className={styles.body_content}>{children}</div>
      </div>
      <TimeOffModal />
      <TimeOffSettingsModal />
    </div>
  )
}

export default TimeOffLayout
