import React from 'react'

type props = {
  className?: string
}

const Logo: React.FC<props> = ({ className }) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="-0.757324"
        y="19.7428"
        width="28"
        height="4"
        rx="2"
        transform="rotate(-45 -0.757324 19.7428)"
        fill="#3A57E8"
      />
      <rect
        x="7.72803"
        y="28.228"
        width="28"
        height="4"
        rx="2"
        transform="rotate(-45 7.72803 28.228)"
        fill="#3A57E8"
      />
      <rect
        x="10.5364"
        y="16.8945"
        width="16"
        height="4"
        rx="2"
        transform="rotate(45 10.5364 16.8945)"
        fill="#3A57E8"
      />
      <rect
        x="10.5564"
        y="-0.0562744"
        width="28"
        height="4"
        rx="2"
        transform="rotate(45 10.5564 -0.0562744)"
        fill="#3A57E8"
      />
    </svg>
  )
}

export default Logo
