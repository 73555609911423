import {useQuery} from "@tanstack/react-query";
import {queryKeys} from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import {endpoints} from "../utils/constants/endpoints";
import {ErrorRes} from "../../../utils/model/responseType";
import {DashboardModel} from "../utils/models/dashboardModel";
import {errorHandler} from "../../../utils/helpers/errorHandler";
import {useNavigate} from "react-router-dom";
import {isEmptyArr} from "../../../utils/helpers/isEmptyArr";
import {ChartsModel} from "../utils/models/chartsModel";
import {useQueryParams} from "../../../hooks/useQueryParams";

// get dashboards
export const useGetDashboards = () => {
  const navigate = useNavigate()
  const {getParams} = useQueryParams()

  return useQuery<DashboardModel[], ErrorRes>(
    [queryKeys.DASHBOARD_VIEW],
    async () => {
      const res = await $api.get(endpoints.DASHBOARD_VIEW)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      onSuccess: (res) => {
        if (!isEmptyArr(res) && !getParams()?.id) {
          navigate({
            search: `?id=${res[0]?.id}`
          })
        }
      }
    }
  )
}

// get charts
export const useGetCharts = (id: string | undefined) => {
  return useQuery<ChartsModel, ErrorRes>(
    [queryKeys.CHART_VIEW, id],
    async () => {
      const res = await $api.get(`${endpoints.CHART_VIEW}?dashboard_id=${id}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id,
      onError: errorHandler,
    }
  )
}