import { Button, Form, Input, Modal, Spin } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'

import ModalCloseIcon from '../../../../assets/icons/ModalCloseIcon'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { workloadReducerActions } from '../../../../store/reducers/workloadReducer'
import { DAY_OF_WEEK_FULL } from '../../../../utils/constants/dayjsFormats'
import { createArr } from '../../../../utils/helpers/createArr'
import { requiredFormItem } from '../../../../utils/helpers/requiredFormItem'
import {
  useChangeUserPositon,
  useUpdateDailyWorkload,
} from '../../services/mutations'

import styles from './WorkloadModal.module.scss'
const { List, Item } = Form

const WorkloadModal: React.FC = () => {
  const [formInstance] = Form.useForm()
  const [userPositionFormInstance] = Form.useForm()
  const dispatch = useAppDispatch()
  const { setVisibleWorkloadModal } = workloadReducerActions
  const updateDailyWorkload = useUpdateDailyWorkload(handleAfterSuccess)
  const changeUserPosition = useChangeUserPositon(handleAfterSuccess)
  const { visibleWorkloadModal, workloadModalData } = useAppSelector(
    (state) => state.workloadReducer
  )

  // initial form fields
  useEffect(() => {
    if (workloadModalData && visibleWorkloadModal) {
      formInstance.setFieldsValue({ ...workloadModalData })
      userPositionFormInstance.setFieldsValue({position:workloadModalData.position })
    }
  }, [workloadModalData, formInstance, visibleWorkloadModal])

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleWorkloadModal(false))
    resetFormValues()
  }

  // handle after close
  const resetFormValues = () => {
    formInstance.resetFields();
    userPositionFormInstance.resetFields();
  };

  // on finish
  const onFinish = (fields: { daily_workload: string[] }) => {
    updateDailyWorkload.mutate({
      daily_workload: fields?.daily_workload?.map((item) => +item),
      user_id: workloadModalData?.user_id,
      position: workloadModalData?.position
    })
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // handle day of week
  const handleDayOfWeek = (index: number) => {
    return dayjs().weekday(index).format(DAY_OF_WEEK_FULL)
  }

  // handle after success
  function handleAfterSuccess() {
    dispatch(setVisibleWorkloadModal(false))
  }

  // handle position
  const handlePosition = ({ position }: { position: string }) => {
    changeUserPosition.mutate({
      position: +position,
      user_id: workloadModalData?.user_id!,
    })
  }
  
  return (
    <Modal
      centered
      title={null}
      footer={null}
      closable={false}
      onCancel={handleCancel}
      // afterClose={resetFormValues}
      className={`${styles.workload_modal} common_modal`}
      open={visibleWorkloadModal}
    >
      <div className={`${styles.workload_modal_header} common_modal_header`}>
        <p>Daily workload</p>
        <Form form={userPositionFormInstance} onFinish={handlePosition}>
          <Spin spinning={changeUserPosition.isLoading}>
            <Item name={'position'} initialValue={workloadModalData?.position} rules={[{ required: true, message: '' }]}>
              <Input type="number" />
            </Item>
          </Spin>
        </Form>
        <ModalCloseIcon onClickFunc={handleCancel} />
      </div>
      <Form
        className={`common_modal_body`}
        onFinish={onFinish}
        form={formInstance}
        labelCol={{ span: 10 }}
      >
        <List name="daily_workload" initialValue={createArr(7, null)}>
          {(fields) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return(
                    <div key={key}>
                    <Item
                      {...{ label: `${handleDayOfWeek(key)}` }}
                      initialValue=""
                      rules={[requiredFormItem()]}
                      name={[name]}
                      {...restField}
                    >
                      <Input type="number" />
                    </Item>
                  </div>
                  )
                })}
              </>
            )
          }}
        </List>
        <Button htmlType="submit" className="d_n" />
      </Form>
      <div className={`common_modal_footer`}>
        <Button
          type="primary"
          className="submit_btn"
          onClick={handleSubmit}
          loading={updateDailyWorkload.isLoading}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default WorkloadModal
