import dayjs from 'dayjs'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { sliceNames } from '../../utils/constants/sliceNames'
import { TimeOffListRes } from '../../utils/model/timeOffModel'
import { DATE_FORMAT } from '../../utils/constants/dayjsFormats'
import { dayjsStringify } from '../../utils/helpers/dayjsFormatter'

const initialState: {
  visibleTimeOffModal: boolean
  visibleTimeOffSettingsModal: boolean
  selectedTimeOff: TimeOffListRes | null
  selectedTime: string
} = {
  selectedTimeOff: null,
  visibleTimeOffModal: false,
  visibleTimeOffSettingsModal: false,
  selectedTime: dayjsStringify(dayjs(), DATE_FORMAT),
}

const timeOffReducer = createSlice({
  name: sliceNames.TIME_OFF,
  initialState,
  reducers: {
    setVisibleTimeOffSettingsModal: (state, action: PayloadAction<boolean>) => {
      state.visibleTimeOffSettingsModal = action.payload
    },
    setVisibleTimeOffModal: (state, action: PayloadAction<boolean>) => {
      state.visibleTimeOffModal = action.payload
    },
    setSelectedTimeOff: (state, action: PayloadAction<TimeOffListRes | null>) => {
      state.selectedTimeOff = action.payload
    },
    setSelectedTime: (state, action: PayloadAction<string>) => {
      state.selectedTime = action.payload
    },
  },
})

export default timeOffReducer.reducer
export const timeOffReducerActions = timeOffReducer.actions
