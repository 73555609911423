import React, { useMemo } from "react";
import Strike from "./components/strike/Strike";
import Header from "../workload/components/header/Header";
import styles from "./index.module.scss";
import { useAppSelector } from "../../hooks/redux";
import { createArr } from "../../utils/helpers/createArr";
import { dayjsUnits } from "../../utils/constants/dayjsUnits";
import { workloadViewTypes } from "../workload/utils/enums/workloadViewTypes";
import {
  DATE_FORMAT,
  DAY,
  DAY_OF_WEEK,
  DEFAULT_DATE_FORMAT,
} from "../../utils/constants/dayjsFormats";
import dayjs from "dayjs";
import { dayjsParse } from "../../utils/helpers/dayjsFormatter";
import { VIEW_TYPE } from "../../utils/constants/queryParamsKeys";
import { useSearchParams } from "react-router-dom";
import {useGetAllTasks} from "./services/queries";

const Index = () => {
  const { selectedTime } = useAppSelector((state) => state.workloadReducer);
  const { data } = useGetAllTasks(selectedTime);
  const [searchParams] = useSearchParams();

  // parsed selected time
  const parsedSelectedTime = useMemo(
    () => dayjsParse(selectedTime, DATE_FORMAT),
    [selectedTime]
  );

  // view type/
  const viewType = useMemo(() => {
    return searchParams.get(VIEW_TYPE) ?? workloadViewTypes.WEEKLY;
  }, [searchParams]);

  // first day
  const firstDay = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.WEEK);
    } else {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.MONTH);
    }
  }, [selectedTime, viewType]);

  // count of days
  const countOfDays = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) return 7;
    else
      return dayjs(
        dayjsParse(selectedTime, DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
      ).daysInMonth();
  }, [viewType, selectedTime]);

  // units
  const units = {
    [workloadViewTypes.WEEKLY]: dayjsUnits.WEEK,
    [workloadViewTypes.MONTHLY]: dayjsUnits.MONTH,
  };

  console.log(data)

  return (
    <div className={styles.strike}>
      <Header />
      <div className={styles.workloads_body_top}>
        <div className={styles.left}>
          <p>Assignees {data?.length}</p>
        </div>
        <div className={styles.right}>
          {createArr(countOfDays).map((_, index) => {
            const isToday = firstDay.add(index, dayjsUnits.DAY).isToday();

            return (
              <div
                className={`${styles.item} ${isToday ? styles.active : ""}`}
                key={index}
              >
                {viewType === workloadViewTypes.WEEKLY && (
                  <span>
                    {parsedSelectedTime
                      .startOf(units[viewType])
                      .add(index, dayjsUnits.DAY)
                      .format(DAY_OF_WEEK)}
                  </span>
                )}


                <span>
                  {parsedSelectedTime
                    .startOf(units[viewType as keyof typeof units])
                    .add(index, dayjsUnits.DAY)
                    .format(DAY)}
                  s
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {data?.map((item) => {
        return <Strike {...item} key={item.id} />;
      })}
    </div>
  );
};

export default Index;
