import { Button, Form, Input, Modal, Select } from 'antd';
import React from 'react'
import { useViewEmployeesSelect } from '../../../../queries/queries';
import { useCreateWebsite } from '../../services/mutation';
import styles from './website.module.scss'

const { Option } = Select

interface IProps {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
    openModal: boolean
}

const WebsiteModal: React.FC<IProps> = ({ openModal, setOpenModal }) => {

    const [form] = Form.useForm()
    const {data: employees} = useViewEmployeesSelect();

    const handleCancel = () => {
        setOpenModal(false);
    };
    const createWebsite = useCreateWebsite();

    const onFinish = (values: any) => {
        createWebsite.mutateAsync({
            responsible_id: values.responsible_id,
            status: values.status,
            url: values.url
        }).then(() => {
            handleCancel()
            form.resetFields()
        })
    }


    return (
        <>
            <Modal
                open={openModal}
                onCancel={handleCancel}
                footer={false}
                className={styles.modal}
            >
                <div className={styles.modal_title}>
                    <h4>Site checker</h4>
                    <p>Create your site details.</p>
                </div>
                <Form name='site_modal' onFinish={onFinish} form={form} layout='vertical'>
                    <Form.Item label='Site url' name={'url'}>
                        <Input placeholder='Site url' />
                    </Form.Item>
                    <Form.Item label='Responsible employee' name={'responsible_id'}>
                        <Select placeholder='Responsible employee'>
                            {
                                employees?.map((item) => (
                                    <Option key={item.user_id}>{item.first_name}</Option>
                                ))
                            }
                        </Select>
                    </Form.Item>
                    {/* <Form.Item label='Status' name={"status"} valuePropName="checked">
                        <h4>Choose site status</h4>
                        <Switch />
                    </Form.Item> */}
                    <div className={styles.modal_btns}>
                        <Button onClick={handleCancel}>Cencel</Button>
                        <Button htmlType='submit' loading={createWebsite.isLoading}>Create</Button>
                    </div>
                </Form>
            </Modal>
        </>
    );
}

export default WebsiteModal
