import { createViewsType, updateViewsType } from './../utils/models/WebsiteView';
import { message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from "@tanstack/react-query";
import $api from '../../../services/RequestService';
import { endPoints } from '../utils/constants/endPoints';
import { queryKeys } from '../utils/constants/queryKeys';

export function useWebsiteDelete() {
  const qc = useQueryClient();
  return useMutation(async (id: number) => {
    const res = await $api.delete(endPoints.INDEX + "/delete?id=" + id);
    return res.data
  }, {
    onError: (err: any) => {
      message.error(err.response.data.errors[0].errorMsg)
    },
    onSuccess: () => {
      message.success("Muvaffaqiyatli")
      qc.invalidateQueries([queryKeys.WEBSITE])
    }
  }
  )
}

export function useWebsiteUpdate() {
  const qc = useQueryClient();
  return useMutation(async (data: updateViewsType) => {
    const res = await $api.put(endPoints.INDEX + "/update", data);
    return res.data
  }, {
    onError: (err: any) => {
      let messageText = '';
      Object.values(err.response?.data?.error)?.forEach((item: any) => {
        messageText += item[0] + "\n"
      })
      message.error(messageText)
    },
    onSuccess: () => {
      message.success("Muvaffaqiyatli")
      qc.invalidateQueries([queryKeys.WEBSITE])
    }
  }
  )
}

export function useCreateWebsite() {
  const qc = useQueryClient();
  return useMutation(async (data: createViewsType) => {
    const res = await $api.post(endPoints.INDEX + '/create', data);
    return res.data
  }, {
    onError: (err: any) => {
      let messageText = '';
      Object.values(err.response?.data?.messages[0])?.forEach((item: any) => {
        messageText += item[0] + "\n"
      })
      message.error(messageText);
    },
    onSuccess: () => {
      message.success("Muvaffaqiyatli")
      qc.invalidateQueries([queryKeys.WEBSITE])
    }
  })
}

export function useGetCron() {
  const qc = useQueryClient()
  return useMutation(async () => {
    const res = await $api.get(endPoints.CRON)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.CRON])
      message.success("Baza yangilandi")
    }
  })
}