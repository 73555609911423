import { Button } from 'antd'
import React, { ReactNode } from 'react'

import { useAppDispatch } from '../../hooks/redux'
import { employeesReducerActions } from '../../store/reducers/employeesReducer'
import EmployeesModal from '../../components/employees/employeesModal/EmployeesModal'

import styles from './EmployeesLayout.module.scss'

type props = {
  children: ReactNode
}

const EmployeesLayout: React.FC<props> = ({ children }) => {
  const dispatch = useAppDispatch()
  const { setVisibleEmployeesModal } = employeesReducerActions

  // open employees modal
  const handleOpenEmployeesModal = () => {
    dispatch(setVisibleEmployeesModal(true))
  }

  return (
    <div className={styles.employees_layout}>
      <div className={styles.header}>
        <Button
          type="primary"
          className="common_btn"
          onClick={handleOpenEmployeesModal}
        >
          Add
        </Button>
      </div>
      <div className={styles.body}>{children}</div>
      <EmployeesModal />
    </div>
  )
}

export default EmployeesLayout
