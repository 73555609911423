import { Spin } from 'antd'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import weekday from 'dayjs/plugin/weekday'
import React, { useMemo } from 'react'

import { useAppSelector } from '../../hooks/redux'
import {
  DATE_FORMAT,
  DAY,
  DAY_OF_WEEK,
  DEFAULT_DATE_FORMAT,
} from '../../utils/constants/dayjsFormats'
import { createArr } from '../../utils/helpers/createArr'
import { dayjsParse } from '../../utils/helpers/dayjsFormatter'
import Header from './components/header/Header'
import TaskModal from './components/task-modal/TaskModal'
import WorkloadItem from './components/workload-item/WorkloadItem'
import WorkloadModal from './components/workload-modal/WorkloadModal'
import { useGetWorkloads } from './services/queries'

import styles from './Index.module.scss'
import { useSearchParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../utils/constants/queryParamsKeys'
import { workloadViewTypes } from './utils/enums/workloadViewTypes'
import { dayjsUnits } from '../../utils/constants/dayjsUnits'

dayjs.extend(weekday)
dayjs.extend(isToday)

const Index: React.FC = () => {
  const { selectedTime } = useAppSelector((state) => state.workloadReducer)
  const { data, isLoading } = useGetWorkloads(selectedTime)
  const [searchParams] = useSearchParams()


  // parsed selected time
  const parsedSelectedTime = useMemo(
    () => dayjsParse(selectedTime, DATE_FORMAT),
    [selectedTime]
  )

  // view type/
  const viewType = useMemo(() => {
    return searchParams.get(VIEW_TYPE) ?? workloadViewTypes.WEEKLY
  }, [searchParams])

  // first day
  const firstDay = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.WEEK)
    } else {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.MONTH)
    }
  }, [selectedTime, viewType])

  // count of days
  const countOfDays = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) return 7
    else
      return dayjs(
        dayjsParse(selectedTime, DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
      ).daysInMonth()
  }, [viewType, selectedTime])

  // units
  const units = {
    [workloadViewTypes.WEEKLY]: dayjsUnits.WEEK,
    [workloadViewTypes.MONTHLY]: dayjsUnits.MONTH,
  }

  return (
    <div className={styles.workloads}>
      <Header />
      <Spin spinning={isLoading}>
        <div className={styles.workloads_body}>
          <div className={styles.workloads_body_top}>
            <div className={styles.left}>
              <p>Assignees {data?.length}</p>
            </div>
            <div className={styles.right}>
              {createArr(countOfDays).map((_, index) => {
                const isToday = firstDay.add(index, dayjsUnits.DAY).isToday()

                return (
                  <div
                    className={`${styles.item} ${isToday ? styles.active : ''}`}
                    key={index}
                  >
                    {viewType === workloadViewTypes.WEEKLY && (
                      <span>
                        {parsedSelectedTime
                          .startOf(units[viewType])
                          .add(index, dayjsUnits.DAY)
                          .format(DAY_OF_WEEK)}
                      </span>
                    )}
                    <span>
                      {parsedSelectedTime
                        .startOf(units[viewType as keyof typeof units])
                        .add(index, dayjsUnits.DAY)
                        .format(DAY)}
                    </span>
                  </div>
                )
              })}
            </div>


          </div>
          <div className={styles.workloads_body_bottom}>
            {data?.map((item) => {
              return (
                  <WorkloadItem {...item} key={item.user_id}/>
              )
            })}
            <div className={styles.lines}>
              {createArr(countOfDays).map((item) => (
                  <div className={styles.line}></div>
              ))}
            </div>
          </div>
        </div>
      </Spin>
      <WorkloadModal/>
      <TaskModal/>
    </div>
  )
}

export default Index
