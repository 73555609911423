import React from 'react'

import Timeline from '../../common/timeline/Timeline'
import { useViewTimeOffDaily } from '../../../queries/queries'
import { timeOffViewTypes } from '../../../utils/constants/timeOffViewTypes'

const TimeOffDaily: React.FC = () => {
  const { data, isLoading } = useViewTimeOffDaily()

  return <Timeline data={data} isLoading={isLoading} type={timeOffViewTypes.DAILY} />
}

export default TimeOffDaily
