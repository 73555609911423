import Index from '../../features/workload/Index'
import { RouteType } from '../../utils/model/routeModel'
import { workloadPaths } from './workloadPaths'

export const workloadRoutes: RouteType[] = [
  {
    path: workloadPaths.INDEX,
    element: Index,
    isExact: true,
  },
]
