import React, {useState} from "react";
import dayjs, {Dayjs} from "dayjs";

import {ListModel} from "../../utils/models/listModel";
import {useViewTasks} from "../../services/queries";
import Task from "../task/Task";
import ListIcon from "../../assets/icons/ListIcon";

import styles from "../../index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../../hooks/redux";
import {gantListStorageActions} from "../../../../store/reducers/gantListSlice";
import {dayjsUnits} from "../../../../utils/constants/dayjsUnits";
import MTitle from "../../../../components/common/MTitle/MTitle";
import {useSearchParams} from "react-router-dom";

type Props = {
    ganttStart: Dayjs;
};

const List: React.FC<Props & ListModel> = ({
                                               done_tasks_count,
                                               due_date,
                                               id,
                                               name,
                                               start_date,
                                               tasks_count,
                                               ganttStart,
                                           }) => {
    const gantListStorage = useAppSelector((store) => store.gantListSlice.ids);
    const dispatch = useAppDispatch();
    const [openList, setOpenList] = useState(gantListStorage?.includes(id));
    const {data} = useViewTasks(openList ? String(id) : "");
    const [searchParams] = useSearchParams()

    const handleCollapse = () => {
        if (openList && gantListStorage.includes(id)) {
            dispatch(
                gantListStorageActions.setGantListAddStorage([
                    ...gantListStorage.filter((item) => item !== id),
                ])
            );
        } else {
            dispatch(
                gantListStorageActions.setGantListAddStorage([...gantListStorage, id])
            );
        }
        setOpenList(!openList);
    };

    const leftLength = dayjs.unix(start_date).diff(ganttStart, dayjsUnits.DAY);
    const rightLength = dayjs.unix(due_date).diff(ganttStart, dayjsUnits.DAY);
    const listWidth = rightLength - leftLength;
    const comleteTask = (done_tasks_count / (tasks_count / 100)).toFixed(1);

    const listIdFromParam = searchParams.getAll("listId")
    if (listIdFromParam.length > 0 && !listIdFromParam.includes(id.toString())) {
        return null
    }

    return (
        <div className={`${styles.list} ${styles.gantt_row}`}>
            <div className={styles.top} onClick={handleCollapse}>
                <div className={styles.left}>
          <span
              className={
                  openList ? styles.left_open_icon : styles.left_close_icon
              }
          ></span>
                    <ListIcon/>
                    <p>{name}</p>
                </div>
                <div className={styles.right}>
                    <MTitle content={name + " - " + comleteTask + "%"}>
                        <div
                            className={styles.right_line}
                            style={{left: leftLength * 25, width: listWidth * 25}}
                        >
                            <span style={{width: comleteTask + "%"}}></span>
                        </div>
                    </MTitle>
                </div>
            </div>
            <div className={styles.bottom}>
                {openList &&
                    data?.map((item) => (
                        <Task
                            {...item}
                            key={item.id}
                            ganttStart={ganttStart}
                            paddingLeft={95}
                            listId={String(id)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default List;
