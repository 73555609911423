import React, {useMemo} from 'react';
import {PieChart as ReactPieChart, Pie, Cell, ResponsiveContainer, Tooltip} from 'recharts';

import {PieChartModel} from "../../../utils/models/chartsModel";
import ChartLayout from "../chart-layout/ChartLayout";

import styles from './pieChart.module.scss'

const PieChart: React.FC<PieChartModel> = (props) => {
  const {statistics} = props

  // colors
  const colors = {
    "Open": "#d3d3d3",
    "Pending": '#FFCC00',
    "In progress": "#04A9F4",
    "Rejected": "#e50000",
    "Completed": "#2ecd6f",
    "Done": "#6bc950",
  }

  // total
  const total = useMemo(() => {
    return statistics?.reduce((sum, current) => sum + current.count, 0)
  }, [statistics])

  return (
    <ChartLayout data={props}>
      <p className={styles.total}>{total}</p>
      <ResponsiveContainer width="100%" height="100%">
        <ReactPieChart>
          <Pie
            width="100%"
            height="100%"
            data={statistics}
            innerRadius={100}
            fill="#8884d8"
            paddingAngle={2}
            dataKey="count"
            label
          >
            {statistics?.map((item, index) => (
              <Cell key={index} fill={colors[item.status as keyof typeof colors]}/>
            ))}
          </Pie>
          <Tooltip formatter={(value, _, props) => [value, props?.payload?.payload?.status]}/>
        </ReactPieChart>
      </ResponsiveContainer>
    </ChartLayout>
  );
};

export default PieChart;