import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'

import App from './App'
import { colors } from './utils/constants/colors'
import { elementIds } from './utils/constants/elementIds'

ConfigProvider.config({
  theme: {
    primaryColor: colors.PRIMARY_COLOR,
  },
})

const root = ReactDOM.createRoot(
  document.getElementById(elementIds.ROOT) as HTMLElement
)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
