import React from "react";

const ClickUpIcon = () => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 15.3657L3.07539 13.0098C4.70931 15.1423 6.44525 16.1252 8.37755 16.1252C10.2995 16.1252 11.9864 15.1539 13.5467 13.0382L16.666 15.3373C14.4147 18.3881 11.617 20.0001 8.37755 20.0001C5.14846 20.0001 2.32366 18.3985 0 15.3657Z"
        fill="url(#paint0_linear_1159_4968)"
      />
      <path
        d="M8.36755 5.12522L2.8936 9.84229L0.363281 6.90768L8.3792 0L16.3318 6.91285L13.7898 9.83713L8.36755 5.12522Z"
        fill="url(#paint1_linear_1159_4968)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1159_4968"
          x1="0"
          y1="17.7639"
          x2="16.666"
          y2="17.7639"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1159_4968"
          x1="0.363281"
          y1="6.69374"
          x2="16.3318"
          y2="6.69374"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ClickUpIcon;
