export const reduceTimeSpent = (arr: {
  id: string
  name: string
  time_spent: number
}[]) => {
  return arr?.map((item, index) => {
    return {
      ...item,
      time_spent: item.time_spent ? arr.reduce((sum, current, currentIndex) => {
        if (currentIndex < index) {
          return Math.round(sum + current.time_spent)
        }
        return sum
      }, Math.round(item.time_spent)) : null
    }
  })
}