import { errorHandler } from './../../../utils/helpers/errorHandler';
import { endPoints } from './../utils/constants/endPoints';
import { useQuery } from "@tanstack/react-query";
import { ErrorRes } from "../../../utils/model/responseType";
import { WebsiteView } from "../utils/models/WebsiteView";
import { queryKeys } from '../utils/constants/queryKeys'
import $api from "../../../services/RequestService";
import { PAGE } from '../../../utils/constants/queryParamsKeys';

export function useWebsiteView(page: string) {
  return useQuery<WebsiteView, ErrorRes>(
    [queryKeys.WEBSITE, page],
    async () => {
      const res = await $api.get(`${endPoints.WEBSITE}?${PAGE}=${page}`)
      return res.data
    }, {
    refetchOnWindowFocus: false,
    retry: false,
    onError: errorHandler
  }
  )
}