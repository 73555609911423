import React, {useMemo} from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

import ChartLayout from "../chart-layout/ChartLayout";
import {BurnupChartModel} from "../../../utils/models/chartsModel";
import {reduceTimeSpent} from "../../../utils/helpers/reduceTimeSpent";

const BurnupChart: React.FC<BurnupChartModel> = (props) => {
  const {statistics} = props

  // empty object
  const emptyObject = useMemo(() => {
    return {
      id: '1',
      name: 'a',
      time_spent: 0
    }
  }, [])

  // extra
  // const extra = [
  //   {id: "900701946085", name: "Sprint 9 (29/5/23 - 4/6/23)", time_spent: 736},
  //   {id: "900701946085", name: "Sprint 9 (29/5/23 - 4/6/23)1", time_spent: 400},
  //   {id: "900701946085", name: "Sprint 9 (29/5/23 - 4/6/23)2", time_spent: null},
  //   {id: "900701946085", name: "Sprint 9 (29/5/23 - 4/6/23)3", time_spent: null},
  //   {id: "900701946085", name: "Sprint 9 (29/5/23 - 4/6/23)8", time_spent: null}
  // ]

  // custom data
  const customData = useMemo(() => {
    const reduced = reduceTimeSpent(statistics)

    return [emptyObject, ...reduced]
  }, [statistics, emptyObject])


  return (
    <ChartLayout data={props}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={customData}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis dataKey="time_spent"/>
          <Tooltip/>
          <Line type="monotone" dataKey="time_spent" stroke="#8884d8" activeDot={{r: 8}}/>
        </LineChart>
      </ResponsiveContainer>
    </ChartLayout>
  );
};

export default BurnupChart;