import React from 'react'

const SelectSuffixIcon: React.FC = () => {
  return (
    <svg
      width="11"
      height="5"
      viewBox="0 0 11 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70342 4.38661C5.77857 4.4517 5.89013 4.4517 5.96528 4.38661L10.625 0.351186C10.765 0.229959 10.6792 0 10.4941 0H1.17464C0.989467 0 0.903732 0.229959 1.04371 0.351186L5.70342 4.38661Z"
        fill="#7C828D"
      />
    </svg>
  )
}

export default SelectSuffixIcon
