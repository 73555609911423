import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

import {VelocityChartModel} from "../../../utils/models/chartsModel";
import ChartLayout from "../chart-layout/ChartLayout";

const VelocityChart: React.FC<VelocityChartModel> = (props) => {
  const {statistics} = props

  return (
    <ChartLayout data={props}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={statistics}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <Legend/>
          <Bar dataKey="time_estimate" fill="#1890FF"/>
          <Bar dataKey="time_spent" fill="#2FC25B"/>
        </BarChart>
      </ResponsiveContainer>
    </ChartLayout>
  );
};

export default VelocityChart;