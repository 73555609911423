import React from 'react'

const SubtaskIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33325 11.6667C3.05992 11.6667 2.83325 11.44 2.83325 11.1667V6.5C2.83325 6.22667 3.05992 6 3.33325 6C3.60659 6 3.83325 6.22667 3.83325 6.5V11.1667C3.83325 11.44 3.60659 11.6667 3.33325 11.6667Z"
        fill="#292D32"
      />
      <path
        d="M3.49992 6.66683C2.02659 6.66683 0.833252 5.4735 0.833252 4.00016C0.833252 2.52683 2.02659 1.3335 3.49992 1.3335C4.97325 1.3335 6.16658 2.52683 6.16658 4.00016C6.16658 5.4735 4.97325 6.66683 3.49992 6.66683ZM3.49992 2.3335C2.57992 2.3335 1.83325 3.08016 1.83325 4.00016C1.83325 4.92016 2.57992 5.66683 3.49992 5.66683C4.41992 5.66683 5.16658 4.92016 5.16658 4.00016C5.16658 3.08016 4.41992 2.3335 3.49992 2.3335Z"
        fill="#292D32"
      />
      <path
        d="M3.33325 15.6665C1.95325 15.6665 0.833252 14.5465 0.833252 13.1665C0.833252 11.7865 1.95325 10.6665 3.33325 10.6665C4.71325 10.6665 5.83325 11.7865 5.83325 13.1665C5.83325 14.5465 4.71325 15.6665 3.33325 15.6665ZM3.33325 11.6665C2.50659 11.6665 1.83325 12.3398 1.83325 13.1665C1.83325 13.9932 2.50659 14.6665 3.33325 14.6665C4.15992 14.6665 4.83325 13.9932 4.83325 13.1665C4.83325 12.3398 4.15992 11.6665 3.33325 11.6665Z"
        fill="#292D32"
      />
      <path
        d="M12.6667 15.6665C11.2867 15.6665 10.1667 14.5465 10.1667 13.1665C10.1667 11.7865 11.2867 10.6665 12.6667 10.6665C14.0467 10.6665 15.1667 11.7865 15.1667 13.1665C15.1667 14.5465 14.0467 15.6665 12.6667 15.6665ZM12.6667 11.6665C11.8401 11.6665 11.1667 12.3398 11.1667 13.1665C11.1667 13.9932 11.8401 14.6665 12.6667 14.6665C13.4934 14.6665 14.1667 13.9932 14.1667 13.1665C14.1667 12.3398 13.4934 11.6665 12.6667 11.6665Z"
        fill="#292D32"
      />
      <path
        d="M12.1133 11.6933C11.9067 11.6933 11.7133 11.5667 11.64 11.36C11.1533 9.96 9.83332 9.01999 8.34666 9.01999C8.33999 9.01999 8.33999 9.01999 8.33332 9.01999L6.04666 9.02667C6.03999 9.02667 6.03999 9.02667 6.03332 9.02667C4.57332 9.02667 3.29999 8.04 2.93332 6.62666C2.86666 6.36 3.02666 6.08666 3.29332 6.01999C3.55999 5.95333 3.83332 6.11333 3.89999 6.38C4.15332 7.35333 5.02666 8.03332 6.03332 8.03332H6.03999L8.32666 8.02667C8.33332 8.02667 8.33999 8.02667 8.34666 8.02667C10.2533 8.02667 11.9533 9.23333 12.58 11.04C12.6733 11.3 12.5333 11.5867 12.2733 11.6733C12.22 11.68 12.1667 11.6933 12.1133 11.6933Z"
        fill="#292D32"
      />
    </svg>
  )
}

export default SubtaskIcon
