import React from "react";
import { Route, Routes } from "react-router-dom";

import MainLayout from "../../layouts/mainLayout/MainLayout";
import Login from "../../pages/login/Login";
import { exactRouteFixer } from "../../utils/helpers/exactRouteFixer";
import CheckAuth from "../CheckAuth";
import RequireAuth from "../RequireAuth";
import RequirePermission from "../RequirePermission";
import { rootPaths } from "./rootPaths";
import { rootRoutes } from "./rootRoutes";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { allQueryKeys } from "../../utils/helpers/allQueryKeys";
import Index from "../../features/strikes/Index";
import Gantt from '../../features/gantt/Index'
import Workload from '../../features/workload/Index'
import IndexDashboard from "../../features/dashboard/Index";

const RootRouter: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24, //24 hour
        queryKey: allQueryKeys,
      },
    },
  });

  const localStoragePersister = createSyncStoragePersister({
    storage: window.localStorage,
  });

  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister: localStoragePersister }}
    >
      <Routes>
        <Route element={<CheckAuth />}>
          <Route path={rootPaths.INDEX} element={<Login />} />
        </Route>
        <Route element={<MainLayout />}>
          <Route element={<RequireAuth />}>
            {rootRoutes.map((item) => {
              return (
                <Route
                  element={
                    <RequirePermission permission={item.neededPermissions} />
                  }
                  key={item.path}
                >
                  <Route
                    path={exactRouteFixer(item.path, item.isExact)}
                    element={<item.element />}
                  />
                </Route>
              );
            })}
          </Route>
        </Route>
        <Route path="/strikes/embed" element={<Index />} />
        <Route path="/gantt/embed" element={<Gantt />} />
        <Route path="/workload/embed" element={<Workload/>} />
        <Route path="/dashboard/embed" element={<IndexDashboard/>}/>
      </Routes>
    </PersistQueryClientProvider>
  );
};

export default RootRouter;
