import {Button, Empty, Popover, Progress, Tooltip} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import EditIcon from '../../../../assets/icons/EditIcon'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { workloadReducerActions } from '../../../../store/reducers/workloadReducer'
import {
  DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from '../../../../utils/constants/dayjsFormats'
import { dayjsUnits } from '../../../../utils/constants/dayjsUnits'
import { permissions } from '../../../../utils/constants/permissions'
import { VIEW_TYPE } from '../../../../utils/constants/queryParamsKeys'
import { createArr } from '../../../../utils/helpers/createArr'
import { dayjsParse } from '../../../../utils/helpers/dayjsFormatter'
import { hasPermission } from '../../../../utils/helpers/hasPermission'
import { isEmptyArr } from '../../../../utils/helpers/isEmptyArr'
import PriorityIcon from '../../assets/icons/PriorityIcon'
import SubtaskIcon from '../../assets/icons/SubtaskIcon'
import { workloadViewTypes } from '../../utils/enums/workloadViewTypes'
import { WorkloadType } from '../../utils/models/workloadModel'

import styles from './WorkloadItem.module.scss'

const WorkloadItem: React.FC<WorkloadType> = ({
  first_name,
  image,
  last_name,
  tasks,
  user_id,
  daily_workload,
  overdue_tasks_count,
  unscheduled_tasks_count,
  position,
}) => {
  const dispatch = useAppDispatch()
  const { setVisibleWorkloadModal, setWorkloadModalData } = workloadReducerActions
  const { selectedTime, mode } = useAppSelector((state) => state.workloadReducer)
  const [searchParams] = useSearchParams()
  const typeParam = searchParams.get("type");
  const isTimelineType = typeParam === "timeline";
  const [visibleTasks, setVisibleTasks] = useState(isTimelineType)

  // view type
  const viewType = useMemo(() => {
    return searchParams.get(VIEW_TYPE) ?? workloadViewTypes.WEEKLY
  }, [searchParams])

  // first day
  const firstDay = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.WEEK)
    } else {
      return dayjsParse(selectedTime, DATE_FORMAT).startOf(dayjsUnits.MONTH)
    }
  }, [selectedTime, viewType])

  // day index of week
  const dayIndexOfWeek = (index: number) => {
    return (+firstDay.add(index, dayjsUnits.DAY).format('d') || 7) - 1
  }

  // count of days
  const countOfDays = useMemo(() => {
    if (viewType === workloadViewTypes.WEEKLY) return 7
    else
      return dayjs(
        dayjsParse(selectedTime, DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
      ).daysInMonth()
  }, [viewType, selectedTime])

  // handle start
  const handleStart = (startDate: string, dueDate: string) => {
    const parsedDate = dayjsParse(startDate ?? dueDate, DATE_FORMAT)
    const distance = parsedDate.diff(firstDay, dayjsUnits.DAY)

    if (distance < 0) return 1
    return distance + 1
  }

  // handle end
  const handleEnd = (date: string) => {
    const parsedDate = dayjsParse(date, DATE_FORMAT)
    const distance = parsedDate.diff(firstDay, dayjsUnits.DAY)

    if (distance > countOfDays - 1) return countOfDays + 2
    return distance + 2
  }

  // handle daily workload
  const handleDailyWorkload = useCallback(
    (index: number) => {
      const day = firstDay.add(index, dayjsUnits.DAY)
      let workload = 0

      tasks?.forEach((task) => {
        const diffStart = dayjsParse(
          task.start_date ?? task.due_date,
          DATE_FORMAT
        ).diff(day, dayjsUnits.DAY)
        const diffEnd = dayjsParse(task.due_date, DATE_FORMAT).diff(
          day,
          dayjsUnits.DAY
        )
        const taskDays =
          dayjsParse(task.due_date, DATE_FORMAT).diff(
            dayjsParse(task.start_date ?? task.due_date, DATE_FORMAT),
            dayjsUnits.DAY
          ) + 1
        const isIncluded = diffStart <= 0 && diffEnd < taskDays && diffEnd >= 0

        if (isIncluded) {
          workload += task[mode] / 60 / 60 / taskDays
        }
      })

      return Math.round(workload * 100) / 100
    },
    [firstDay, tasks, mode]
  )

  // handle open workload modal
  const handleOpenWorkloadModal = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    e.stopPropagation()
    dispatch(setWorkloadModalData({ user_id, daily_workload, position }))
    dispatch(setVisibleWorkloadModal(true))
  }

  // handle open task modal
  // const handleOpenTaskModal = (task: WorkloadTaskType) => {
  //   dispatch(setTaskModalData({ url: task.url }))
  //   dispatch(setVisibleTaskModal(true))
  // }

  // tooltip
  const handleTime = (hour: number) => {
    const minute = Math.round(hour * 60)

    const h = Math.floor(minute / 60)
    const m = minute % 60

    let result = ''

    if (h) result += `${h} h`
    if (m) result += ` ${m} m`

    return result || '0 h'
  }

  // toggle visible tasks
  const toggleVisibleTasks = () => {
    setVisibleTasks((prev) => (isTimelineType ? true : !prev));
  };

  // workload background
  const handleWorkloadBackground = (overCapacity: number) => {
    if (overCapacity > 0 && overCapacity < 1) return styles.yellow
    if (overCapacity >= 1) return styles.red
  }

  // weekly workload
  const handleWeeklyWorkload = useCallback(() => {
    let weeklyWorkload = 0

    createArr(7).forEach((_, index) => {
      weeklyWorkload += handleDailyWorkload(index)
    })

    return weeklyWorkload
  }, [handleDailyWorkload])

  // weekly capacity
  const weeklyCapacity = useMemo(
    () => daily_workload.reduce((a, b) => +a + +b, 0),
    [daily_workload]
  )

  // weekly progress
  const weeklyProgress = useMemo(
    () => Math.round((handleWeeklyWorkload() * 100) / weeklyCapacity),
    [weeklyCapacity, handleWeeklyWorkload]
  )

  // aux click
  const handleAuxClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    url: string
  ) => {
    e.preventDefault()
    window.open(url?.replace('https', 'clickup'), '_self')
  }

  const userIdFromParam = searchParams.get("userId");

  if (userIdFromParam) {
    const userIds = userIdFromParam.split(',').map(id => parseInt(id.trim()));
    if (!userIds.includes(user_id)) {
      return null;
    }
  }

  return (
    <div className={styles.workload_item}>
      <div className={styles.workload_item_left} onClick={toggleVisibleTasks}>
        <div className={styles.top}>
          <img alt={first_name} src={image} />
          <div className={styles.info}>
            <p className={styles.name}>
              {first_name} {last_name}
            </p>
            <div className={styles.more}>
              <span>
                {handleTime(handleWeeklyWorkload())}/{handleTime(weeklyCapacity)}
              </span>
              {hasPermission(permissions.WORKLOAD_UPDATE) && (
                <EditIcon onClickFunc={(e) => handleOpenWorkloadModal(e)} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <Progress showInfo={false} percent={weeklyProgress} />
        </div>
        <div className={styles.extra_bottom}>
          <p>
            Unscheduled tasks <span>{unscheduled_tasks_count}</span>
          </p>
          <p className={overdue_tasks_count > 0 ? styles.red : ''}>
            Overdue tasks <span>{overdue_tasks_count}</span>
          </p>
        </div>
      </div>
      <div className={styles.workload_item_right}>
        <div className={`${styles.daily_workload} ${isTimelineType ? styles.hidden : ''}`}>
          {createArr(countOfDays).map((_, index) => {
            const workload = daily_workload[dayIndexOfWeek(index)]

            if (workload) {
              const dailyWorkload = handleDailyWorkload(index)
              const overCapacity = dailyWorkload - +workload

              return (
                <div className={styles.daily_workload_item} key={index}>
                  <Popover
                    overlayClassName={styles.workload_popover}
                    content={
                      <div className={styles.content}>
                        <p className={styles.content_top}>SCHEDULED</p>
                        <div className={styles.content_bottom}>
                          <Progress
                            className={`${
                              styles.content_bottom_left
                            } ${handleWorkloadBackground(overCapacity)}`}
                            percent={Math.round(dailyWorkload * 100) / +workload}
                            type="circle"
                            width={26}
                            showInfo={false}
                            strokeWidth={12}
                          />
                          <div className={styles.content_bottom_right}>
                            <span>This View</span>
                            <p>
                              <span
                                className={handleWorkloadBackground(overCapacity)}
                              >
                                {handleTime(dailyWorkload)}
                              </span>{' '}
                              / {handleTime(+workload)} capacity
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div
                      className={styles.background}
                      style={{
                        gridTemplateRows: `repeat(${+workload * 600}, 1fr)`,
                      }}
                    >
                      {dailyWorkload ? (
                        <div
                          className={`${styles.workload} ${handleWorkloadBackground(
                            overCapacity
                          )}`}
                          style={{
                            gridRowStart: 1,
                            gridRowEnd: Math.round(dailyWorkload * 600) + 1,
                          }}
                        >
                          {overCapacity >= 1 &&
                            viewType === workloadViewTypes.WEEKLY && (
                              <div className={styles.over_capacity}>
                                <span>{handleTime(overCapacity)}</span>
                                <p>OVER CAPACITY</p>
                              </div>
                            )}
                        </div>
                      ) : (
                        <span />
                      )}
                    </div>
                  </Popover>
                </div>
              )
            } else
              return <div className={styles.daily_workload_empty} key={index}></div>
          })}
        </div>
        <div
          className={`${styles.tasks} ${visibleTasks ? styles.active : ''}`}
          style={{
            gridTemplateColumns: `repeat(${countOfDays}, calc(100% / ${countOfDays}))`,
          }}
        >
          {!isEmptyArr(tasks) ? (
            tasks
              ?.sort((a, b) => {
                const aDiff = dayjsParse(a.due_date, DATE_FORMAT).diff(
                  dayjsParse(a.start_date ?? a.due_date, DATE_FORMAT),
                  dayjsUnits.DAY
                )
                const bDiff = dayjsParse(b.due_date, DATE_FORMAT).diff(
                  dayjsParse(b.start_date ?? b.due_date, DATE_FORMAT),
                  dayjsUnits.DAY
                )

                return bDiff - aDiff
              })
              .map((item) => (
                <Tooltip title={item.name} color="#292D32" key={item.task_id}>
                  <a
                    href={item.url}
                    onAuxClick={(e) => handleAuxClick(e, item.url)}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      gridColumnStart: handleStart(item?.start_date, item?.due_date),
                      gridColumnEnd: handleEnd(item?.due_date),
                      backgroundColor: `${item?.status?.color}70`,
                      borderLeft: `5px solid ${item?.status?.color}`,
                      position: "relative",
                      zIndex: 99,
                    }}
                    className={styles.item}
                  >
                    {item.priority && <PriorityIcon color={item?.priority?.color} />}
                    {item.parent && <SubtaskIcon />}
                    <p>{item.name}</p>
                    <span>{handleTime(item[mode] / 60 / 60)}</span>
                  </a>
                </Tooltip>
              ))
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkloadItem
