import React from 'react'

const SpaceIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.768 2.0703a6.241 6.241 0 011.0824 1.0602c.1616-.436.2735-.8184.3374-1.1243a3.6254 3.6254 0 00.0446-.2549c-.1192.0044-.2693.0194-.4499.0506-.2865.0495-.6286.1362-1.0145.2684zm1.7424-.3017a.0441.0441 0 01-.0085-.0026.0364.0364 0 01.0085.0026zm.2798 2.9975c.0528-.1135.1034-.2256.1518-.3362.3477-.7944.5912-1.5284.7141-2.117.0609-.2912.0981-.5742.0935-.8241-.0023-.1253-.0153-.2647-.053-.403-.0363-.1337-.1089-.3153-.2646-.471-.2767-.2768-.6653-.3386-.9094-.357-.2916-.022-.6308.003-.9956.066-.6807.1177-1.5253.3814-2.4585.8248A6.1944 6.1944 0 007 .7954C3.5733.7954.7954 3.5734.7954 7c0 .7253.1245 1.4215.3532 2.0684-.4434.9333-.7071 1.7779-.8248 2.4586-.063.3648-.088.704-.066.9956.0184.2441.0802.6327.357.9094.1557.1557.3373.2283.471.2647.1383.0376.2777.0506.403.0529.25.0046.5329-.0326.8241-.0935.5885-.1229 1.3226-.3664 2.117-.7141.1105-.0484.2227-.099.3362-.1518A6.1894 6.1894 0 007 13.2045c3.4267 0 6.2045-2.7778 6.2045-6.2045a6.1894 6.1894 0 00-.4143-2.234zm-1.6606-.0217C10.3308 3.2851 8.781 2.2954 7 2.2954 4.4017 2.2955 2.2955 4.4017 2.2955 7c0 1.781.9896 3.3308 2.449 4.1296 1.2368-.6419 2.58-1.5425 3.7113-2.6738s2.0319-2.4745 2.6738-3.7114zm-4.3675 6.9542c.947-.5994 1.9006-1.3284 2.7544-2.1821.8537-.8538 1.5827-1.8073 2.1821-2.7545.004.0789.0059.1582.0059.238 0 2.5982-2.1063 4.7045-4.7045 4.7045a4.6948 4.6948 0 01-.238-.0059zm-3.6316.1518a6.2406 6.2406 0 01-1.0602-1.0824c-.1322.3859-.2189.728-.2684 1.0145-.0312.1806-.0462.3307-.0506.4499a3.6252 3.6252 0 00.2549-.0446c.306-.0639.6883-.1758 1.1243-.3374zm-1.3619.6601a.0411.0411 0 01-.0026-.0086.0416.0416 0 01.0026.0086z"
      ></path>
    </svg>
  )
}

export default SpaceIcon
