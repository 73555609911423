import {RouteType} from "../../utils/model/routeModel";
import {strikesPaths} from "./strikesPaths";
import Index from "../../features/strikes/Index";

export const strikesRoutes: RouteType[] = [
    {
        path: strikesPaths.INDEX,
        element: Index,
        isExact: false,
    }
]