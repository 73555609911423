import React from 'react'

const GantIcon = () => {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 22.0132L2 2.01318C2 1.60318 2.34 1.26318 2.75 1.26318C3.16 1.26318 3.5 1.60318 3.5 2.01318L3.5 22.0132C3.5 22.4232 3.16 22.7632 2.75 22.7632C2.34 22.7632 2 22.4232 2 22.0132Z"
                fill="#8A92A6"
            />
            <path
                d="M20 9.76318L2 9.76318L2 14.2632L20 14.2632C21.1 14.2632 22 13.8132 22 12.4632L22 11.5632C22 10.2132 21.1 9.76318 20 9.76318Z"
                fill="#8A92A6"
            />
            <path
                opacity="0.4"
                d="M14 3.01318L2 3.01318L2 7.01318L14 7.01318C15.1 7.01318 16 6.61318 16 5.41318L16 4.61318C16 3.41318 15.1 3.01318 14 3.01318Z"
                fill="#8A92A6"
            />
            <path
                opacity="0.4"
                d="M9 17.0132L2 17.0132L2 21.0132L9 21.0132C10.1 21.0132 11 20.6132 11 19.4132L11 18.6132C11 17.4132 10.1 17.0132 9 17.0132Z"
                fill="#8A92A6"
            />
        </svg>

    )
}

export default GantIcon
