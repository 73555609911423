import React from 'react'

const ArrowLeftIcon: React.FC = () => {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62422 10.2071C5.2337 10.5976 4.60053 10.5976 4.21001 10.2071L0.351428 6.34853C-0.117203 5.8799 -0.117199 5.1201 0.351428 4.65147L4.21001 0.792894C4.60053 0.402369 5.2337 0.402369 5.62422 0.792893C6.01474 1.18342 6.01474 1.81658 5.62422 2.20711L2.33133 5.5L5.62422 8.79289C6.01475 9.18342 6.01475 9.81658 5.62422 10.2071Z"
        fill="#7C828D"
      />
    </svg>
  )
}

export default ArrowLeftIcon
