import {DashboardModel} from "../../features/dashboard/utils/models/dashboardModel";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {sliceNames} from "../../utils/constants/sliceNames";
import {BasicChartModel} from "../../features/dashboard/utils/models/chartsModel";


type InitialState = {
  dashboardModalData: {
    visible: boolean,
    data?: DashboardModel
  },
  chartModalData: {
    visible: boolean,
    data?: BasicChartModel
  }
}

const initialState: InitialState = {
  dashboardModalData: {
    visible: false,
  },
  chartModalData: {
    visible: false
  }
}

const dashboardReducer = createSlice({
  name: sliceNames.DASHBOARD,
  initialState,
  reducers: {
    setDashboardModalData: (state, action: PayloadAction<{ visible: boolean, data?: DashboardModel }>) => {
      state.dashboardModalData = action.payload
    },
    setChartModalData: (state, action: PayloadAction<{ visible: boolean, data?: BasicChartModel }>) => {
      state.chartModalData = action.payload
    },
  }
})

export default dashboardReducer.reducer
export const dashboardReducerActions = dashboardReducer.actions