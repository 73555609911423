import React, { useCallback, useEffect } from "react";
import { useClickUpConnect } from "../../queries/mutation";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

const ClickUpIntegration = () => {
  const clickUpConnectMutate = useClickUpConnect();
  const location = useLocation();
  const navigate = useNavigate();
  const locationCode = queryString.parse(location.search)?.code;

  const ClickUpRequest = useCallback(() => {
    if (locationCode) {
      clickUpConnectMutate
        .mutateAsync({
          code: String(locationCode),
        })
        .then(() => {
          navigate(location.pathname);
        })
        .catch(() => {
          navigate(location.pathname);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    ClickUpRequest();
  }, [ClickUpRequest]);

  return <></>;
};

export default ClickUpIntegration;
