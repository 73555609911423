import React, {useEffect, useMemo, useState} from "react";
import type {MenuProps} from "antd";
import {StrikeType} from "../../utils/models/strikesModel";
import dayjs from "dayjs";
import {Avatar, Button, Dropdown, Tooltip} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {workloadViewTypes} from "../../../workload/utils/enums/workloadViewTypes";
import {dayjsParse} from "../../../../utils/helpers/dayjsFormatter";
import {
    DATE_FORMAT,
    DEFAULT_DATE_FORMAT,
} from "../../../../utils/constants/dayjsFormats";
import {VIEW_TYPE} from "../../../../utils/constants/queryParamsKeys";
import {useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../../../hooks/redux";
import {useGetAllTasks, useGetPermission} from "../../services/queries";
import TrashIcon from "../../assets/svg/TrashIcon";
import {useDeleteStrike} from "../../services/mutations";

import styles from "./strike.module.scss"

const Strike: React.FC<StrikeType> = ({count, fullName, profilePic, id}) => {
    const [deleteEvent, setDeleteEvent] = useState<number | undefined>()
    const [showTaskList, setShowTaskList] = useState(false);
    const [searchParams] = useSearchParams();
    const {selectedTime, mode} = useAppSelector((state) => state.workloadReducer);
    const {data} = useGetAllTasks(selectedTime);
    const userEvent = data?.find((item) => item?.id === id)
    const deleteStrikeMutation = useDeleteStrike();
    const viewType = useMemo(() => {
        return searchParams.get(VIEW_TYPE) ?? workloadViewTypes.WEEKLY;
    }, [searchParams]);
    const {userData} = useAppSelector(state => state.userReducer)

    function handleDeleteEvent() {
        deleteStrikeMutation.mutate(deleteEvent)
    }

    function userPermission() {
        return userData?.user?.user_id === 3 || userData?.user?.user_id === 53 ? false : true
    }

    const countOfDays = useMemo(() => {
        if (viewType === workloadViewTypes.WEEKLY) return 7;
        else
            return dayjs(
                dayjsParse(selectedTime, DATE_FORMAT).format(DEFAULT_DATE_FORMAT)
            ).daysInMonth();
    }, [viewType, selectedTime]);

    const taskListTopData = Array.from({length: countOfDays}).map((_, i) =>
        dayjs().startOf("week").add(i, "day").format("YYYY-MM-DD")
    )

    useEffect(() => {
        let garr: number[][] = [];
        data?.forEach((task: any) => {
            const startDate = dayjs(task?.start_date || task?.dueDate);
            const dueDate = dayjs(task?.dueDate);
            const today = dayjs();
            const startWeek = dayjs(task.date).startOf("week");
            const gridColumnStart =
                Number(startDate.diff(startWeek, "day")) >= 0
                    ? Number(startDate.diff(startWeek, "day")) + 1
                    : 1;
            const gridColumnEnd =
                Number(dueDate.diff(startWeek, "day")) >= 0
                    ? Number(dueDate.diff(startWeek, "day")) + 1
                    : Number(today.diff(startWeek, "day")) + 1;

            const arr = Array.from({
                length: gridColumnEnd - gridColumnStart + 2,
            }).map((_, i) => i + gridColumnStart);
            garr.push(arr);
        });
    }, [data]);

    // dropdown items
    const items = (id: string | undefined) => {
        return [
            {
                label: (
                    <a
                        rel="noopener noreferrer"
                        href={`${"https://app.clickup.com/t"}/${id}`}
                    >
                        Ochish
                    </a>
                ),
                key: "4",
            },
            {
                label: (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${"https://app.clickup.com/t"}/${id}`}
                    >
                        Yangi oynada ochish
                    </a>
                ),
                key: "0",
            },
            {
                label: (
                    <a
                        rel="noopener noreferrer"
                        href={`${"clickup://app.clickup.com/t"}/${id}`}
                    >
                        ClickUpda ochish
                    </a>
                ),
                key: "1",
            },
            {
                label: (
                    <Button
                        className={styles.delete}
                        onClick={handleDeleteEvent}
                        disabled={userPermission()}
                    >
                        <TrashIcon/>
                        <p>O'chirish</p>
                    </Button>
                ),
                key: "3",
            },
        ] as MenuProps["items"];
    };

    return (
        <div className={styles.strike}>
            <div className={styles.user}>
                <div>
                    <div className={styles.user_item} onClick={() => setShowTaskList(!showTaskList)}>
                        {profilePic ? (
                            <img src={profilePic} alt="" className={styles.avatar}/>
                        ) : (
                            <Avatar size={36} icon={<UserOutlined/>}/>
                        )}
                        <div>
                            <p>{fullName}</p>
                            <span>Strikes: {count}</span>
                        </div>
                    </div>
                </div>

            </div>


            <div className={styles.tasklist}>
                <div className={styles.tasklist_top}>
                    {taskListTopData?.map((item, index) => (
                        <div key={item}>
              <span
                  style={
                      {
                          // background: taskDays.includes(index - 1)
                          //   ? ""
                          //   : taskList[index]?.type === "FINISH"
                          //   ? "#44CEA8"
                          //   : taskList[index]?.type === "UPDATE"
                          //   ? "#FFD62E"
                          //   : taskList[index]?.type === "DAILY"
                          //   ? "#F56B6D"
                          //   : taskList[index]?.type === "WEEKLY"
                          //   ? "#F56B6D"
                          //   : taskList[index]?.type === "MONTHLY"
                          //   ? "#F56B6D"
                          //   : "",
                      }
                  }
              ></span>
                        </div>
                    ))}
                </div>

                {showTaskList && userEvent &&
                    <div className={styles.tasklist_bottom}>
                        {userEvent?.events?.map((task) => {
                            const startDate = dayjs(task?.startDate || task?.dueDate);
                            const dueDate = dayjs(task?.dueDate);
                            const today = dayjs();
                            const startWeek = dayjs(task?.date).startOf("week");
                            const gridColumnStart =
                                Number(startDate.diff(startWeek, "day")) >= 0
                                    ? Number(startDate.diff(startWeek, "day")) + 1
                                    : 1;
                            const gridColumnEnd =
                                Number(dueDate.diff(startWeek, "day")) >= 0
                                    ? Number(dueDate.diff(startWeek, "day")) + 2
                                    : Number(today.diff(startWeek, "day")) + 2;

                            const type = task?.type;

                            return (
                                <Dropdown
                                    menu={{items: items(task?.task?.id)}}
                                    trigger={["click"]}
                                    className={styles.dropdown}
                                >
                                    <div
                                        onClick={() => setDeleteEvent(task?.id)}
                                        className={styles.task}
                                        key={task?.id}
                                        style={{
                                            gridColumnStart,
                                            gridColumnEnd,
                                        }}
                                    >
                                        <div className={styles.task_fd}>
                        <span
                            className={styles.circle}
                            style={{
                                background:
                                    type === "FINISH"
                                        ? "#2FC25B"
                                        : type === "OVERDUE"
                                            ? "#D10000"
                                            : type === "UPDATE_DUE_DATE"
                                                ? "#FFFF00"
                                                : type === "UPDATE_DUE_DATE_ADMIN"
                                                    ? "#FFA500"
                                                    : "#FFFFFF",
                            }}
                        ></span>
                                            <p className={styles.task_name}>
                                                <Tooltip title={task?.task?.name}>
                                                    {task?.task?.name?.length > 20
                                                        ? task?.task?.name.slice(0, 20) + "..."
                                                        : task?.task?.name}
                                                </Tooltip>
                                            </p>
                                        </div>
                                    </div>
                                </Dropdown>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
    );
};

export default Strike;
