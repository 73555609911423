import React from 'react'

const ListIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.75 1.5A.75.75 0 011.5.75h11a.75.75 0 010 1.5h-11a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h11a.75.75 0 010 1.5h-11a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h11a.75.75 0 010 1.5h-11a.75.75 0 01-.75-.75zm0 3a.75.75 0 01.75-.75h7a.75.75 0 010 1.5h-7a.75.75 0 01-.75-.75z"
      ></path>
    </svg>
  )
}

export default ListIcon
