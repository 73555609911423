import { Empty, Spin } from 'antd'
import React, { ReactNode } from 'react'

import { isEmptyArr } from '../../../utils/helpers/isEmptyArr'

import styles from './BeforeData.module.scss'

type props = {
  isLoading: boolean
  data: any[] | undefined
  children: ReactNode
  noDataText?: string
}

const BeforeData: React.FC<props> = ({ data, isLoading, children, noDataText }) => {
  if (isLoading) {
    return <Spin spinning className={styles.spin} />
  } else {
    if (isEmptyArr(data)) {
      return (
        <Empty
          className={styles.empty}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={noDataText ?? 'No Data'}
        />
      )
    } else return <>{children}</>
  }
}

export default BeforeData
