import React from 'react'

import Timeline from '../../common/timeline/Timeline'
import { useViewTimeOffWeekly } from '../../../queries/queries'
import { timeOffViewTypes } from '../../../utils/constants/timeOffViewTypes'

const TimeOffWeekly: React.FC = () => {
  const { data, isLoading } = useViewTimeOffWeekly()

  return (
    <Timeline data={data} isLoading={isLoading} type={timeOffViewTypes.WEEKLY} />
  )
}

export default TimeOffWeekly
