import type {MenuProps} from "antd";
import {Layout, Menu} from "antd";
import React, {ReactNode, useMemo, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import Logo from "../../assets/svg/Logo";
import {rootPaths} from "../../routing/root/rootPaths";
import {permissions} from "../../utils/constants/permissions";
import TimeOffIcon from "../../assets/icons/sidebar/TimeOffIcon";
import {hasPermission} from "../../utils/helpers/hasPermission";
import EmployeeIcon from "../../assets/icons/sidebar/EmployeeIcon";
import ArrowLeftIcon from "../../assets/icons/sidebar/ArrowLeftIcon";
import {LocalStorage} from "../../services/LocalStorage";
import {urlFirstSegmentHandler} from "../../utils/helpers/urlSegmentHandler";
import WorkloadIcon from "../../assets/icons/sidebar/WorkloadIcon";
import {SIDEBAR} from "../../utils/constants/localStorageKeys";

import styles from './MainLayout.module.scss'
import HomeIcon from '../../assets/icons/HomeIcon'
import ClickUpIcon from "../../assets/icons/ClickUpIcon";
import GantIcon from "../../assets/icons/sidebar/GantIcon";
import DashboardIcon from "../../assets/icons/sidebar/DashboardIcon";
// import DotIcon from "../../assets/icons/sidebar/DotIcon";

type MenuItem = Required<MenuProps>['items'][number];

// function getItem(
//   label: React.ReactNode,
//   key: React.Key,
//   icon?: React.ReactNode,
//   onClick?: () => void,
// ): MenuItem {
//   return {
//     label,
//     key,
//     icon,
//     onClick,
//   } as MenuItem;
// }

type sidebarLink = {
  icon: ReactNode;
  label: string;
  onClick?: () => void;
  key: string;
  neededpermission: permissions;
  children?: MenuItem[]
};

const {Sider, Content} = Layout;

const MainLayot: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(
    JSON.parse(LocalStorage.get(SIDEBAR) || String(false))
  );

  // sidebar links
  const sidebarLinks: sidebarLink[] = [
    {
      icon: (
        <span>
          <HomeIcon/>
        </span>
      ),
      label: 'Home',
      key: rootPaths.HOME,
      onClick: () => handleNavigate(rootPaths.HOME),
      neededpermission: permissions.WORKLOAD_VIEW,
    },
    {
      icon: (
        <span>
          <TimeOffIcon/>
        </span>
      ),
      label: "Time Off",
      key: rootPaths.TIME_OFF,
      onClick: () => handleNavigate(rootPaths.TIME_OFF),
      neededpermission: permissions.CALENDAR_VIEW,
    },
    {
      icon: (
        <span>
          <EmployeeIcon/>
        </span>
      ),
      label: "Employees",
      key: rootPaths.EMPLOYEES,
      onClick: () => handleNavigate(rootPaths.EMPLOYEES),
      neededpermission: permissions.USER_VIEW_PAGINATION,
    },
    {
      icon: (
        <span>
          <WorkloadIcon/>
        </span>
      ),
      label: "Workload",
      key: rootPaths.WORKLOAD,
      onClick: () => handleNavigate(rootPaths.WORKLOAD),
      neededpermission: permissions.WORKLOAD_VIEW,
    },
    {
      icon: (
        <span>
          <GantIcon/>
        </span>
      ),
      label: "Gantt",
      key: rootPaths.GANTT,
      onClick: () => handleNavigate(rootPaths.GANTT),
      neededpermission: permissions.WORKLOAD_VIEW,
    },
    {
      icon: (
        <span>
          <EmployeeIcon/>
        </span>
      ),
      label: "Resourses",
      key: rootPaths.WEBSITE,
      onClick: () => handleNavigate(rootPaths.WEBSITE),
      neededpermission: permissions.WORKLOAD_VIEW,
      // children: [
      //   getItem('Domain', '1', <DotIcon />,),
      //   getItem('Servers', '2', <DotIcon />, )
      // ]
    },
    {
      icon: (
          <span>
            <TimeOffIcon/>
          </span>
      ),
      label: "Strikes",
      key: rootPaths.STRIKES,
      onClick: () => handleNavigate(rootPaths.STRIKES),
      neededpermission: permissions.WORKLOAD_VIEW
    },
    {
      icon: (
        <span>
            <DashboardIcon/>
          </span>
      ),
      label: 'Dashboard',
      key: rootPaths.DASHBOARD,
      onClick: () => handleNavigate(rootPaths.DASHBOARD),
      neededpermission: permissions.DASHBOARD_VIEW,
    },
  ];

  // toggle collapse
  const toggleCollapse = () => {
    setCollapsed((prev: boolean) => {
      LocalStorage.set(SIDEBAR, JSON.stringify(!prev));
      return !prev;
    });
  };

  // navigate to pages
  const handleNavigate = (path: string) => {
    navigate(path);
  };

  // is simple
  const isSimple = useMemo(
    () =>
      location.pathname === rootPaths.WORKLOAD ||
      location.pathname === rootPaths.GANTT ||
      location.pathname === rootPaths.DASHBOARD,
    [location.pathname]
  );

  return (
    <Layout className={styles.main_layout}>
      <Sider
        collapsible
        trigger={null}
        collapsed={collapsed}
        className={styles.main_layout_sidebar}
      >
        <div className={styles.main_layout_sidebar_header}>
          <Logo
            className={`${collapsed ? "" : styles.active} ${styles.logo}`}
          />
          <p className={`${collapsed ? "" : styles.active}`}>ERP</p>
          <div
            className={`${collapsed ? styles.collapsed : ""} ${styles.trigger}`}
            onClick={toggleCollapse}
          >
            <div className={styles.btn}>
              <ArrowLeftIcon/>
            </div>
          </div>
        </div>
        <div className={styles.main_layout_sidebar_body}>
          <Menu
            mode="inline"
            items={sidebarLinks.map((link) =>
              hasPermission(link.neededpermission) ? link : null
            )}
            className={styles.menu}
            defaultSelectedKeys={[urlFirstSegmentHandler(location.pathname)]}
          />
        </div>
        <div className={styles.main_layout_sidebar_footer}>
          <Menu
            className={styles.menu}
            mode="inline"
            items={[
              {
                icon: (
                  <span>
                    <ClickUpIcon/>
                  </span>
                ),
                label: "Connect to clickup",
                key: "clickup",
                onClick: () =>
                  window.location.replace(
                    "https://app.clickup.com/api?client_id=Y72I0ETRL22G3ATR8CHIUQKTD2GZIAK7&redirect_uri=erp.softex.uz"
                  ),
                className: styles.main_layout_sidebar_footer_clickUpButton,
              },
            ]}
          />
        </div>
      </Sider>
      <Layout
        className={`${styles.main_layout_content} ${
          isSimple ? styles.simple : ""
        }`}
      >
        {/* <Header className={styles.main_layout_content_header}></Header> */}
        <Content className={styles.main_layout_content_body}>
          <Outlet/>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayot;
