import React from 'react'

const ArrowDown = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00016 3.33325V12.6666M8.00016 12.6666L12.6668 7.99992M8.00016 12.6666L3.3335 7.99992"
                stroke="#667085"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    )
}

export default ArrowDown
