import { Modal } from 'antd'
import React from 'react'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { workloadReducerActions } from '../../../../store/reducers/workloadReducer'

import styles from './taskModal.module.scss'

const TaskModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const { setVisibleTaskModal } = workloadReducerActions
  const { visibleTaskModal } = useAppSelector((state) => state.workloadReducer)

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleTaskModal(false))
  }

  return (
    <Modal
      centered
      title={null}
      footer={null}
      closable={false}
      onCancel={handleCancel}
      className={`${styles.task_modal} common_modal`}
      visible={visibleTaskModal}
    >
      hello
    </Modal>
  )
}

export default TaskModal
