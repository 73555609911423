import { useQuery } from "@tanstack/react-query"
import $api from "../../../services/RequestService"
import { errorHandler } from "../../../utils/helpers/errorHandler"
import { ErrorRes } from "../../../utils/model/responseType"
import { endpoints } from "../utils/constants/endpoints"
import { queryKeys } from "../utils/constants/queryKeys"
import { HomeView } from "../utils/models/HomeView"

export function useHomeView() {
    return useQuery<HomeView,ErrorRes>(
      [queryKeys.HOME_VIEW],
      async () => {
        const res = await $api.get(`${endpoints.HOME}`)
        return res.data
      },
      {
        refetchOnWindowFocus: false,
        retry: false,
        onError: errorHandler,
      }
    )
  }