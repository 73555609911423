import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EmployeesLayout from '../../layouts/employeesLayout/EmployeesLayout'
import { exactRouteFixer } from '../../utils/helpers/exactRouteFixer'
import { employeesRoutes } from './employeesRoutes'

const EmployeesRouter: React.FC = () => {
  return (
    <EmployeesLayout>
      <Routes>
        {employeesRoutes.map((item) => (
          <Route
            key={item.path}
            path={exactRouteFixer(item.path, item.isExact)}
            element={<item.element />}
          />
        ))}
      </Routes>
    </EmployeesLayout>
  )
}

export default EmployeesRouter
