import ReactInputMask from 'react-input-mask'

export const InputMask = (mask: string) => (
  <ReactInputMask
    mask={mask}
    // @ts-ignore
    maskChar=""
    className="ant-input"
  />
)
