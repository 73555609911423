import { useHomeView } from '../../services/queries'
import Calendar from '../full-calendar/FullCalendar'
import TimeOffRequests from '../time-off-requests/TimeOffRequests'
import styles from './statistic.module.scss'

const Statistic = () => {
    const {data} = useHomeView()

    return(
    <div className={styles.container}>
        <Calendar />
        <TimeOffRequests data={data?.today_time_off.filter((item) => item.status !== 'rejected')} />
    </div>
    )
}

export default Statistic