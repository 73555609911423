import Home from "../../features/home";
import Website from "../../features/website";
import Dashboard from '../../features/dashboard/Index';
import NotFound from "../../pages/notFound/NotFound";
import {permissions} from "../../utils/constants/permissions";
import {RouteType} from "../../utils/model/routeModel";
import EmployeesRouter from "../employees/EmployeesRouter";
import GanttRouter from "../gantt/GanttRouter";
import TimeOffRouter from "../timeOff/TimeOffRouter";
import WorkLoadRouter from "../workload/WorkLoadRouter";
import {rootPaths} from "./rootPaths";
import Strike from "../strikes/StrikesRouter";

const {CALENDAR_VIEW, USER_VIEW_PAGINATION, WORKLOAD_VIEW} = permissions;

export const rootRoutes: RouteType[] = [
  {
    path: rootPaths.TIME_OFF,
    element: TimeOffRouter,
    isExact: false,
    neededPermissions: CALENDAR_VIEW,
  },
  {
    path: rootPaths.EMPLOYEES,
    element: EmployeesRouter,
    isExact: false,
    neededPermissions: USER_VIEW_PAGINATION,
  },
  {
    path: rootPaths.WORKLOAD,
    element: WorkLoadRouter,
    isExact: false,
    neededPermissions: WORKLOAD_VIEW,
  },
  {
    path: rootPaths.GANTT,
    element: GanttRouter,
    isExact: false,
    neededPermissions: WORKLOAD_VIEW,
  },
  {
    path: rootPaths.HOME,
    element: Home,
    isExact: false,
    neededPermissions: WORKLOAD_VIEW,
  },
  {
    path: rootPaths.HOME_INDEX,
    element: Home,
    isExact: false,
    neededPermissions: WORKLOAD_VIEW,
  },
  {
    path: rootPaths.WEBSITE,
    element: Website,
    isExact: false,
    neededPermissions: WORKLOAD_VIEW,
  },
  {
    path: rootPaths.DASHBOARD,
    element: Dashboard,
    isExact: true
  },
  {
    path: "*",
    element: NotFound,
    isExact: true,
  },
  {
    path: rootPaths.STRIKES,
    element: Strike,
    isExact: true
  }
];
