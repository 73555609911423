import dayjs from 'dayjs'
import { Popover } from 'antd'
import weekday from 'dayjs/plugin/weekday'
import React, { CSSProperties } from 'react'

import {
  TimeOffCalendarRes,
  TimeOffOneCalendarType,
} from '../../../utils/model/timeOffModel'
import {
  DATE_FORMAT,
  D_M_H_M,
  TIME_FORMAT,
} from '../../../utils/constants/dayjsFormats'
import BeforeData from '../beforeData/BeforeData'
import { useAppSelector } from '../../../hooks/redux'
import { createArr } from '../../../utils/helpers/createArr'
import { dayjsUnits } from '../../../utils/constants/dayjsUnits'
import { seperators } from '../../../utils/constants/seperators'
import { dayjsParse } from '../../../utils/helpers/dayjsFormatter'
import { truncateString } from '../../../utils/helpers/truncateString'
import { layoutElements } from '../../../utils/constants/layoutElements'
import { timeOffViewTypes } from '../../../utils/constants/timeOffViewTypes'

import styles from './Timeline.module.scss'

dayjs.extend(weekday)

type props = {
  data: TimeOffCalendarRes[] | undefined
  isLoading: boolean
  type: string
}

const Timeline: React.FC<props> = ({ data, isLoading, type }) => {
  const { selectedTime } = useAppSelector((state) => state.timeOffReducer)

  // handle time
  const handleTime = (time: string, day: string) => {
    const arr = time.split(seperators.COLON)

    let hour = (+arr[0] - 8) * 6
    const minute = +arr[1] / 10

    // for weekly
    if (type === timeOffViewTypes.WEEKLY) {
      const firstDay = dayjsParse(selectedTime, DATE_FORMAT).weekday(0)
      const distance = dayjs(day, DATE_FORMAT).diff(dayjs(firstDay), dayjsUnits.DAY)

      hour += distance * 60
    }

    // for monthly
    if (type === timeOffViewTypes.MONTHLY) {
      const firstDay = dayjsParse(selectedTime, DATE_FORMAT).date(1)
      const distance = dayjs(day, DATE_FORMAT).diff(dayjs(firstDay), dayjsUnits.DAY)

      hour += distance * 60
    }

    return hour + minute + 1
  }

  // min column for visibile info
  const minColumnForVisibleInfo = () => {
    switch (type) {
      case timeOffViewTypes.DAILY:
        return 6
      case timeOffViewTypes.WEEKLY:
        return 60
      case timeOffViewTypes.MONTHLY:
        return 120
    }
  }

  // min column to truncate text
  const minColumnToTruncateText = () => {
    switch (type) {
      case timeOffViewTypes.DAILY:
        return 10
      case timeOffViewTypes.WEEKLY:
        return 100
      case timeOffViewTypes.MONTHLY:
        return 200
    }
  }

  // visible time-line info
  const visibleTimeLineInfo = (startTime: number, endTime: number) => {
    if (endTime - startTime >= minColumnForVisibleInfo()!) {
      return true
    }
    return false
  }

  // truncate reason name
  const truncateReasonName = (startTime: number, endTime: number, name: string) => {
    if (endTime - startTime < minColumnToTruncateText()!) {
      return truncateString(name, 10)
    }
    return name
  }

  // days of selected month
  const daysInMonth = dayjsParse(selectedTime, DATE_FORMAT).daysInMonth()

  // header
  const handleHeader = () => {
    switch (type) {
      case timeOffViewTypes.DAILY:
        return createArr(10).map((_, index) => (
          <span key={index}>{`${index + 8}:00`}</span>
        ))
      case timeOffViewTypes.WEEKLY:
        return createArr(7).map((_, index) => (
          <span key={index}>
            {dayjsParse(selectedTime, DATE_FORMAT)
              .weekday(index)
              .format(DATE_FORMAT)}
          </span>
        ))
      case timeOffViewTypes.MONTHLY:
        return createArr(daysInMonth).map((_, index) => (
          <span key={index}>{index + 1}</span>
        ))
    }
  }

  // extra  style for monthly
  const extraStyleMonthly: (layoutType: string) => CSSProperties | {} = (
    layoutType
  ) => {
    if (type === timeOffViewTypes.MONTHLY) {
      let count = daysInMonth

      // for body
      if (layoutType === layoutElements.BODY) {
        count *= 60
      }

      return {
        gridTemplateColumns: `repeat(${count}, 1fr)`,
      }
    }
    return {}
  }

  // popover content
  const popoverContent = (calendar: TimeOffOneCalendarType) => {
    if (
      !visibleTimeLineInfo(
        handleTime(calendar.start_time, calendar.from_when),
        handleTime(calendar.end_time, calendar.until_when)
      ) &&
      type !== timeOffViewTypes.MONTHLY
    ) {
      return (
        <>
          <span
            className={styles.time}
            style={{ backgroundColor: calendar.reason_color }}
          >
            {calendar.start_time} - {calendar.end_time}
          </span>
          <p className={styles.reason}>{calendar.reason_name}</p>
        </>
      )
    }

    // for monthly
    if (type === timeOffViewTypes.MONTHLY) {
      const formatter = (date: string, time: string) => {
        return dayjsParse(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`).format(
          D_M_H_M
        )
      }

      const startTime = formatter(calendar.from_when, calendar.start_time)
      const endTime = formatter(calendar.until_when, calendar.end_time)

      return (
        <>
          <span
            className={styles.time}
            style={{ backgroundColor: calendar.reason_color }}
          >
            {startTime} - {endTime}
          </span>
          <p className={styles.reason}>{calendar.reason_name}</p>
        </>
      )
    }
  }

  return (
    <div className={styles.time_line}>
      <BeforeData isLoading={isLoading} data={data}>
        <div className={styles.header}>
          <div className={styles.left}>Xodimlar</div>
          <div
            className={`${styles.right} ${type}`}
            style={extraStyleMonthly(layoutElements.HEADER)}
          >
            {handleHeader()}
          </div>
        </div>
        <div className={styles.body}>
          {data?.map((user) => (
            <div className={styles.grid_cont} key={user.user_id}>
              <div className={styles.left}>
                <img src={user.image} alt={user.first_name} />
                <div className={styles.info}>
                  <p>{`${user.first_name} ${user.last_name}`}</p>
                  <span>{user.profession}</span>
                </div>
              </div>
              <div
                className={`${styles.right} ${type}`}
                style={extraStyleMonthly(layoutElements.BODY)}
              >
                {user.calendars?.map((item) => (
                  <Popover
                    key={item.calendar_id}
                    overlayClassName={styles.time_line_popover}
                    content={popoverContent(item)}
                  >
                    <div
                      style={{
                        gridColumnStart: handleTime(item.start_time, item.from_when),
                        gridColumnEnd: handleTime(item.end_time, item.until_when),
                      }}
                      className={styles.time_line}
                    >
                      <div
                        className={styles.content}
                        style={{
                          borderColor: item.reason_color,
                        }}
                      >
                        {visibleTimeLineInfo(
                          handleTime(item.start_time, item.from_when),
                          handleTime(item.end_time, item.until_when)
                        ) && (
                          <>
                            <span
                              className={styles.time}
                              style={{ backgroundColor: item.reason_color }}
                            >
                              {item.start_time} - {item.end_time}
                            </span>
                            <p className={styles.reason}>
                              {truncateReasonName(
                                handleTime(item.start_time, item.from_when),
                                handleTime(item.end_time, item.until_when),
                                item.reason_name
                              )}
                            </p>
                          </>
                        )}
                      </div>
                      <div
                        className={styles.bg}
                        style={{
                          backgroundColor: item.reason_color,
                        }}
                      />
                    </div>
                  </Popover>
                ))}
              </div>
            </div>
          ))}
        </div>
      </BeforeData>
    </div>
  )
}

export default Timeline
