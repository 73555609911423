import React from 'react';
import {MoreOutlined} from "@ant-design/icons";
import {Popconfirm, Popover} from "antd";

import {useDeleteChart} from "../../../services/mutations";
import {BasicChartModel} from "../../../utils/models/chartsModel";
import {useAppDispatch} from "../../../../../hooks/redux";
import {dashboardReducerActions} from "../../../../../store/reducers/dashboardReducer";

import styles from './chartLayout.module.scss'

type Props = {
  children: React.ReactNode
  data: BasicChartModel
}

const ChartLayout: React.FC<Props> = ({children, data}) => {
  const dispatch = useAppDispatch()
  const {setChartModalData: setModalData} = dashboardReducerActions
  const deleteChart = useDeleteChart()

  // delete
  const handleDelete = () => {
    deleteChart.mutate({id: data.id})
  }

  // update
  const handleUpdate = () => {
    dispatch(setModalData({
      visible: true,
      data: data
    }))
  }

  return (
    <div className={styles.chart}>
      <div className={styles.chart_header}>
        <p>{data?.name}</p>
        <Popover
          content={
            <div className={styles.popover_content}>
              <span onClick={handleUpdate}>Update</span>
              <Popconfirm
                onConfirm={handleDelete}
                title='Are you sure to delete this dashboard?'
                okButtonProps={{
                  loading: deleteChart.isLoading
                }}
                placement='right'
                trigger={['click', 'hover']}
                mouseEnterDelay={10}
                mouseLeaveDelay={0.2}
              >
                <span>Delete</span>
              </Popconfirm>
            </div>
          }
          overlayClassName={styles.popover}
          placement='right'
          trigger={['click', 'hover']}
          mouseEnterDelay={10}
          mouseLeaveDelay={0.2}
        >
          <MoreOutlined className={styles.more}/>
        </Popover>
      </div>
      <div className={styles.chart_body}>
        {children}
      </div>
    </div>
  );
};

export default ChartLayout;