import React from "react";
import { Route, Routes } from "react-router-dom";
import TimeOffLayout from "../../layouts/timeOffLayout/TimeOffLayout";
import { exactRouteFixer } from "../../utils/helpers/exactRouteFixer";
import { timeOffRoutes } from "./timeOffRoutes";

const TimeOffRouter: React.FC = () => {
  return (
    <TimeOffLayout>
      <Routes>
        {timeOffRoutes.map((item) => (
          <Route
            key={item.path}
            path={exactRouteFixer(item.path, item.isExact)}
            element={<item.element />}
          />
        ))}
      </Routes>
    </TimeOffLayout>
  );
};

export default TimeOffRouter;
