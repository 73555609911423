import React from 'react'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { useWebsiteView } from '../../services/queries'
import styles from './styles.module.scss'
import { useGetCron } from '../../services/mutation'
import { Button } from 'antd'

interface IProps {
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

const Navbar: React.FC<IProps> = ({ setOpenModal }) => {
  const showModal = () => {
    setOpenModal(true)
  }
  const { getParams } = useQueryParams()
  const page = getParams()?.page || String(1)

  const { data } = useWebsiteView(page)
  const updateCron = useGetCron()

  const updateCronData = () => {
    updateCron.mutate()
  }
  

  return (
    <div className={styles.navbar}>
      <div className={styles.navbar_left}>
        <h3>Websites</h3>
        <div className={styles.navbar_left_domain}>
          <h4>{data?.total} domains</h4>
        </div>
      </div>
      <div className={styles.navbar_btns}>
      <button onClick={showModal}>
        add new
      </button>
      <Button loading={updateCron?.isLoading} className={styles.navbar_btn} onClick={updateCronData}>Refresh</Button>
      </div>
    </div>
  )
}

export default Navbar
