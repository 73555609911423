import React from 'react'
import CollapseList from './components/collapse-list/CollapseList'
import Navbar from './components/navbar/Navbar'
import Statistic from './components/statistic/Statistic'
import styles from './home.module.scss'

const Home = () => {

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container_top}>
          <Navbar />
          <CollapseList />
        </div>
        <Statistic />
      </div>
    </>
  )
}

export default Home