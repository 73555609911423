import { parse, stringify } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import { QueryParamsTypes } from '../utils/model/queryParamsType'

type returnType = {
  append: (key: string, value: string) => void
  getParams: () => QueryParamsTypes
}

export const useQueryParams: () => returnType = () => {
  const location = useLocation()
  const navigate = useNavigate()

  // get params
  const getParams = () => {
    return parse(location.search)
  }

  // append
  const append = (key: string, value: string) => {
    const query = parse(location.search)

    query[key] = value
    navigate({ search: stringify(query) })
  }

  return {
    append,
    getParams,
  }
}
