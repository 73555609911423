import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine
} from "recharts";

import {BurndownChartModel} from "../../../utils/models/chartsModel";
import ChartLayout from "../chart-layout/ChartLayout";


const BurndownChart: React.FC<BurndownChartModel> = (props) => {
  const {statistics} = props

  return (
    <ChartLayout data={props}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={statistics}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="date"/>
          <YAxis dataKey="value"/>
          <Tooltip/>
          <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{r: 8}}/>
          <ReferenceLine
            stroke="green"
            strokeDasharray="3 3"
            segment={[
              {x: statistics[0]?.date, y: statistics[0]?.value},
              {x: statistics[statistics?.length - 1]?.date, y: 0}
            ]}/>
        </LineChart>
      </ResponsiveContainer>
    </ChartLayout>
  );
};

export default BurndownChart;