import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from '../hooks/redux'
import $api from '../services/RequestService'
import {
  employeeEndpoints,
  reasonEndpoints,
  timeOffEndpoints,
} from '../utils/constants/apis'
import { permissions } from '../utils/constants/permissions'
import { queryKeys } from '../utils/constants/queryKeys'
import { PAGE, TIME } from '../utils/constants/queryParamsKeys'
import { errorHandler } from '../utils/helpers/errorHandler'
import { hasPermission } from '../utils/helpers/hasPermission'
import { EmployeeRes } from '../utils/model/employeesModel'
import { Pagination } from '../utils/model/pagination'
import { ReasonsRes } from '../utils/model/reasonModel'
import { ErrorRes } from '../utils/model/responseType'
import { TimeOffCalendarRes, TimeOffListRes } from '../utils/model/timeOffModel'

// get time-off list
export function useViewTimeOffList(page: string) {
  return useQuery<Pagination<TimeOffListRes>, ErrorRes>(
    [queryKeys.TIME_OFF_LIST, page],
    async () => {
      const res = await $api.get(`${timeOffEndpoints.VIEW_LIST}?${PAGE}=${page}`)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get time-off daily
export function useViewTimeOffDaily() {
  const { selectedTime } = useAppSelector((state) => state.timeOffReducer)

  return useQuery<TimeOffCalendarRes[], ErrorRes>(
    [queryKeys.TIME_OFF_DAILY, selectedTime],
    async () => {
      const res = await $api.get(
        `${timeOffEndpoints.VIEW_DAILY}?${TIME}=${selectedTime}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: hasPermission(permissions.CALENDAR_VIEW),
    }
  )
}

// get time-off weekly
export function useViewTimeOffWeekly() {
  const { selectedTime } = useAppSelector((state) => state.timeOffReducer)

  return useQuery<TimeOffCalendarRes[], ErrorRes>(
    [queryKeys.TIME_OFF_WEEKLY, selectedTime],
    async () => {
      const res = await $api.get(
        `${timeOffEndpoints.VIEW_WEEKLY}?${TIME}=${selectedTime}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: hasPermission(permissions.CALENDAR_VIEW),
    }
  )
}

// get time-off monthly
export function useViewTimeOffMonthly() {
  const { selectedTime } = useAppSelector((state) => state.timeOffReducer)

  return useQuery<TimeOffCalendarRes[], ErrorRes>(
    [queryKeys.TIME_OFF_MONTHLY, selectedTime],
    async () => {
      const res = await $api.get(
        `${timeOffEndpoints.VIEW_MONTHLY}?${TIME}=${selectedTime}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: hasPermission(permissions.CALENDAR_VIEW),
    }
  )
}

// get reasons
export function useViewReasons() {
  return useQuery<ReasonsRes[], ErrorRes>(
    [queryKeys.REASONS],
    async () => {
      const res = await $api.get(reasonEndpoints.VIEW)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}

// get employees
export function useViewEmployeesSelect() {
  return useQuery<EmployeeRes[], ErrorRes>(
    [queryKeys.EMPLOYEES_SELECT],
    async () => {
      const res = await $api.get(employeeEndpoints.VIEW)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: hasPermission(permissions.USER_VIEW),
    }
  )
}

// get employess pagination
export function useViewEmployees(page: string) {
  return useQuery<Pagination<EmployeeRes>, ErrorRes>(
    [queryKeys.EMPLOYEES, page],
    async () => {
      const res = await $api.get(
        `${employeeEndpoints.VIEW_PAGINATION}?${PAGE}=${page}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
      enabled: hasPermission(permissions.USER_VIEW_PAGINATION),
    }
  )
}
