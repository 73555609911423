import EmployeesTable from '../../components/employees/employeesTable/EmployeesTable'
import { RouteType } from '../../utils/model/routeModel'
import { employeePaths } from './employeePaths'

export const employeesRoutes: RouteType[] = [
  {
    path: employeePaths.INDEX,
    element: EmployeesTable,
    isExact: true,
  },
]
