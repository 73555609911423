import React from 'react'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import EditIcon from '../../../assets/icons/EditIcon'
import { useAppDispatch } from '../../../hooks/redux'
import { useViewTimeOffList } from '../../../queries/queries'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { PAGE } from '../../../utils/constants/queryParamsKeys'
import { TimeOffListRes } from '../../../utils/model/timeOffModel'
import { permissions } from '../../../utils/constants/permissions'
import { hasPermission } from '../../../utils/helpers/hasPermission'
import PaginationNextIcon from '../../../assets/icons/PaginationNextIcon'
import PaginationPrevIcon from '../../../assets/icons/PaginationPrevIcon'
import { timeOffReducerActions } from '../../../store/reducers/timeOffReducer'
import { tablePaginationPositions } from '../../../utils/constants/tablePaginationPositions'

import styles from './TimeOffList.module.scss'

const TimeOffList: React.FC = () => {
  const dispatch = useAppDispatch()
  const { append, getParams } = useQueryParams()
  const page = getParams()?.page || String(1)
  const { data, isLoading } = useViewTimeOffList(page)
  const { setSelectedTimeOff, setVisibleTimeOffModal } = timeOffReducerActions

  // handle page and size
  const handlePagination = (page: number) => {
    append(PAGE, String(page))
  }

  // handle update
  const handleUpdate = (timeOff: TimeOffListRes) => {
    dispatch(setSelectedTimeOff(timeOff))
    dispatch(setVisibleTimeOffModal(true))
  }

  // table columns
  const columns: ColumnsType<TimeOffListRes> | undefined = [
    {
      title: '#',
      render: (_, __, index: number) => index + 1,
      width: 80,
    },
    {
      title: 'Employee',
      render: (record: TimeOffListRes) => {
        return <p className="m_0">{`${record.first_name} ${record.last_name}`}</p>
      },
    },
    {
      title: 'Type of vacation',
      render: (record: TimeOffListRes) => record.reason_name,
      align: 'center',
    },
    {
      title: 'Start time',
      render: (record: TimeOffListRes) => record.from_when,
      align: 'center',
    },
    {
      title: 'End time',
      render: (record: TimeOffListRes) => record.until_when,
      align: 'center',
    },
    {
      title: 'Created time',
      render: (record: TimeOffListRes) => record.created_time,
      align: 'center',
    },
    {
      title: 'Description',
      render: (record: TimeOffListRes) => record.description,
      ellipsis: true,
    },
    {
      title: 'Status',
      render: (record: TimeOffListRes) => (
        <span className={`${styles.status_tag} ${styles[record.status]}`}>
          {record.status}
        </span>
      ),
      align: 'center',
      width: 140,
    },
    ...[
      hasPermission(permissions.CALENDAR_UPDATE)
        ? {
            title: '',
            render: (record: TimeOffListRes) => (
              <Button
                className={styles.edit_btn}
                type="primary"
                onClick={() => handleUpdate(record)}
              >
                <EditIcon />
                <span>Edit</span>
              </Button>
            ),
            width: 120,
          }
        : // sorry for that! i have tried my best but i could not do better with this column
          {
            className: 'd_n',
          },
    ],
  ]

  return (
    <div className={styles.time_off_list}>
      <Table
        columns={columns}
        pagination={{
          onChange: handlePagination,
          current: data?.current_page,
          total: data?.total,
          position: [tablePaginationPositions.BOTTOM_CENTER],
          prevIcon: <PaginationPrevIcon />,
          nextIcon: <PaginationNextIcon />,
          hideOnSinglePage: true,
        }}
        loading={isLoading}
        dataSource={data?.data}
        rowKey={(record: TimeOffListRes) => record.calendar_id}
      />
    </div>
  )
}

export default TimeOffList
