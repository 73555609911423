import React, {useEffect, useMemo} from 'react';
import {Form, Input, Modal, Select} from "antd";

import {useCreateChart, useUpdateChart} from "../../../services/mutations";
import {chartTypes} from "../../../utils/enums/chartTypes";
import {chartViewTypes} from "../../../utils/enums/chartViewTypes";
import {useQueryParams} from "../../../../../hooks/useQueryParams";
import {ChartFormFieldsModel} from "../../../utils/models/chartFormFieldsModel";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {dashboardReducerActions} from "../../../../../store/reducers/dashboardReducer";

const {Item} = Form
const {Option} = Select

const ChartModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const {chartModalData: modalData} = useAppSelector(state => state.dashboardReducer)
  const {setChartModalData: setModalData} = dashboardReducerActions
  const [formInstance] = Form.useForm()
  const createChart = useCreateChart()
  const updateChart = useUpdateChart()
  const {getParams} = useQueryParams()

  // initial form fields
  useEffect(() => {
    if (modalData?.data) {
      formInstance.setFieldsValue({...modalData?.data})
    }
  }, [modalData?.data, formInstance])

  // is updating
  const isUpdating = useMemo(() => !!modalData?.data, [modalData?.data])

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({visible: false}))
  }

  // finish
  const onFinish = (fields: ChartFormFieldsModel) => {
    const req = {...fields, dashboard_id: Number(getParams()?.id)}

    if (isUpdating) {
      updateChart.mutateAsync({...req, id: modalData?.data?.id!}).then(handleCancel)
    } else {
      createChart.mutateAsync(req).then(handleCancel)
    }
  }

  // submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields()
  }

  return (
    <Modal
      open={modalData.visible}
      onCancel={handleCancel}
      centered
      title='Add dashboard'
      onOk={handleSubmit}
      afterClose={handleAfterClose}
      okButtonProps={{
        loading: createChart.isLoading || updateChart.isLoading
      }}
    >
      <Form form={formInstance} onFinish={onFinish} layout='vertical'>
        <Item name='name' label='Name' rules={[{required: true, message: ''}]}>
          <Input/>
        </Item>
        <Item name='folder_id' label='Folder id'>
          <Input/>
        </Item>
        <Item name='list_id' label='List id'>
          <Input/>
        </Item>
        <Item name='type' label='Type' rules={[{required: true, message: ''}]}>
          <Select>
            {Object.values(chartTypes).map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </Select>
        </Item>
        <Item name='view_type' label='View type' rules={[{required: true, message: ''}]}>
          <Select>
            {Object.values(chartViewTypes).map(item => (
              <Option key={item} value={item}>{item}</Option>
            ))}
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

export default ChartModal;