import React from 'react'

const PickerArrowRight: React.FC = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8125 13H19.1875"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.125 7.9375L19.1875 13L14.125 18.0625"
        stroke="#BDBDBD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="-0.5"
        y="0.5"
        width="25"
        height="25"
        rx="5.5"
        transform="matrix(-1 0 0 1 25 0)"
        stroke="#BDBDBD"
      />
    </svg>
  )
}

export default PickerArrowRight
