import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { messageTypes } from "../../../utils/constants/messageTypes";
import { errorHandler } from "../../../utils/helpers/errorHandler";
import { showMessage } from "../../../utils/helpers/showMessage";
import { ErrorRes, SuccessRes } from "../../../utils/model/responseType";
import { endpoints } from "../utils/constants/endpoints";
import { queryKeys } from "../utils/constants/queryKeys";

// clickup connect
export function useTaskUpdate(listId: string) {
  const qc = useQueryClient();
  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      task_id: string;
      start_date?: string;
      due_date?: string;
      name?: string;
    }
  >(
    async (req) => {
      const res = await $api.put(endpoints.TASKS_UPDATE, req);
      return res.data;
    },
      {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS);
        qc.invalidateQueries([queryKeys.TASKS, listId]);
      },
      onError: (err) => {
        errorHandler(err);
        qc.invalidateQueries([queryKeys.TASKS, listId]);
      },
    }
  );
}

export function useTaskCreate(listId: string) {
  const qc = useQueryClient();
  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      task_id: string;
      start_date?: string; 
      due_date?: string;
      name?: string;
    }
  >(
    async (req) => {
      const res = await $api.post(endpoints.TASKS_CREATE, req);
      return res.data;
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS);
        qc.invalidateQueries([queryKeys.TASKS, listId]);
      },
      onError: (err) => {
        errorHandler(err);
        qc.invalidateQueries([queryKeys.TASKS, listId]);
      },
    }
  );
}
