import React from 'react'
import { CaretRightOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import List from '../list/List'
import styles from './collapse.module.scss'
import { useHomeView } from '../../services/queries'

const { Panel } = Collapse

const CollapseList = () => {
  const { data } = useHomeView()

  return (
    <>
      <List data={data?.today} />
      <Collapse
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className={styles.container}
      >
        <Panel header={'Overdue (' + data?.overdue_tasks.length + ')'} key="1">
          <List data={data?.overdue_tasks} />
        </Panel>
        <Panel header={'Next (' + data?.next.length + ')'} key="2">
          <List data={data?.next} />
        </Panel>
        <Panel
          header={'Unsechudel (' + data?.unscheduled_tasks.length + ')'}
          key="3"
        >
          <List data={data?.unscheduled_tasks} />
        </Panel>
      </Collapse>
    </>
  )
}

export default CollapseList
