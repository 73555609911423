import React from 'react';

function DashboardIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 10.9132V4.11318C21.5 2.61318 20.86 2.01318 19.27 2.01318H15.23C13.64 2.01318 13 2.61318 13 4.11318V10.9132C13 12.4132 13.64 13.0132 15.23 13.0132H19.27C20.86 13.0132 21.5 12.4132 21.5 10.9132Z"
        fill="#8A92A6"/>
      <path
        d="M11 13.1132V19.9132C11 21.4132 10.36 22.0132 8.77 22.0132H4.73C3.14 22.0132 2.5 21.4132 2.5 19.9132V13.1132C2.5 11.6132 3.14 11.0132 4.73 11.0132H8.77C10.36 11.0132 11 11.6132 11 13.1132Z"
        fill="#8A92A6"/>
      <path opacity="0.4"
            d="M21.5 19.9132V17.1132C21.5 15.6132 20.86 15.0132 19.27 15.0132H15.23C13.64 15.0132 13 15.6132 13 17.1132V19.9132C13 21.4132 13.64 22.0132 15.23 22.0132H19.27C20.86 22.0132 21.5 21.4132 21.5 19.9132Z"
            fill="#8A92A6"/>
      <path opacity="0.4"
            d="M11 6.91318V4.11318C11 2.61318 10.36 2.01318 8.77 2.01318H4.73C3.14 2.01318 2.5 2.61318 2.5 4.11318V6.91318C2.5 8.41318 3.14 9.01318 4.73 9.01318H8.77C10.36 9.01318 11 8.41318 11 6.91318Z"
            fill="#8A92A6"/>
    </svg>


  );
}

export default DashboardIcon;