import { Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useMemo } from 'react'
import {useSearchParams} from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import DatePicker from '../../../../services/DatePicker'
import { workloadReducerActions } from '../../../../store/reducers/workloadReducer'
import { datePickerPickers } from '../../../../utils/constants/datePickerPickers'
import {
  DATE_FORMAT,
  YEAR_MONTH_FORMAT,
  YEAR_WEEK_FORMAT,
} from '../../../../utils/constants/dayjsFormats'
import { dayjsUnits } from '../../../../utils/constants/dayjsUnits'
import { VIEW_TYPE } from '../../../../utils/constants/queryParamsKeys'
import { dayjsParse, dayjsStringify } from '../../../../utils/helpers/dayjsFormatter'
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon'
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon'
import SelectSuffixIcon from '../../assets/icons/SelectSuffixIcon'
import { workloadModes } from '../../utils/enums/workloadModes'
import { workloadViewTypes } from '../../utils/enums/workloadViewTypes'

import styles from '../../Index.module.scss'

const { Option } = Select
const { MONTHLY, WEEKLY } = workloadViewTypes

const Header: React.FC = () => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const { setSelectedTime, setMode } = workloadReducerActions
  const { selectedTime, mode } = useAppSelector((state) => state.workloadReducer)

  // datePicker formats
  const datePickerFormats = {
    [WEEKLY]: YEAR_WEEK_FORMAT,
    [MONTHLY]: YEAR_MONTH_FORMAT,
  }

  // pickers
  const pickers = {
    [WEEKLY]: datePickerPickers.WEEK,
    [MONTHLY]: datePickerPickers.MONTH,
  }

  // units
  const units = {
    [WEEKLY]: dayjsUnits.WEEK,
    [MONTHLY]: dayjsUnits.MONTH,
  }

  // view type
  const viewType = useMemo(() => {
    return searchParams.get(VIEW_TYPE) ?? WEEKLY
  }, [searchParams])

  // change view type
  const handleChangeViewType = (type: string) => {
    setSearchParams({ [VIEW_TYPE]: type })
    dispatch(setSelectedTime(dayjs().format(DATE_FORMAT)))
  }

  // change mode
  const handleChangeMode = (mode: workloadModes) => {
    dispatch(setMode(mode))
  }

  // parsed selected time
  const parsedSelectedTime = useMemo(
    () => dayjsParse(selectedTime, DATE_FORMAT),
    [selectedTime]
  )

  // handle time
  const handleTime = (value: Dayjs | null) => {
    const newValue = dayjsStringify(value ?? dayjs(), DATE_FORMAT)

    dispatch(setSelectedTime(newValue))
  }

  // handle prev time
  const handlePrevTime = () => {
    const newTime = dayjs(parsedSelectedTime).add(
      -1,
      units[viewType as keyof typeof units]
    )

    dispatch(setSelectedTime(dayjsStringify(newTime, DATE_FORMAT)))
  }

  // handle next time
  const handleNextTime = () => {
    const newTime = dayjs(parsedSelectedTime).add(
      1,
      units[viewType as keyof typeof units]
    )

    dispatch(setSelectedTime(dayjsStringify(newTime, DATE_FORMAT)))
  }

  return (
    <div className={styles.workloads_header}>
      <div className={styles.left}></div>
      <div className={styles.right}>
        <DatePicker
          className={styles.picker}
          placement="bottomRight"
          format={datePickerFormats[viewType as keyof typeof datePickerFormats]}
          bordered={false}
          picker={pickers[viewType as keyof typeof pickers]}
          onChange={handleTime}
          value={parsedSelectedTime}
        />
        <div className={styles.arrows}>
          <span className={styles.arrow} onClick={handlePrevTime}>
            <ArrowLeftIcon />
          </span>
          <span className={styles.arrow} onClick={handleNextTime}>
            <ArrowRightIcon />
          </span>
        </div>
        <Select
          bordered={false}
          className={styles.select}
          suffixIcon={<SelectSuffixIcon />}
          onChange={handleChangeViewType}
          value={viewType}
        >
          <Option value={WEEKLY}>{WEEKLY}</Option>
          <Option value={MONTHLY}>{MONTHLY}</Option>
        </Select>
        <Select
          bordered={false}
          className={styles.select}
          suffixIcon={<SelectSuffixIcon />}
          onChange={handleChangeMode}
          value={mode}
        >
          <Option value={workloadModes.PLAN}>Time Estimate</Option>
          <Option value={workloadModes.FACT}>Time Tracked</Option>
        </Select>
      </div>
    </div>
  )
}

export default Header