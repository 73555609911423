import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { exactRouteFixer } from '../../utils/helpers/exactRouteFixer'
import { ganttRoutes } from './ganttRoutes'

const GanttRouter: React.FC = () => {
  return (
    <Routes>
      {ganttRoutes.map((item) => (
        <Route
          key={item.path}
          path={exactRouteFixer(item.path, item.isExact)}
          element={<item.element />}
        />
      ))}
    </Routes>
  )
}

export default GanttRouter
