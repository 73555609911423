import React from 'react'
import { Button, Form, Input } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'

import Logo from '../../assets/svg/Logo'
import { useLogin } from '../../queries/mutation'
import { useAppDispatch } from '../../hooks/redux'
import { LoginReq } from '../../utils/model/loginModel'
import { rootPaths } from '../../routing/root/rootPaths'
import { formLayouts } from '../../utils/constants/formLayouts'
import { userReducerActions } from '../../store/reducers/userReducer'

import styles from './Login.module.scss'

const { Item } = Form

const Login: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const { setIsAuth } = userReducerActions
  const loginMutation = useLogin(afterSucces)
  const locationState: any = useLocation().state
  const from = locationState?.from?.pathname || rootPaths.TIME_OFF

  // after success login
  function afterSucces() {
    dispatch(setIsAuth(true))
    navigate(from, { replace: true })
  }

  // on finish
  const onFinish = (fields: LoginReq) => {
    loginMutation.mutate(fields)
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.cont}>
          <div className={styles.cont_header}>
            <Logo />
            <p>ERP</p>
          </div>
          <div className={styles.cont_body}>
            <h1 className={styles.cont_body_title}>Kirish</h1>
            <Form
              layout={formLayouts.VERTICAL}
              className={styles.cont_body_form}
              autoComplete="off"
              form={formInstance}
              onFinish={onFinish}
            >
              <Item
                name="login"
                label="Login"
                rules={[{ required: true, message: '' }]}
              >
                <Input />
              </Item>
              <Item
                name="password"
                label="Parol"
                rules={[{ required: true, message: '' }]}
              >
                <Input.Password />
              </Item>
              <div className={styles.submit_btn}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loginMutation.isLoading}
                >
                  Kirish
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className={styles.right} />
    </div>
  )
}

export default Login
