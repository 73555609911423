import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AUTHORIZATION} from '../../utils/constants/localStorageKeys'
import {sliceNames} from '../../utils/constants/sliceNames'
import {LoginRes} from "../../utils/model/loginModel";

type InitialState = {
    isAuth: boolean,
    userData: LoginRes | null
}

const initialState: InitialState = {
    isAuth: !!localStorage.getItem(AUTHORIZATION),
    userData: null
}

const userReducer = createSlice({
    name: sliceNames.USER,
    initialState,
    reducers: {
        setIsAuth: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload
        },
        setUserData: (state, action: PayloadAction<LoginRes>) => {
            state.userData = action.payload
        }
    },
})

export default userReducer.reducer
export const userReducerActions = userReducer.actions
