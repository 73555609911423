import {combineReducers, configureStore} from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import timeOffReducer from "./reducers/timeOffReducer";
import employeesReducer from "./reducers/employeesReducer";
import workloadReducer from "./reducers/workloadReducer";
import gantListSlice from "./reducers/gantListSlice";
import dashboardReducer from './reducers/dashboardReducer'

const rootReducer = combineReducers({
  userReducer,
  timeOffReducer,
  employeesReducer,
  workloadReducer,
  gantListSlice,
  dashboardReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
