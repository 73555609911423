import {useMutation, useQueryClient} from "@tanstack/react-query";
import {LocalStorage} from "../services/LocalStorage";
import $api from "../services/RequestService";
import {
    authEndpoints,
    employeeEndpoints,
    reasonEndpoints,
    timeOffEndpoints,
    workloadEndpoints,
} from "../utils/constants/apis";
import {
    AUTHORIZATION,
    PERMISSIONS,
    USER,
} from "../utils/constants/localStorageKeys";
import {messageTypes} from "../utils/constants/messageTypes";
import {permissions} from "../utils/constants/permissions";
import {queryKeys} from "../utils/constants/queryKeys";
import {
    CALENDAR_ID,
    REASON_ID,
    STATUS,
} from "../utils/constants/queryParamsKeys";
import {errorHandler} from "../utils/helpers/errorHandler";
import {hasPermission} from "../utils/helpers/hasPermission";
import {showMessage} from "../utils/helpers/showMessage";
import {LoginReq, LoginRes} from "../utils/model/loginModel";
import {ReasonsReq} from "../utils/model/reasonModel";
import {ErrorRes, SuccessRes} from "../utils/model/responseType";
import {TimeOffReq} from "../utils/model/timeOffModel";
import {useAppDispatch} from "../hooks/redux";
import {userReducerActions} from "../store/reducers/userReducer";

// sign in
export function useLogin(afterSucces: () => void) {
    const dispatch = useAppDispatch()
    const {setUserData} = userReducerActions
    return useMutation<SuccessRes<LoginRes>, ErrorRes, LoginReq>(
        async (req) => {
            const res = await $api.post(authEndpoints.INDEX, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                afterSucces();
                dispatch(setUserData(res))
                LocalStorage.set(AUTHORIZATION, res.Authorization);
                LocalStorage.set(USER, res.user);
                LocalStorage.set(PERMISSIONS, res.permissions);
            },
            onError: errorHandler,
        }
    );
}

// create time-off
export function useCreateTimeOff(afterSucces: () => void) {
    const qc = useQueryClient();
    const changeStatus = useChangeStatusTimeOff();

    return useMutation<SuccessRes<{ calendar_id: number }>, ErrorRes, TimeOffReq>(
        async (req) => {
            const create = await $api.post(timeOffEndpoints.CREATE, req);
            return create.data;
        },
        {
            onSuccess: async (res, req) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                hasPermission(permissions.CALENDAR_STATUS_CHANGE) &&
                (await changeStatus.mutateAsync({
                    calendar_id: res.calendar_id,
                    status: req.status,
                }));
                afterSucces();
                qc.invalidateQueries([queryKeys.TIME_OFF_LIST]);
            },
            onError: errorHandler,
        }
    );
}

// update time-off
export function useUpdateTimeOff(afterSucces: () => void) {
    const qc = useQueryClient();
    const changeStatus = useChangeStatusTimeOff();

    return useMutation<
        SuccessRes,
        ErrorRes,
        TimeOffReq & { calendar_id: number }
    >(
        async (req) => {
            const res = await $api.put(timeOffEndpoints.UPDATE, req);
            return res.data;
        },
        {
            onSuccess: async (res, req) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                hasPermission(permissions.CALENDAR_STATUS_CHANGE) &&
                (await changeStatus.mutateAsync({
                    calendar_id: req.calendar_id,
                    status: req.status,
                }));
                afterSucces();
                qc.invalidateQueries([queryKeys.TIME_OFF_LIST]);
            },
            onError: errorHandler,
        }
    );
}

// change status time-off
export function useChangeStatusTimeOff() {
    return useMutation<
        SuccessRes,
        ErrorRes,
        { calendar_id: number; status: string }
    >(
        async (req) => {
            const res = await $api.patch(
                `${timeOffEndpoints.STATUS_CHANGE}?${CALENDAR_ID}=${req.calendar_id}&${STATUS}=${req.status}`
            );
            return res.data;
        },
        {
            onError: errorHandler,
        }
    );
}

// create time-off reason
export function useCreateTimeOffReason(afterSuccess: () => void) {
    const qc = useQueryClient();

    return useMutation<SuccessRes, ErrorRes, ReasonsReq>(
        async (req) => {
            const res = await $api.post(reasonEndpoints.CREATE, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                qc.invalidateQueries([queryKeys.REASONS]);
                afterSuccess();
            },
            onError: errorHandler,
        }
    );
}

// update time-off reason
export function useUpdateTimeOffReason(afterSuccess: () => void) {
    const qc = useQueryClient();

    return useMutation<SuccessRes, ErrorRes, ReasonsReq>(
        async (req) => {
            const res = await $api.put(reasonEndpoints.UPDATE, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                qc.invalidateQueries([queryKeys.REASONS]);
                afterSuccess();
            },
            onError: errorHandler,
        }
    );
}

// change status time-off reason
export function useChangeStatusTimeOffReason(afterSucces: () => void) {
    const qc = useQueryClient();

    return useMutation<SuccessRes, ErrorRes, number>(
        async (reason_id) => {
            const res = await $api.patch(
                `${reasonEndpoints.STATUS_CHANGE}?${REASON_ID}=${reason_id}`
            );
            return res.data;
        },
        {
            onSuccess: (res) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                afterSucces();
                qc.invalidateQueries([queryKeys.REASONS]);
            },
            onError: errorHandler,
        }
    );
}

// create update employee
export function useCreateUpdateEmployee(
    afterSucces: () => void,
    isUpdating: boolean
) {
    const qc = useQueryClient();
    let url: string;

    if (isUpdating) {
        url = employeeEndpoints.UPDATE;
    } else {
        url = employeeEndpoints.CREATE;
    }

    return useMutation<SuccessRes, ErrorRes, FormData>(
        async (req) => {
            const res = await $api.post(url, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
                afterSucces();
                qc.invalidateQueries([queryKeys.EMPLOYEES]);
            },
            onError: errorHandler,
        }
    );
}

// clickup connect
export function useClickUpConnect() {
    return useMutation<SuccessRes, ErrorRes, { code: string }>(
        async (req) => {
            const res = await $api.post(workloadEndpoints.CLICKUP_CONNECT, req);
            return res.data;
        },
        {
            onSuccess: (res) => {
                showMessage(res?.messages, messageTypes.SUCCESS);
            },
            onError: errorHandler,
        }
    );
}
