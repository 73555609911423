import React from 'react'
import { Button, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { useAppDispatch } from '../../../hooks/redux'
import EditIcon from '../../../assets/icons/EditIcon'
import { useViewEmployees } from '../../../queries/queries'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { PAGE } from '../../../utils/constants/queryParamsKeys'
import { EmployeeRes } from '../../../utils/model/employeesModel'
import PaginationNextIcon from '../../../assets/icons/PaginationNextIcon'
import PaginationPrevIcon from '../../../assets/icons/PaginationPrevIcon'
import { employeesReducerActions } from '../../../store/reducers/employeesReducer'
import { tablePaginationPositions } from '../../../utils/constants/tablePaginationPositions'

import styles from './EmployeesTable.module.scss'

const EmployeesTable: React.FC = () => {
  const dispatch = useAppDispatch()
  const { append, getParams } = useQueryParams()
  const page = getParams()?.page || String(1)
  const { data, isLoading } = useViewEmployees(page)
  const { setSelectedEmployee, setVisibleEmployeesModal } = employeesReducerActions

  // handle update
  const handleUpdate = (employee: EmployeeRes) => {
    dispatch(setSelectedEmployee(employee))
    dispatch(setVisibleEmployeesModal(true))
  }

  // table columns
  const columns: ColumnsType<EmployeeRes> | undefined = [
    {
      title: '#',
      render: (_, __, index: number) => index + 1,
      width: 60,
    },
    {
      title: 'Image',
      render: (record: EmployeeRes) => (
        <img
          className={styles.employee_img}
          src={record.image}
          alt={record.first_name}
        />
      ),
      align: 'center',
      width: 80,
    },
    {
      title: 'Name',
      render: (record: EmployeeRes) => {
        return <p className="m_0">{`${record.first_name} ${record.last_name}`}</p>
      },
      width: 500
    },
    {
      title: 'Vacation',
      render: (record: EmployeeRes) => {
        return <p>{record.vacation}</p>
      }
    },
    {
      title: 'Profession',
      render: (record: EmployeeRes) => record.profession,
    },
    {
      title: '',
      render: (record: EmployeeRes) => (
        <Button
          className={styles.edit_btn}
          type="primary"
          onClick={() => handleUpdate(record)}
        >
          <EditIcon />
          <span>Edit</span>
        </Button>
      ),
      align: 'center',
      width: 100,
    },
  ]

  // handle page and size
  const handlePagination = (page: number) => {
    append(PAGE, String(page))
  }

  return (
    <div className={styles.employees_table}>
      <Table
        columns={columns}
        pagination={{
          onChange: handlePagination,
          current: data?.current_page,
          total: data?.total,
          position: [tablePaginationPositions.BOTTOM_CENTER],
          prevIcon: <PaginationPrevIcon />,
          nextIcon: <PaginationNextIcon />,
          hideOnSinglePage: true,
        }}
        loading={isLoading}
        dataSource={data?.data}
        rowKey={(record: EmployeeRes) => record.user_id}
      />
    </div>
  )
}

export default EmployeesTable
