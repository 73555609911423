import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

import {
  TaskModalDataType,
  WorkloadModalDataType,
} from '../../features/workload/utils/models/workloadModel'
import { DATE_FORMAT } from '../../utils/constants/dayjsFormats'
import { sliceNames } from '../../utils/constants/sliceNames'
import { dayjsStringify } from '../../utils/helpers/dayjsFormatter'
import { workloadModes } from '../../features/workload/utils/enums/workloadModes'

const initialState: {
  workloadModalData: WorkloadModalDataType
  taskModalData: TaskModalDataType
  visibleWorkloadModal: boolean
  visibleTaskModal: boolean
  selectedTime: string
  mode: workloadModes
} = {
  workloadModalData: { user_id: null, daily_workload: [], position: null },
  taskModalData: { url: '' },
  visibleWorkloadModal: false,
  visibleTaskModal: false,
  selectedTime: dayjsStringify(dayjs(), DATE_FORMAT),
  mode: workloadModes.PLAN,
}

const workloadReducer = createSlice({
  name: sliceNames.WORKLOAD,
  initialState,
  reducers: {
    setWorkloadModalData: (state, action: PayloadAction<WorkloadModalDataType>) => {
      state.workloadModalData = action.payload
    },
    setTaskModalData: (state, action: PayloadAction<TaskModalDataType>) => {
      state.taskModalData = action.payload
    },
    setVisibleWorkloadModal: (state, action: PayloadAction<boolean>) => {
      state.visibleWorkloadModal = action.payload
    },
    setVisibleTaskModal: (state, action: PayloadAction<boolean>) => {
      state.visibleTaskModal = action.payload
    },
    setSelectedTime: (state, action: PayloadAction<string>) => {
      state.selectedTime = action.payload
    },
    setMode: (state, action: PayloadAction<workloadModes>) => {
      state.mode = action.payload
    },
  },
})

export default workloadReducer.reducer
export const workloadReducerActions = workloadReducer.actions
