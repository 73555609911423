import { useQuery } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { errorHandler } from "../../../utils/helpers/errorHandler";
import { ErrorRes } from "../../../utils/model/responseType";
import { endpoints } from "../utils/constants/endpoints";
import { queryKeys } from "../utils/constants/queryKeys";
import {
  FOLDER_ID,
  LIST_ID,
  SPACE_ID,
} from "../utils/constants/queryParamsKeys";
import { FolderModel } from "../utils/models/folderModel";
import { ListModel } from "../utils/models/listModel";
import { SpaceModel } from "../utils/models/spaceModel";
import { TaskModel } from "../utils/models/taskModel";

// get spaces
export function useViewSpaces() {
  return useQuery<SpaceModel[], ErrorRes>(
    [queryKeys.SPACES],
    async () => {
      const res = await $api.get(endpoints.SPACES);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  );
}

// get folders
export function useViewFolders(spaceId?: string) {
  return useQuery<FolderModel[], ErrorRes>(
    [queryKeys.FOLDERS, spaceId],
    async () => {
      const res = await $api.get(`${endpoints.FOLDERS}?${SPACE_ID}=${spaceId}`);
      return res.data;
    },
    {
      enabled: !!spaceId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  );
}

// get lists
export function useViewLists(folderId?: string) {
  return useQuery<ListModel[], ErrorRes>(
    [queryKeys.LISTS, folderId],
    async () => {
      const res = await $api.get(`${endpoints.LISTS}?${FOLDER_ID}=${folderId}`);
      return res.data;
    },
    {
      enabled: !!folderId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  );
}

// get tasks
export function useViewTasks(listId?: string) {
  return useQuery<TaskModel[], ErrorRes>(
    [queryKeys.TASKS, listId],
    async () => {
      const res = await $api.get(`${endpoints.TASKS}?${LIST_ID}=${listId}`);
      return res.data;
    },
    {
      enabled: !!listId,
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  );
}
