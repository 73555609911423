export const rootPaths = {
  INDEX: "/",
  TIME_OFF: "/time-off",
  EMPLOYEES: "/employees",
  WORKLOAD: "/workload",
  GANTT: "/gantt",
  HOME: "/home",
  HOME_INDEX: "index.html",
  WEBSITE: '/website',
  DASHBOARD: '/dashboard',
  STRIKES: '/strikes'
};
