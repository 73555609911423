import React from 'react'

const EditIcon = (props: React.SVGProps<SVGSVGElement>) => {
    return (
        <svg
        {...props}
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g
                clip-path="url(#clip0_1408_32254)">
                <path
                    d="M24.1665 12.4999C24.3854 12.2811 24.6452 12.1074 24.9312 11.989C25.2171 11.8705 25.5236 11.8096 25.8332 11.8096C26.1427 11.8096 26.4492 11.8705 26.7352 11.989C27.0211 12.1074 27.281 12.2811 27.4998 12.4999C27.7187 12.7188 27.8923 12.9786 28.0108 13.2646C28.1292 13.5506 28.1902 13.8571 28.1902 14.1666C28.1902 14.4761 28.1292 14.7826 28.0108 15.0686C27.8923 15.3546 27.7187 15.6144 27.4998 15.8333L16.2498 27.0833L11.6665 28.3333L12.9165 23.7499L24.1665 12.4999Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1408_32254">
                    <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(10 10)"
                    />
                </clipPath>
            </defs>
        </svg>

    )
}

export default EditIcon
