import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  TimePicker,
} from 'antd'

import DatePicker from '../../../services/DatePicker'
import ModalCloseIcon from '../../../assets/icons/ModalCloseIcon'
import { permissions } from '../../../utils/constants/permissions'
import { defaultTimes } from '../../../utils/constants/defaultTimes'
import { hasPermission } from '../../../utils/helpers/hasPermission'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { timeOffStatuses } from '../../../utils/constants/timeOffStatuses'
import { useCreateTimeOff, useUpdateTimeOff } from '../../../queries/mutation'
import { timeOffReducerActions } from '../../../store/reducers/timeOffReducer'
import { timeOffDisabledTime } from '../../../utils/helpers/timeOffDisabledTime'
import { DATE_FORMAT, TIME_FORMAT } from '../../../utils/constants/dayjsFormats'
import { useViewReasons, useViewEmployeesSelect } from '../../../queries/queries'
import { dayjsParse, dayjsStringify } from '../../../utils/helpers/dayjsFormatter'

import styles from './TimeOffModal.module.scss'

type formFields = {
  user_id: number
  from_when: Dayjs
  until_when: Dayjs
  start_time: Dayjs
  end_time: Dayjs
  reason_id: number
  description: string
  status: string
}

const { Item } = Form
const { Option } = Select
const { TextArea } = Input

const TimeOffModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const { setSelectedTimeOff } = timeOffReducerActions
  const { setVisibleTimeOffModal } = timeOffReducerActions
  const { visibleTimeOffModal, selectedTimeOff } = useAppSelector(
    (state) => state.timeOffReducer
  )
  const [timeOffStatus, setTimeOffStatus] = useState(
    selectedTimeOff?.status || timeOffStatuses.EXPECTED
  )
  const { data: employees, isLoading: usersIsLoading } = useViewEmployeesSelect()
  const { data: reasons, isLoading: reasonsIsLoading } = useViewReasons()
  const createTimeOffMutation = useCreateTimeOff(afterSuccess)
  const updateTimeOffMutation = useUpdateTimeOff(afterSuccess)

  // initial form fields
  useEffect(() => {
    if (selectedTimeOff) {
      formInstance.setFieldsValue({
        ...selectedTimeOff,
        from_when: dayjsParse(selectedTimeOff.from_when, DATE_FORMAT),
        until_when: dayjsParse(selectedTimeOff.until_when, DATE_FORMAT),
        start_time: dayjsParse(selectedTimeOff.start_time, TIME_FORMAT),
        end_time: dayjsParse(selectedTimeOff.end_time, TIME_FORMAT),
      })
      setTimeOffStatus(selectedTimeOff.status)
    }
  }, [formInstance, selectedTimeOff])

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleTimeOffModal(false))
  }

  // after success
  function afterSuccess() {
    handleCancel()
  }

  // handle submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // handle after close
  const handleAfterClose = () => {
    formInstance.resetFields()
    dispatch(setSelectedTimeOff(null))
    setTimeOffStatus(timeOffStatuses.EXPECTED)
  }

  // on finish
  const onFinish = (fields: formFields) => {
    const req = {
      ...fields,
      from_when: dayjsStringify(fields.from_when, DATE_FORMAT),
      until_when: dayjsStringify(fields.until_when, DATE_FORMAT),
      start_time: dayjsStringify(fields.start_time, TIME_FORMAT),
      end_time: dayjsStringify(fields.end_time, TIME_FORMAT),
      status: timeOffStatus,
    }

    if (selectedTimeOff) {
      updateTimeOffMutation.mutate({
        ...req,
        calendar_id: selectedTimeOff.calendar_id,
      })
    } else {
      createTimeOffMutation.mutate(req)
    }
  }

  // handle change time-off status
  const handleChangeTimeOffStatus = (e: RadioChangeEvent) => {
    setTimeOffStatus(e.target.value)
  }

  return (
    <Modal
      centered
      title={null}
      footer={null}
      closable={false}
      onCancel={handleCancel}
      afterClose={handleAfterClose}
      visible={visibleTimeOffModal}
      className={`${styles.time_off_modal} common_modal`}
    >
      <div className="common_modal_header">
        <p>Taking vacation</p>
        <ModalCloseIcon onClickFunc={handleCancel} />
      </div>
      <div className={`${styles.body} common_modal_body`}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={formInstance}
          autoComplete="off"
        >
          {hasPermission(permissions.USER_VIEW) && (
            <Item
              label="By whom"
              rules={[{ required: true, message: '' }]}
              name="user_id"
            >
              <Select loading={usersIsLoading}>
                {employees?.map((employee) => (
                  <Option
                    key={employee.user_id}
                    value={employee.user_id}
                  >{`${employee.first_name} ${employee.last_name}`}</Option>
                ))}
              </Select>
            </Item>
          )}
          <div className="time_cont">
            <Item
              label="Since when"
              className="w_100"
              rules={[{ required: true, message: '' }]}
              name="from_when"
              initialValue={dayjs()}
            >
              <DatePicker placeholder="" format={DATE_FORMAT} />
            </Item>
            <Item
              rules={[{ required: true, message: '' }]}
              name="start_time"
              initialValue={dayjsParse(defaultTimes.EIGHT, TIME_FORMAT)}
            >
              <TimePicker
                format={TIME_FORMAT}
                placeholder=""
                disabledTime={timeOffDisabledTime}
                hideDisabledOptions={true}
                minuteStep={10}
              />
            </Item>
          </div>
          <div className="time_cont">
            <Item
              label="Until when"
              className="w_100"
              rules={[{ required: true, message: '' }]}
              name="until_when"
              initialValue={dayjs()}
            >
              <DatePicker placeholder="" format={DATE_FORMAT} />
            </Item>
            <Item
              rules={[{ required: true, message: '' }]}
              name="end_time"
              initialValue={dayjsParse(defaultTimes.EIGHTTEEN, TIME_FORMAT)}
            >
              <TimePicker
                format={TIME_FORMAT}
                placeholder=""
                disabledTime={timeOffDisabledTime}
                hideDisabledOptions={true}
                minuteStep={10}
              />
            </Item>
          </div>
          <Item
            label="Type"
            rules={[{ required: true, message: '' }]}
            name="reason_id"
          >
            <Select loading={reasonsIsLoading}>
              {reasons?.map((reason) => (
                <Option value={reason.reason_id} key={reason.reason_id}>
                  {reason.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item label="Description" name="description">
            <TextArea />
          </Item>
        </Form>
      </div>
      <div className={`${styles.footer} common_modal_footer`}>
        {hasPermission(permissions.CALENDAR_STATUS_CHANGE) ? (
          <Radio.Group value={timeOffStatus} onChange={handleChangeTimeOffStatus}>
            <Radio value={timeOffStatuses.EXPECTED}>Waiting</Radio>
            <Radio value={timeOffStatuses.CONFIRMED}>Confirm</Radio>
            <Radio value={timeOffStatuses.REJECTED}>Reject</Radio>
          </Radio.Group>
        ) : (
          <span />
        )}
        <Button
          type="primary"
          onClick={handleSubmit}
          className="submit_btn"
          loading={
            createTimeOffMutation.isLoading || updateTimeOffMutation.isLoading
          }
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default TimeOffModal
