import React, { useEffect, useMemo } from "react";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import weekday from "dayjs/plugin/weekday";

import Space from "./components/space/Space";
import { useViewSpaces } from "./services/queries";
import { dayjsUnits } from "../../utils/constants/dayjsUnits";
import { createArr } from "../../utils/helpers/createArr";

import styles from "./index.module.scss";
import { DAY, MONTH_FORMAT } from "../../utils/constants/dayjsFormats";

dayjs.extend(minMax);
dayjs.extend(weekday);

const Index: React.FC = () => {
  const { data } = useViewSpaces();

  // start date
  const startDate = useMemo(() => {
    if (data) {
      const date = dayjs.min(data?.map((item) => dayjs.unix(item.start_date)));
      return date.add(-date.weekday(), dayjsUnits.DAY);
    }
    return dayjs();
  }, [data]);

  // end date
  const endDate = useMemo(() => {
    if (data) {
      const date = dayjs.max(data?.map((item) => dayjs.unix(item.due_date)));
      return date.add(7 - date.weekday(), dayjsUnits.DAY);
    }
    return dayjs();
  }, [data]);

  // count of days
  const countOfDays = useMemo(
    () => endDate.diff(startDate, dayjsUnits.DAY),
    [startDate, endDate]
  );

  // count of weeks
  const countOfWeeks = useMemo(
    () => endDate.diff(startDate, dayjsUnits.WEEK),
    [startDate, endDate]
  );

  // count of today
  const countOfToday = useMemo(
    () => dayjs().diff(startDate, dayjsUnits.DAY),
    [startDate]
  );

  useEffect(() => {
    const todayElement = document.getElementById("today")!;
    todayElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  }, [data]);

  return (
    <div className={styles.gantt}>
      <div className={styles.gantt_header}>header</div>
      <div className={styles.gantt_body}>
        <div className={styles.gantt_body_top}>
          <div className={styles.left}>left</div>
          <div className={styles.right}>
            <div className={styles.weeks}>
              {createArr(countOfWeeks)?.map((_, index) => (
                <div className={styles.weeks_item} key={index}>
                  {startDate
                    .add(index, dayjsUnits.WEEK)
                    .startOf(dayjsUnits.WEEK)
                    .format(`${DAY} ${MONTH_FORMAT}`)}
                  &nbsp;-&nbsp;
                  {startDate
                    .add(index, dayjsUnits.WEEK)
                    .endOf(dayjsUnits.WEEK)
                    .format(`${DAY} ${MONTH_FORMAT}`)}
                </div>
              ))}
            </div>
            <div className={styles.days}>
              {createArr(countOfDays)?.map((_, index) => (
                <div key={index} className={styles.days_item}>
                  {startDate.add(index, dayjsUnits.DAY).format(DAY)}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.gantt_body_bottom}>
          <div
            className={styles.today}
            style={{ left: countOfToday * 25 }}
            id="today"
          >
            <span>Today</span>
          </div>
          {data?.map((item) => (
            <Space {...item} key={item.id} ganttStart={startDate} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
