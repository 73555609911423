import React from 'react';
import { Table, Tag, Avatar } from 'antd';
import FlagIcon from '../../assets/icons/FlagIcon';
import styles from './list.module.scss'
import { HomeViewTask, HomeViewTaskAssign, HomeViewTaskStatus } from '../../utils/models/HomeView';
import dayjs from 'dayjs';
import { toHourSeconds } from '../../utils/helper/toHoursAndMinutes';

interface IProps {
  data: HomeViewTask[] | undefined
}

const handleAuxClick = (
  e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  url: string
) => {
  e.preventDefault();
  e.stopPropagation()
  window.open(url?.replace('https', 'clickup'), '_self')
}


const List: React.FC<IProps> = ({ data }) => {


  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'name',
      key: 'name',
      width: 500,
      render: (name: string, item: HomeViewTask) => {
        return (
          <div>
            <h6>{item?.list.name + '/' + item?.folder?.name?.substring(0, 40)}...</h6>
            <a
              href={item.url}
              target="_blank"
              rel="noreferrer"
              onAuxClick={(e) => handleAuxClick(e, item?.url)}
            >{name.length > 60 ? name.substring(0, 47) + "..." : name}</a>
          </div>
        )
      }
    },
    {
      title: 'Assignes',
      dataIndex: 'assignees',
      key: 'Assignes',
      width: 100,
      render: (assignees: HomeViewTaskAssign[], item: any) => assignees?.map((item) => <Avatar key={item?.id} src={item?.profilePicture} />)
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      width: 60,
      render: (_: any, item: HomeViewTask) => {
        return (
          <FlagIcon fill={item?.priority?.color} />
        )
      }
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      width: 120,
      render: (status: HomeViewTaskStatus) => <Tag color={status.color}>{status.status}</Tag>
    },
    {
      title: 'Start and Due date',
      dataIndex: 'startAndDueDate',
      key: 'startAndDueDate',
      width: 160,
      render: (_: any, item: HomeViewTask) => {

        // start and due date

        const start_date = (item.start_date || item.due_date) ? dayjs.unix(item.start_date ?? item.due_date) : dayjs()
        const due_date = (item.start_date || item.due_date) ? dayjs.unix(item.due_date) : dayjs()

        const differDate = dayjs.unix(item.due_date).diff(dayjs.unix(item.start_date), "day");
        const differToday = dayjs().diff(dayjs.unix(item.start_date), "day")
        const nextDiff = dayjs().hour(0).diff(dayjs.unix(item.start_date || item.due_date).hour(1), "day")

        const totalDate = (100 / (differDate + 1)) * (differToday + 1);

        return (
          <div className={styles.progress}>
            <span className={styles.progress_text}>{start_date.format("MMM DD")}-{due_date.format("MMM DD")}</span>
            <span className={styles.progress_protsent} style={{ backgroundColor: totalDate >= 80 ? '#D73D2E' : '' || nextDiff < 0 ? 'darkgray' : '', width: totalDate + "%" }}></span>
          </div>

        )
      }
    },
    {
      title: 'Time Tracked',
      dataIndex: 'timetracked',
      key: 'timetracked',
      width: 105,
      render: (_: any, item: HomeViewTask) => {
        return (
          <p>{toHourSeconds(item.time_tracking).totalHours.toString().padStart(2, "0")}:{toHourSeconds(item.time_tracking).totalMinutes.toString().padStart(2, "0")}:{toHourSeconds(item.time_tracking).totalSeconds.toString().padStart(2, "0")}</p>
        )
      }
    },
    {
      title: 'Time Estimate',
      dataIndex: 'timeestimate',
      key: 'timeestimate',
      width: 105,
      render: (_: any, item: HomeViewTask) => <p>{toHourSeconds(item.time_estimate).totalHours}h {toHourSeconds(item.time_estimate).totalMinutes}m</p>
    },
  ];


  return <>
    <Table
      className={styles.container}
      columns={columns}
      dataSource={data?.filter((item) => item?.status?.status !== "completed")}
    />
  </>
};

export default List;