import { PaginationProps } from "antd";
import NextIcon from "../../assets/NextIcon";
import PrevIcon from "../../assets/PrevIcon";
import styles from '../../index.module.scss'

const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
        return (
            <div className={styles.navigate}>
                <PrevIcon /> <p>Previous</p>
            </div>
        );
    }
    if (type === 'next') {
        return <div className={styles.navigate}>
            <p>Next</p> <NextIcon />
        </div>;
    }
    return originalElement;
};

export default itemRender