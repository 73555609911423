import {message} from 'antd'
import axios from 'axios'
import {rootPaths} from '../routing/root/rootPaths'

import {AUTHORIZATION} from '../utils/constants/localStorageKeys'
import {LocalStorage} from './LocalStorage'
import {config} from "@fullcalendar/core/internal";

const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

$api.interceptors.request.use((config) => {
    const authorization = LocalStorage.get(AUTHORIZATION)
    if (authorization && config.headers) {
        config.headers.Authorization = `Bearer ${authorization}`
    }
    return config
})

$api.interceptors.response.use(undefined, (error) => {
    if (error.message === 'Network Error') {
        message.error("Internet tarmog'iga ulanishda xatolik yuz berdi", 5)
    }
    if (
        error.response?.status === 401 &&
        window.location.pathname !== rootPaths.INDEX
    ) {
        localStorage.removeItem(AUTHORIZATION)
        window.location.href = rootPaths.INDEX
    }
    return Promise.reject(error)
})

export default $api

export const $strikeapi = axios.create(
    {
        baseURL: process.env.REACT_APP_API_URL_STRIKE
    },
)
$strikeapi.interceptors.request.use((config) => {
    const Authorization = LocalStorage.get(AUTHORIZATION)
    if (Authorization && config.headers) {
        config.headers.Authorization = `Bearer ${Authorization}`
    }
    return config
})


