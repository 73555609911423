import TimeOffDaily from '../../components/timeOff/timeOffDaily/TimeOffDaily'
import TimeOffList from '../../components/timeOff/timeOffList/TimeOffList'
import TimeOffMonthly from '../../components/timeOff/timeOffMonthly/TimeOffMonthly'
import TimeOffWeekly from '../../components/timeOff/timeOffWeekly/TimeOffWeekly'
import { RouteType } from '../../utils/model/routeModel'
import { timeOffPaths } from './timeOffPaths'

export const timeOffRoutes: RouteType[] = [
  {
    path: timeOffPaths.INDEX,
    element: TimeOffList,
    isExact: true,
  },
  {
    path: timeOffPaths.DAILY,
    element: TimeOffDaily,
    isExact: true,
  },
  {
    path: timeOffPaths.WEEKLY,
    element: TimeOffWeekly,
    isExact: true,
  },
  {
    path: timeOffPaths.MOTHLY,
    element: TimeOffMonthly,
    isExact: true,
  },
]
