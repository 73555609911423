import { Button, Form, Modal } from 'antd'
import { useEffect } from 'react'

import MainPanel from './tabpanels/MainPanel'
import { STRING } from '../../../utils/constants/types'
import ModalCloseIcon from '../../../assets/icons/ModalCloseIcon'
import { EmployeeReq } from '../../../utils/model/employeesModel'
import { formLayouts } from '../../../utils/constants/formLayouts'
import { USER_ID } from '../../../utils/constants/queryParamsKeys'
import { useCreateUpdateEmployee } from '../../../queries/mutation'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { isNotUndefined } from '../../../utils/helpers/isNotUndefined'
import { employeesReducerActions } from '../../../store/reducers/employeesReducer'

import styles from './EmployeesModal.module.scss'

// const { TabPane } = Tabs

// type tabPanelType = {
//   tab: string
//   children: ReactNode
// }

const EmployeesModal = () => {
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const { setVisibleEmployeesModal, setSelectedEmployee, setIsUpdatingPassword } =
    employeesReducerActions
  const { visibleEmployeesModal, selectedEmployee } = useAppSelector(
    (state) => state.employeesReducer
  )
  const createUpdateEmployeeMutation = useCreateUpdateEmployee(
    handleAfterSuccess,
    Boolean(selectedEmployee)
  )

  // initial form fields
  useEffect(() => {
    if (selectedEmployee) {
      formInstance.setFieldsValue({ ...selectedEmployee })
    }
  }, [formInstance, selectedEmployee])

  // cancel
  const handleCancel = () => {
    dispatch(setVisibleEmployeesModal(false))
  }

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields()
    dispatch(setSelectedEmployee(null))
    dispatch(setIsUpdatingPassword(false))
  }

  // after success
  function handleAfterSuccess() {
    handleCancel()
  }

  // submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // on finish
  const onFinish = (fields: EmployeeReq) => {
    const formData = new FormData()
    type keyType = keyof typeof fields

    // official employee
    fields.official_employee = +fields.official_employee

    // image
    if (typeof fields.image === STRING) {
      fields.image = undefined
    }

    // passport image
    if (typeof fields.passport_image === STRING) {
      fields.passport_image = undefined
    }

    for (let key in fields) {
      let value = fields[key as keyType]

      if (isNotUndefined(value)) {
        formData.append(key, value! as string)
      }
    }

    if (selectedEmployee) {
      formData.append(USER_ID, String(selectedEmployee.user_id))
    }

    createUpdateEmployeeMutation.mutate(formData)
  }

  // on finish failed
  const onFinishFailed = (err: any) => {
  }

  // tab panels
  // const tabPanels: tabPanelType[] = [
  //   {
  //     tab: 'Main',
  //     children: (
  //       <MainPanel
  //         formInstance={formInstance}
  //         isUpdating={Boolean(selectedEmployee)}
  //       />
  //     ),
  //   },
  //   {
  //     tab: 'Settings',
  //     children: <SettingsPanel />,
  //   },
  //   {
  //     tab: 'Permissions',
  //     children: <PermissionsPanel />,
  //   },
  //   {
  //     tab: 'Payments',
  //     children: <PaymentsPanel />,
  //   },
  // ]

  return (
    <Modal
      centered
      title={null}
      footer={null}
      closable={false}
      onCancel={handleCancel}
      afterClose={handleAfterClose}
      visible={visibleEmployeesModal}
      className={`${styles.employees_modal} common_modal`}
    >
      <div className={`${styles.header} common_modal_header`}>
        <p>Add employee</p>
        <ModalCloseIcon onClickFunc={handleCancel} />
      </div>
      <div className={`${styles.body} common_modal_body`}>
        <Form
          autoComplete="off"
          onFinish={onFinish}
          form={formInstance}
          layout={formLayouts.VERTICAL}
          onFinishFailed={onFinishFailed}
        >
          <MainPanel
            formInstance={formInstance}
            isUpdating={Boolean(selectedEmployee)}
          />
          {/* <Tabs>
            {tabPanels.map((panel, index) => (
              <TabPane tab={panel.tab} key={index}>
                {panel.children}
              </TabPane>
            ))}
          </Tabs> */}
        </Form>
      </div>
      <div className={`${styles.footer} common_modal_footer`}>
        <Button
          type="primary"
          onClick={handleSubmit}
          className="submit_btn"
          loading={createUpdateEmployeeMutation.isLoading}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default EmployeesModal
