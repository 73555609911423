import React from 'react'

type props = {
  onClickFunc?: () => void
}

const ModalCloseIcon: React.FC<props> = ({ onClickFunc }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClickFunc}
    >
      <path
        d="M16 29.3334C23.3334 29.3334 29.3334 23.3334 29.3334 16.0001C29.3334 8.66675 23.3334 2.66675 16 2.66675C8.66669 2.66675 2.66669 8.66675 2.66669 16.0001C2.66669 23.3334 8.66669 29.3334 16 29.3334Z"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2267 19.7732L19.7734 12.2266"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7734 19.7732L12.2267 12.2266"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ModalCloseIcon
