import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import en_GB from "antd/lib/locale-provider/en_GB";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import weekday from "dayjs/plugin/weekday";

import { setupStore } from "./store/store";
import RootRouter from "./routing/root/RootRouter";
import { dayjsLocales } from "./utils/constants/dayjsLocales";

import "antd/dist/antd.variable.min.css";
import "./styles/Common.scss";
import "./styles/Main.scss";
import ClickUpIntegration from "./components/clickUpIntegration/ClickUpIntegration";

dayjs.locale(dayjsLocales.EN_GB);
dayjs.extend(weekday);

const App: React.FC = () => {
  const store = setupStore();
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={en_GB}>
          <BrowserRouter>
            <ClickUpIntegration />
            <RootRouter />
          </BrowserRouter>
        </ConfigProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
