import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./mTitle.module.scss";

interface IProps {
  children: JSX.Element;
  content: JSX.Element | string;
}

const MTitle: FC<IProps> = ({ children, content }) => {
  const titleRef = useRef<HTMLDivElement | null>(null);
  const [mouseTitle, setMouseTitle] = useState({ mouse: false, x: 0, y: 0 });

  useEffect(() => {

  }, [children]);

  // mouseMove
  const mouseMove = (e: any) => {
    const windowWidth =
      window.innerWidth - (titleRef.current?.offsetWidth || 0);
    const clientLeft = e.clientX - (titleRef.current?.offsetWidth || 0) / 2;
    setMouseTitle((prev) => ({
      ...prev,
      mouse: true,
      x: windowWidth - clientLeft < 0 ? windowWidth : clientLeft,
      y:
        e.target.getBoundingClientRect().top -
        (titleRef.current?.offsetHeight || 0) -
        10,
    }));
  };

  // mouseOut
  const mouseOut = (e: any) => {
    setMouseTitle((prev) => ({
      ...prev,
      mouse: false,
    }));
  };

  return (
    <div onMouseMove={(e) => mouseMove(e)} onMouseOut={(e) => mouseOut(e)}>
      {children}
      <div
        className={styles.container}
        style={{
          left: mouseTitle.x,
          top: mouseTitle.y,
          opacity: mouseTitle.mouse ? 1 : 0,
        }}
        ref={(e) => (titleRef.current = e)}
      >
        {content}
        <div className={styles.container_arrow}></div>
      </div>
    </div>
  );
};

export default MTitle;
