import React, { useEffect, useState } from "react";
import axios from 'axios';
import dayjs, { Dayjs } from "dayjs";

import { TaskModel, taskData } from "../../utils/models/taskModel";

import styles from "../../index.module.scss";
import { dayjsUnits } from "../../../../utils/constants/dayjsUnits";
import DraggingIcon from "../../assets/icons/DraggingIcon";
import { useTaskCreate, useTaskUpdate } from "../../services/mutation";
import { useViewTasks } from "../../services/queries";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { gantListStorageActions } from "../../../../store/reducers/gantListSlice";
import { Avatar } from "antd";
import { BorderHorizontalOutlined, PlusCircleFilled } from "@ant-design/icons";
import { DATE_FORMAT } from "../../../../utils/constants/dayjsFormats";
import {LocalStorage} from "../../../../services/LocalStorage";
import {AUTHORIZATION} from "../../../../utils/constants/localStorageKeys";

export type TaskProps = {
    ganttStart: Dayjs;
    paddingLeft: number;
    listId: string;
};

const Task: React.FC<TaskProps & TaskModel> = ({
                                                   description,
                                                   due_date,
                                                   id,
                                                   name,
                                                   priority,
                                                   start_date,
                                                   status,
                                                   text_content,
                                                   time_estimate,
                                                   time_spent,
                                                   url,
                                                   ganttStart,
                                                   sub_tasks,
                                                   paddingLeft,
                                                   listId,
                                                   assignees,
                                               }) => {
    const gantListStorage = useAppSelector((store) => store.gantListSlice.ids);
    const dispatch = useAppDispatch();
    const [subtaskOpen, setSubtaskOpen] = useState(false);
    const { data, isLoading, isSuccess } = useViewTasks(listId);
    const [rightDragging, setRightDragging] = useState<{
        isDraggingRight: boolean;
        isDraggingLeft: boolean;
        isDragging: boolean;
        startWidth: number;
        left: null | number;
        width: null | number;
    }>({
        isDraggingRight: false,
        isDraggingLeft: false,
        isDragging: false,
        startWidth: 0,
        left: null,
        width: null,
    });

    const taskUpdate = useTaskUpdate(listId);

    // start date
    const startDate = dayjs
        .unix(start_date || due_date || ganttStart.unix())
        .diff(ganttStart, dayjsUnits.DAY);

    // length
    const length =
        dayjs
            .unix(due_date)
            .diff(
                dayjs.unix(start_date || due_date || ganttStart.unix()),
                dayjsUnits.DAY
            ) + 1;

    // dragging-mousedown-right
    const MouseDownRight = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        setRightDragging((prev) => ({
            isDraggingRight: true,
            isDraggingLeft: false,
            isDragging: false,
            startWidth: e.clientX,
            left: prev.left || startDate * 25,
            width: prev.width || length * 25,
        }));
    };

    // dragging-mousedown-left
    const MouseDownLeft = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        setRightDragging((prev) => ({
            isDraggingRight: false,
            isDraggingLeft: true,
            isDragging: false,
            startWidth: e.clientX,
            left: prev.left || startDate * 25,
            width: prev.width || length * 25,
        }));
    };

    // dragging-mousedown-dragging
    const MouseDownDragging = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
        setRightDragging((prev) => ({
            isDraggingRight: false,
            isDraggingLeft: false,
            isDragging: true,
            startWidth: e.clientX,
            left: prev.left || startDate * 25,
            width: prev.width || length * 25,
        }));
    };

    useEffect(() => {
        setRightDragging({
            isDraggingRight: false,
            isDraggingLeft: false,
            isDragging: false,
            startWidth: 0,
            left: startDate * 25,
            width: length * 25,
        });
        // eslint-disable-next-line
    }, [
        startDate,
        length,
        data,
        ganttStart,
        isLoading,
        isSuccess,
        taskUpdate.isSuccess,
    ]);

    // window-events
    useEffect(() => {
        // dragging-mousemove-right
        const MouseMoveRight = (e: MouseEvent) => {
            if (rightDragging.isDraggingRight) {
                setRightDragging((prev) => ({
                    ...prev,
                    width: length * 25 + (e.clientX - prev.startWidth),
                }));
            }

            if (rightDragging.isDraggingLeft) {
                setRightDragging((prev) => ({
                    ...prev,
                    width: length * 25 + (prev.startWidth - e.clientX),
                    left: startDate * 25 - (prev.startWidth - e.clientX),
                }));
            }

            if (rightDragging.isDragging) {
                setRightDragging((prev) => ({
                    ...prev,
                    left: startDate * 25 - (prev.startWidth - e.clientX),
                }));
            }
        };

        // dragging-mouseup-right
        const MouseUpRight = (e: MouseEvent) => {
            if (
                rightDragging.isDraggingRight ||
                rightDragging.isDraggingLeft ||
                rightDragging.isDragging
            ) {
                setRightDragging((prev) => ({
                    ...prev,
                    isDraggingRight: false,
                    isDraggingLeft: false,
                    isDragging: false,
                }));

                let startDateUpdate = Math.floor(
                    (rightDragging.left! - startDate * 25) / 25
                );
                // prettier-ignore
                let finishDateUpdate = Math.round(
                    ((rightDragging.left! + rightDragging.width!) - ((startDate * 25) + (length * 25))) / 25
                );

                if (startDateUpdate || finishDateUpdate) {
                    setRightDragging((prev) => ({
                        isDraggingRight: false,
                        isDraggingLeft: false,
                        isDragging: false,
                        startWidth: 0,
                        left: Math.ceil(prev.left! / 25) * 25,
                        width: Math.ceil(prev.width! / 25) * 25,
                    }));
                    taskUpdate.mutate({
                        task_id: id,
                        ...(startDateUpdate && {
                            start_date: dayjs
                                .unix(start_date || due_date)
                                .add(startDateUpdate, "day")
                                .valueOf()
                                .toString(),
                        }),
                        ...(finishDateUpdate && {
                            due_date: dayjs
                                .unix(due_date)
                                .add(finishDateUpdate, "day")
                                .valueOf()
                                .toString(),
                        }),
                    });
                }
            }
        };

        window.addEventListener("mousemove", MouseMoveRight);
        window.addEventListener("mouseup", MouseUpRight);

        return () => {
            window.removeEventListener("mousemove", MouseMoveRight);
            window.removeEventListener("mouseup", MouseUpRight);
        };
    }, [
        rightDragging,
        length,
        startDate,
        due_date,
        ganttStart,
        id,
        start_date,
        taskUpdate,
    ]);

    // task-width
    function taskWidth() {
        return rightDragging.width ? rightDragging.width : length * 25;
    }

    // task-left
    function taskLeft() {
        return rightDragging.left ? rightDragging.left : startDate * 25;
    }

    //handleCollapse
    const handleCollapse = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        if (subtaskOpen && gantListStorage.includes(id)) {
            dispatch(
                gantListStorageActions.setGantListAddStorage([
                    ...gantListStorage.filter((item) => item !== id),
                ])
            );
        } else {
            dispatch(
                gantListStorageActions.setGantListAddStorage([...gantListStorage, id])
            );
        }
        setSubtaskOpen(!subtaskOpen);
    };

    // aux click
    const handleAuxClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        e.preventDefault();
        window.open(url?.replace("https", "clickup"), "_self");
    };

    // openClickUp
    const openClickUp = () => {
        window.open(url, "_blank");
    };

    const [position, setPosition] = useState({ move: false, offsetX: 0 });
    const noneTask = !(start_date || due_date)

    const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (noneTask) {
            setPosition({
                move: true,
                offsetX: e?.nativeEvent?.offsetX - 10,
            });
        }
    };

    const mouseOut = () => {
        if (noneTask) {
            setPosition((prev) => ({
                ...prev,
                move: false,
            }));
        }
    };

    const authorization = LocalStorage.get(AUTHORIZATION)

    const createTask = async (data: any) => {
        // const query = new URLSearchParams().toString();
        // const response = await axios.post(`https://backend.erp.softex.uz/api/task/create`, data, {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': `Bearer ${authorization}`
        //     }
        // });
        // return response.data;
        let startDateUpdate = Math.floor(
            (rightDragging.left! - startDate * 25) / 25
        );
        // prettier-ignore
        let finishDateUpdate = Math.round(
            ((rightDragging.left! + rightDragging.width!) - ((startDate * 25) + (length * 25))) / 25
        );

        taskUpdate.mutate({

            task_id: id,
            ...(startDateUpdate && {
                start_date: dayjs
                    .unix(start_date || due_date)
                    .add(startDateUpdate, "day")
                    .valueOf()
                    .toString(),
            }),
            ...(finishDateUpdate && {
                due_date: dayjs
                    .unix(due_date)
                    .add(finishDateUpdate, "day")
                    .valueOf()
                    .toString(),
            }),
        });
    };

    const handleTaskCreate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const startDateUnix = ganttStart.add(Math.ceil(e.nativeEvent.offsetX / 25) - 1, 'day').valueOf();
        const dueDateUnix = ganttStart.add(Math.ceil(e.nativeEvent.offsetX / 25) - 1, 'day').valueOf();

        const newTaskData = {
            task_id: id,
            start_date: startDateUnix.toString(),
            due_date: dueDateUnix.toString(),
            name: ""
        };

        taskUpdate.mutate(newTaskData);
    };

    return (
        <>
            <div className={`${styles.task} ${styles.gantt_row}`}>
                <div className={styles.top} onMouseMove={mouseMove} onMouseOut={mouseOut} onClick={handleTaskCreate}>
                    <a
                        href={url}
                        className={styles.left}
                        style={{ paddingLeft: paddingLeft }}
                        onAuxClick={handleAuxClick}
                        onClick={(e) => {
                            e.preventDefault();
                            handleCollapse(e);
                        }}
                    >
                        {sub_tasks?.length > 0 && (
                            <span
                                onClick={handleCollapse}
                                className={
                                    subtaskOpen ? styles.left_open_icon : styles.left_close_icon
                                }
                            ></span>
                        )}
                        <span
                            style={{
                                backgroundColor: status?.color,
                            }}
                        ></span>
                        <p onClick={openClickUp}>{name}</p>
                    </a>
                    <div className={styles.right}>
                        <PlusCircleFilled style={{left: position.offsetX,position:"absolute",pointerEvents:"none", opacity: position.move ? 1 : 0 }}/>
                        <div className={styles.cells}>
                            {/* {createArr(2268).map((item) => (
            <div className={styles.cells_item} />
          ))} */}
                        </div>
                        {(start_date || due_date) && (
                            <div
                                className={styles.task}
                                style={{
                                    left: taskLeft(),
                                    width: taskWidth(),
                                    backgroundColor: status?.color,
                                }}
                                id={`taskId_${id}`}
                                onMouseDown={(e) => MouseDownDragging(e)}

                            >
                <span
                    onMouseDown={(e) => MouseDownLeft(e)}
                    className={styles.task_dragging_left}
                >
                  <DraggingIcon />
                </span>
                                {name.length < length * 4 ? (
                                    <>
                                        {assignees?.map((item) => (
                                            <Avatar src={item.profilePicture} size={20} />
                                        ))}{" "}
                                        {name}
                                    </>
                                ) : (
                                    <span className={styles.task_rightText}>
                    {assignees?.map((item) => (
                        <Avatar src={item.profilePicture} size={20} />
                    ))}{" "}
                                        {name}
                  </span>
                                )}
                                <p></p>
                                <span
                                    className={styles.task_dragging_right}
                                    onMouseDown={(e) => MouseDownRight(e)}
                                >
                  <DraggingIcon />
                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div className={styles.bottom}></div>
            </div>
            <div className={styles.task_subtask}>
                {subtaskOpen &&
                    sub_tasks.map((item) => (
                        <Task
                            {...item}
                            key={item.id}
                            ganttStart={ganttStart}
                            paddingLeft={paddingLeft + 25}
                            listId={listId}
                        />
                    ))}
            </div>
        </>
    );
};

export default Task;
