import React from 'react'

const ArrowRightIcon: React.FC = () => {
  return (
    <svg
      width="7"
      height="11"
      viewBox="0 0 7 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.21001 0.792893C1.60053 0.402369 2.2337 0.402369 2.62422 0.792893L6.4828 4.65147C6.95143 5.1201 6.95143 5.8799 6.4828 6.34853L2.62422 10.2071C2.2337 10.5976 1.60053 10.5976 1.21001 10.2071C0.819483 9.81658 0.819483 9.18342 1.21001 8.79289L4.5029 5.5L1.21001 2.20711C0.819483 1.81658 0.819483 1.18342 1.21001 0.792893Z"
        fill="#7C828D"
      />
    </svg>
  )
}

export default ArrowRightIcon
