import React from 'react'

const PaginationNextIcon: React.FC = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.18408 1.63174L2.87581 3.60542L1.18408 5.5791"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.22925 1.63174L5.92098 3.60542L4.22925 5.5791"
        stroke="#3A57E8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PaginationNextIcon
