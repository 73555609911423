import React, {useEffect, useMemo} from 'react';
import {Form, Input, Modal} from "antd";

import {DashboardRequestModel} from "../../../utils/models/dashboardRequestModel";
import {useCreateDashboard, useUpdateDashboard} from "../../../services/mutations";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/redux";
import {dashboardReducerActions} from "../../../../../store/reducers/dashboardReducer";

const {Item} = Form

const DashboardModal: React.FC = () => {
  const dispatch = useAppDispatch()
  const [formInstance] = Form.useForm()
  const {dashboardModalData: modalData} = useAppSelector(state => state.dashboardReducer)
  const {setDashboardModalData: setModalData} = dashboardReducerActions
  const createDashboard = useCreateDashboard()
  const updateDashboard = useUpdateDashboard()

  // initial form fields
  useEffect(() => {
    if (modalData?.data) {
      formInstance.setFieldsValue({...modalData.data})
    }
  }, [modalData?.data, formInstance])

  // is updating
  const isUpdating = useMemo(() => !!modalData?.data, [modalData?.data])

  // cancel
  const handleCancel = () => {
    dispatch(setModalData({visible: false}))
  }

  // finish
  const onFinish = (fields: DashboardRequestModel) => {
    if (isUpdating) {
      updateDashboard.mutateAsync({...fields, id: modalData?.data?.id!}).then(handleCancel)
    } else {
      createDashboard.mutateAsync(fields).then(handleCancel)
    }
  }

  // submit
  const handleSubmit = () => {
    formInstance.submit()
  }

  // after close
  const handleAfterClose = () => {
    formInstance.resetFields()
  }

  return (
    <Modal
      open={modalData.visible}
      onCancel={handleCancel}
      centered
      title='Add dashboard'
      onOk={handleSubmit}
      afterClose={handleAfterClose}
      okButtonProps={{
        loading: createDashboard.isLoading || updateDashboard.isLoading
      }}
    >
      <Form form={formInstance} onFinish={onFinish} layout='vertical'>
        <Item name='name' label='Name' rules={[{required: true, message: ''}]}>
          <Input/>
        </Item>
      </Form>
    </Modal>
  );
};

export default DashboardModal;