import { Avatar, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react'
import { DATE_FORMAT } from '../../../../utils/constants/dayjsFormats';
import { TimeOffRequestsType } from '../../utils/models/HomeView';
import styles from './timeOff.module.scss'

interface IProps {
  data: TimeOffRequestsType[] | undefined;
}

const TimeOffRequests: React.FC<IProps> = ({ data }) => {

  const columns = [
    {
      title: 'Member',
      dataIndex: "member",
      key: 'member',
      render: (_: any, item: TimeOffRequestsType) => {
        return (
          <>
            <div className={styles.member}>
              <Avatar src={item?.image} />
              <h5>{item?.first_name} {item?.last_name}</h5>
            </div>
          </>
        )
      }
    },
    {
      title: 'Policy',
      dataIndex: "policy",
      key: 'policy',
      render: (_: any, item: TimeOffRequestsType) => {
        return (
          <p>{item?.reason_name}</p>
        )
      }
    },
    {
      title: 'Booked on',
      dataIndex: "booked_on",
      key: 'booked_on',
      render: (_: any, item: TimeOffRequestsType) => {
        return (
          <p>{item?.from_when} <span>{item?.start_time}</span></p>
        )
      }
    },
    {
      title: 'Duration',
      dataIndex: "duration",
      key: 'duration',
      render: (_: any, item: TimeOffRequestsType) => {

        const date1 = dayjs(item.from_when, DATE_FORMAT);
        const date2 = dayjs(item.until_when, DATE_FORMAT);
        const diff = date2.diff(date1, "second");
        const different =  (diff / 3600 / 24) + 1

        return (
          <p>{different} day</p>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: "status",
      key: 'status',
      render: (_: any, item: TimeOffRequestsType) => {
        return (
          <p className={`${styles.time_off_status}  ${styles[item.status]}`}>{item?.status}</p>
        )
      }
    }
  ];

  return (
    <div className={styles.request_table}>
      <h5 className={styles.title}>Time off requests</h5>
      <Table className={styles.timeOff_container} dataSource={data} columns={columns} />
    </div>
  )
}

export default TimeOffRequests
