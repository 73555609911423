import { useQuery } from '@tanstack/react-query'
import { stringify } from 'query-string'
import { useSearchParams } from 'react-router-dom'

import $api from '../../../services/RequestService'
import { DATE_FORMAT } from '../../../utils/constants/dayjsFormats'
import { dayjsUnits } from '../../../utils/constants/dayjsUnits'
import { VIEW_TYPE } from '../../../utils/constants/queryParamsKeys'
import { dayjsParse } from '../../../utils/helpers/dayjsFormatter'
import { errorHandler } from '../../../utils/helpers/errorHandler'
import { ErrorRes } from '../../../utils/model/responseType'
import { endpoints } from '../utils/constants/endpoints'
import { queryKeys } from '../utils/constants/queryKeys'
import { workloadViewTypes } from '../utils/enums/workloadViewTypes'
import { WorkloadType } from '../utils/models/workloadModel'

export function useGetWorkloads(selectedTime: string) {
  const [searchParams] = useSearchParams()
  const viewType = searchParams.get(VIEW_TYPE) ?? workloadViewTypes.WEEKLY
  const units = {
    [workloadViewTypes.WEEKLY]: dayjsUnits.WEEK,
    [workloadViewTypes.MONTHLY]: dayjsUnits.MONTH,
  }
  const unit = units[viewType as keyof typeof units]
  const start_date = dayjsParse(selectedTime, DATE_FORMAT)
    .startOf(unit)
    .format(DATE_FORMAT)
  const end_date = dayjsParse(selectedTime, DATE_FORMAT)
    .endOf(unit)
    .add(1, dayjsUnits.DAY)
    .format(DATE_FORMAT)

  return useQuery<WorkloadType[], ErrorRes>(
    [queryKeys.WORKLOADS, selectedTime],
    async () => {
      const res = await $api.get(
        `${endpoints.WORKLOADS.VIEW}?${stringify({ end_date, start_date })}`
      )
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: errorHandler,
    }
  )
}
