import React from 'react';
import {Button, Popconfirm, Popover, Spin} from "antd";
import {MoreOutlined} from "@ant-design/icons";
import {Link, Route} from "react-router-dom";

import {useGetDashboards} from "../../services/queries";
import {rootPaths} from "../../../../routing/root/rootPaths";
import DashboardModal from "./dashboard-modal/DashboardModal";
import {useQueryParams} from "../../../../hooks/useQueryParams";
import {useAppDispatch} from "../../../../hooks/redux";
import {dashboardReducerActions} from "../../../../store/reducers/dashboardReducer";
import {DashboardModel} from "../../utils/models/dashboardModel";
import {useDeleteDashboard} from "../../services/mutations";

import styles from './sidebar.module.scss'
import IndexDashboard from "../../Index";

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch()
  const {setDashboardModalData: setModalData} = dashboardReducerActions
  const deleteDashboard = useDeleteDashboard()
  const {data, isLoading} = useGetDashboards()
  const {getParams} = useQueryParams()

  // open modal
  const handleOpenModal = (data?: DashboardModel) => {
    dispatch(setModalData({
      visible: true,
      data
    }))
  }

  // handle update
  const handleUpdate = (data: DashboardModel) => {
    handleOpenModal(data)
  }

  // handle delete
  const handleDelete = (id: number) => {
    deleteDashboard.mutate({id})
  }

  // is active
  const isActive = (id: number) => {
    if (Number(getParams()?.id) === id) return styles.active
    return ''
  }

  return (
    <>
      <div className={styles.sidebar}>
        <div className={styles.sidebar_top}>
          <p>Dashboards</p>
          <Button type='primary' onClick={() => handleOpenModal()}>+</Button>
        </div>
        <Spin spinning={isLoading}>
          <div className={styles.sidebar_bottom}>
            {data?.map(item => (
              <Link className={isActive(item.id)} to={`${rootPaths.DASHBOARD}?id=${item.id}`} key={item.id}>
                <span>{item.name}</span>
                <Popover
                  content={
                    <div className={styles.popover_content}>
                      <span onClick={() => handleUpdate(item)}>Update</span>
                      <Popconfirm
                        onConfirm={() => handleDelete(item.id)}
                        title='Are you sure to delete this dashboard?'
                        okButtonProps={{
                          loading: deleteDashboard.isLoading
                        }}
                        placement='right'
                        trigger={['click', 'hover']}
                        mouseEnterDelay={10}
                        mouseLeaveDelay={0.2}
                      >
                        <span>Delete</span>
                      </Popconfirm>
                    </div>
                  }
                  overlayClassName={styles.popover}
                  placement='right'
                  trigger={['click', 'hover']}
                  mouseEnterDelay={10}
                  mouseLeaveDelay={0.2}
                >
                  <MoreOutlined className={styles.more}/>
                </Popover>
              </Link>
            ))}
          </div>
        </Spin>
      </div>
      <DashboardModal/>
    </>
  );
};

export default Sidebar;