import { useMutation, useQueryClient } from '@tanstack/react-query'
import $api from '../../../services/RequestService'
import { messageTypes } from '../../../utils/constants/messageTypes'
import { showMessage } from '../../../utils/helpers/showMessage'
import { ErrorRes, SuccessRes } from '../../../utils/model/responseType'
import { endpoints } from '../utils/constants/endpoints'
import { queryKeys } from '../utils/constants/queryKeys'
import { WorkloadModalDataType } from '../utils/models/workloadModel'

// update workload
export function useUpdateDailyWorkload(afterSuccess: () => void) {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, WorkloadModalDataType>(
    async (req) => {
      const res = await $api.put(endpoints.WORKLOADS.UPDATE, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.WORKLOADS])
        afterSuccess()
      },
    }
  )
}

// change user position
export function useChangeUserPositon(afterSuccess: () => void) {
  const queryClient = useQueryClient()

  return useMutation<SuccessRes, ErrorRes, { user_id: number; position: number }>(
    async (req) => {
      const res = await $api.post(endpoints.USER.CHANGE_POSITION, req)
      return res.data
    },
    {
      onSuccess: (res) => {
        showMessage(res?.messages, messageTypes.SUCCESS)
        queryClient.invalidateQueries([queryKeys.WORKLOADS])
        afterSuccess()
      },
    }
  )
}
