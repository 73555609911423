import React from "react";

const DraggingIcon = (props?: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="5"
      height="8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0a1 1 0 100 2 1 1 0 000-2zm3 0a1 1 0 100 2 1 1 0 000-2zM0 4a1 1 0 112 0 1 1 0 01-2 0zm4-1a1 1 0 100 2 1 1 0 000-2zM0 7a1 1 0 112 0 1 1 0 01-2 0zm4-1a1 1 0 100 2 1 1 0 000-2z"
        fill="#fff"
      />
    </svg>
  );
};

export default DraggingIcon;
