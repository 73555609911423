import { queryKeys as ganttQueryKeys } from "../../features/gantt/utils/constants/queryKeys";
import { queryKeys as homeQueryKeys } from "../../features/home/utils/constants/queryKeys";
import { queryKeys as workloadQueryKeys } from "../../features/workload/utils/constants/queryKeys";
import { queryKeys as mainQueryKeys } from "../constants/queryKeys";

export const allQueryKeys = Object.values({
  ...mainQueryKeys,
  ...homeQueryKeys,
  ...ganttQueryKeys,
  ...workloadQueryKeys,
});
