import { addControllerPrefix } from "../helpers/addControllerPrefix";

const controllers = {
  employee: "/user",
  auth: "/login",
  reason: "/reason",
  timeOff: "/calendar",
  workload: "/workload",
};

// auth
const auth = {
  INDEX: "",
};
export const authEndpoints: typeof auth = addControllerPrefix(
  auth,
  controllers.auth
);

// time-off
const timeOff = {
  UPDATE: "/update",
  CREATE: "/create",
  VIEW_LIST: "/view/list",
  VIEW_DAILY: "/view/daily",
  VIEW_WEEKLY: "/view/weekly",
  VIEW_MONTHLY: "/view/monthly",
  STATUS_CHANGE: "/status/change",
};
export const timeOffEndpoints: typeof timeOff = addControllerPrefix(
  timeOff,
  controllers.timeOff
);

// employee
const employee = {
  VIEW: "/view",
  CREATE: "/create",
  UPDATE: "/update",
  VIEW_PAGINATION: "/view/pagination",
};
export const employeeEndpoints: typeof employee = addControllerPrefix(
  employee,
  controllers.employee
);

// reason
const reason = {
  VIEW: "/view",
  CREATE: "/create",
  UPDATE: "/update",
  STATUS_CHANGE: "/status/change",
};
export const reasonEndpoints: typeof reason = addControllerPrefix(
  reason,
  controllers.reason
);

// workload
const workload = {
  VIEW: "/view",
  UPDATE: "/update",
  CLICKUP_CONNECT: "/clickup/connect",
};
export const workloadEndpoints: typeof workload = addControllerPrefix(
  workload,
  controllers.workload
);
