import React from 'react'

const TimeOffIcon: React.FC = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M21.9897 6.03314C22.0097 6.27314 21.9897 6.52313 21.9297 6.77313L18.5597 20.3031C18.3197 21.3131 17.4198 22.0131 16.3798 22.0131H3.23974C1.72974 22.0131 0.659755 20.5331 1.09976 19.0831L5.30975 5.5531C5.59975 4.6131 6.46976 3.97314 7.44976 3.97314H19.7498C20.7097 3.97314 21.4898 4.54314 21.8198 5.34314C21.9198 5.55314 21.9697 5.79314 21.9897 6.03314Z"
        fill="#8A92A6"
      />
      <path
        d="M22.9899 19.6332C23.0899 20.9232 22.0699 22.0132 20.7799 22.0132H16.3799C17.4199 22.0132 18.3199 21.3132 18.5599 20.3032L21.9299 6.77319C21.9899 6.52319 22.0099 6.2732 21.9899 6.0332L21.9999 6.01318L22.9899 19.6332Z"
        fill="#8A92A6"
      />
      <path
        d="M9.67977 7.14319C9.61977 7.14319 9.55977 7.13317 9.49977 7.12317C9.09977 7.02317 8.84979 6.6232 8.94979 6.2132L9.98976 1.89319C10.0898 1.49319 10.4898 1.25321 10.8998 1.34321C11.2998 1.44321 11.5498 1.84318 11.4498 2.25318L10.4098 6.57319C10.3298 6.91319 10.0198 7.14319 9.67977 7.14319Z"
        fill="#8A92A6"
      />
      <path
        d="M16.3799 7.15323C16.3299 7.15323 16.2699 7.15321 16.2199 7.13321C15.8199 7.04321 15.56 6.6432 15.64 6.2432L16.5799 1.90323C16.6699 1.49323 17.0699 1.24321 17.4699 1.32321C17.8699 1.41321 18.1299 1.81323 18.0499 2.21323L17.1099 6.55319C17.0399 6.91319 16.7299 7.15323 16.3799 7.15323Z"
        fill="#8A92A6"
      />
      <path
        d="M15.7002 12.7632H7.7002C7.2902 12.7632 6.9502 12.4232 6.9502 12.0132C6.9502 11.6032 7.2902 11.2632 7.7002 11.2632H15.7002C16.1102 11.2632 16.4502 11.6032 16.4502 12.0132C16.4502 12.4232 16.1102 12.7632 15.7002 12.7632Z"
        fill="#8A92A6"
      />
      <path
        d="M14.7002 16.7632H6.7002C6.2902 16.7632 5.9502 16.4232 5.9502 16.0132C5.9502 15.6032 6.2902 15.2632 6.7002 15.2632H14.7002C15.1102 15.2632 15.4502 15.6032 15.4502 16.0132C15.4502 16.4232 15.1102 16.7632 14.7002 16.7632Z"
        fill="#8A92A6"
      />
    </svg>
  )
}

export default TimeOffIcon
