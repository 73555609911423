import { Button, Form, FormInstance, Image, Input, Select, Switch } from 'antd'
import React, { useMemo, useRef, useState } from 'react'

import noImage from '../../../../assets/images/noImage.png'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import { employeesReducerActions } from '../../../../store/reducers/employeesReducer'
import { fileInputAcceptances } from '../../../../utils/constants/fileInputAcceptances'
import {
  DATE_MASK,
  PASSPORT_MASK,
  PHONE_MASK,
} from '../../../../utils/constants/inputMasks'
import { professions } from '../../../../utils/constants/professions'
import { districts, regions } from '../../../../utils/constants/regions'
import { STRING } from '../../../../utils/constants/types'
import { valuePropNames } from '../../../../utils/constants/valuePropNames'
import { inputFileProps } from '../../../../utils/helpers/inputFileProps'
import { requiredFormItem } from '../../../../utils/helpers/requiredFormItem'
import { validateDateString } from '../../../../utils/helpers/validateDateString'
import { InputMask } from '../../../common/inputMask/InputMask'

import styles from '../EmployeesModal.module.scss'

const { Item } = Form
const { Option } = Select

type props = {
  formInstance: FormInstance
  isUpdating: boolean
}

const MainPanel: React.FC<props> = ({ formInstance, isUpdating }) => {
  const dispatch = useAppDispatch()
  const { setIsUpdatingPassword } = employeesReducerActions
  const [regionId, setRegionId] = useState<number>()
  const { isUpdatingPassword } = useAppSelector((state) => state.employeesReducer)
  const [isActiveBorder, setIsActiveBorder] = useState({
    top: false,
    bottom: true,
  })
  const [imagePreview, setImagePreview] = useState<{
    visible: boolean
    src: string
  }>({
    visible: false,
    src: '',
  })
  const body = useRef<HTMLDivElement | null>(null)

  // handle passport file
  const handlePassportFile = (
    e: React.ChangeEvent<HTMLInputElement> | undefined
  ) => {
    const files = e?.target?.files
    if (files) {
      formInstance.setFieldsValue({
        passport_image_name: files[0]?.name,
      })
    }
  }

  // handle photo file
  const handlePhotoFile = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const files = e?.target?.files
    if (files) {
      formInstance.setFieldsValue({
        image_name: files[0]?.name,
      })
    }
  }

  // filtered districts
  const filteredDistricts = useMemo(() => {
    return districts.filter((district) => district.region_id === regionId)
  }, [regionId])

  // handle region
  const handleRegion = (value: number) => {
    setRegionId(value)
    formInstance.resetFields(['district'])
  }

  // handle open image preview
  const handleOpenImagePreview = async (src: string | File) => {
    let source: string = ''

    if (src) {
      if (typeof src === STRING) {
        source = String(src)
      } else {
        source = URL.createObjectURL(src as File)
      }
    }

    setImagePreview({ src: source, visible: true })
  }

  // visible login password
  const isVisibleLoginPassword = () => {
    if (isUpdating) {
      if (isUpdatingPassword) return true
      return false
    }
    return true
  }

  // set visible login password
  const setVisibleLoginPassword = (visible: boolean) => {
    dispatch(setIsUpdatingPassword(visible))
  }

  // handle scroll
  const handleScroll = () => {
    const scrollTop = body.current?.scrollTop

    if (scrollTop === 0 && !isActiveBorder.top) {
      setIsActiveBorder({ ...isActiveBorder, top: true })
    } else if (scrollTop! > 0 && isActiveBorder.top) {
      setIsActiveBorder({ ...isActiveBorder, top: false })
    }

    const scrollHeight = body?.current?.scrollHeight!
    const isScrollBottom =
      body.current?.offsetHeight! + body.current?.scrollTop! >= scrollHeight

    if (isScrollBottom && isActiveBorder.bottom) {
      setIsActiveBorder({ ...isActiveBorder, bottom: false })
    } else if (!isScrollBottom && !isActiveBorder.bottom) {
      setIsActiveBorder({ ...isActiveBorder, bottom: true })
    }
  }

  return (
    <div className={styles.main_panel} ref={body} onScroll={handleScroll}>
      <div
        className={`${styles.top_border} ${
          !isActiveBorder.top ? styles.active : ''
        }`}
      />
      <div className={styles.twice}>
        <Item name="passport_series" label="Passport series and number">
          {InputMask(PASSPORT_MASK)}
        </Item>
        <Item
          name="birthday"
          label="Birth date"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  formInstance.setFieldsValue({
                    birthday: validateDateString(value),
                  })
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          {InputMask(DATE_MASK)}
        </Item>
      </div>
      <div className={styles.twice}>
        <Item name="first_name" label="First name" rules={[requiredFormItem()]}>
          <Input />
        </Item>
        <Item name="last_name" label="Last name" rules={[requiredFormItem()]}>
          <Input />
        </Item>
      </div>
      <Item name="email" label="Email" rules={[requiredFormItem()]}>
        <Input type="email" />
      </Item>
      <div className={styles.twice}>
        <Item name="father_name" label="Patronymic">
          <Input />
        </Item>
        <Item name="phone" label="Phone" rules={[requiredFormItem()]}>
          {InputMask(PHONE_MASK)}
        </Item>
      </div>
      <div className={styles.twice}>
        <Item
          name="passport_date"
          label="Passport issued date"
          rules={[
            {
              validator: (_, value) => {
                if (value) {
                  formInstance.setFieldsValue({
                    passport_issued_date: validateDateString(value),
                  })
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          {InputMask(DATE_MASK)}
        </Item>
        <Item name="passport_address" label="Passport issued place">
          <Input />
        </Item>
      </div>
      <div className={styles.file_input}>
        <Item name="passport_image" label="File of passport" {...inputFileProps}>
          <label className={styles.file_input_label}>
            <Input
              type="file"
              onChange={handlePassportFile}
              accept={fileInputAcceptances.ONLY_IMAGES}
            />
            <span>Choose File</span>
          </label>
        </Item>
        <Item>
          <Button
            className={styles.view_btn}
            type="primary"
            onClick={() =>
              handleOpenImagePreview(formInstance.getFieldValue('passport_image'))
            }
          >
            Preview
          </Button>
        </Item>
        <Item name="passport_image_name" className={styles.file_name}>
          <Input disabled />
        </Item>
      </div>
      <div className={styles.file_input}>
        <Item name="image" label="Photo 3x4" {...inputFileProps}>
          <label className={styles.file_input_label}>
            <Input
              type="file"
              onChange={handlePhotoFile}
              accept={fileInputAcceptances.ONLY_IMAGES}
            />
            <span>Choose File</span>
          </label>
        </Item>
        <Item>
          <Button
            className={styles.view_btn}
            type="primary"
            onClick={() =>
              handleOpenImagePreview(formInstance.getFieldValue('image'))
            }
          >
            Preview
          </Button>
        </Item>
        <Item name="image_name" className={styles.file_name}>
          <Input disabled />
        </Item>
      </div>
      <div className={styles.twice}>
        <Item label="Region">
          <Select showArrow={false} onChange={handleRegion} clearIcon={true}>
            {regions.map((region) => (
              <Option value={region.id} key={region.id}>
                {region.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="district_id" label="District">
          <Select showArrow={false}>
            {filteredDistricts.map((district) => (
              <Option value={district.id} key={district.id}>
                {district.name}
              </Option>
            ))}
          </Select>
        </Item>
      </div>
      <Item name="address" label="Address">
        <Input />
      </Item>
      <div className={styles.twice}>
        <Item name="profession" label="Profession" rules={[requiredFormItem()]}>
          <Select showArrow={false}>
            {professions.map((profession) => (
              <Option value={profession.value} key={profession.value}>
                {profession.label}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="official_employee"
          label="Official employee"
          rules={[requiredFormItem()]}
          valuePropName={valuePropNames.CHECKED}
          initialValue={false}
        >
          <Switch />
        </Item>
      </div>
      {isUpdating && !isUpdatingPassword && (
        <p
          className={styles.edit_login}
          onClick={() => setVisibleLoginPassword(true)}
        >
          Update login and password
        </p>
      )}
      {isVisibleLoginPassword() && (
        <div className={styles.twice}>
          <Item name="login" label="Login" rules={[requiredFormItem()]}>
            <Input />
          </Item>
          <Item name="password" label="Password" rules={[requiredFormItem()]}>
            <Input />
          </Item>
        </div>
      )}
      <div className={styles.image_preview}>
        <Image
          src={imagePreview.src || noImage}
          preview={{
            visible: imagePreview.visible,
            onVisibleChange: (value) => {
              setImagePreview({ ...imagePreview, visible: value })
            },
          }}
        />
      </div>
      <div
        className={`${styles.bottom_border} ${
          isActiveBorder.bottom ? styles.active : ''
        }`}
      />
    </div>
  )
}

export default MainPanel
